import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import request from "@services/request";
import { BeneficiaryStore, Contact, ContactPaymentTypes, GbpPaymentValidationPayload } from "./beneficiaryTypes";
import { AppDispatch } from "@store/store";


const initialState: BeneficiaryStore = {
	loading: true,
	eurList: [],
	localList: [],
	gbpList: [],
};

const slice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		setEurList(state, action: PayloadAction<Array<Contact>>) {
			state.eurList = action.payload;
		},
		setLocalList(state, action: PayloadAction<Array<Contact>>) {
			state.localList = action.payload;
		},
		setGbpList(state, action: PayloadAction<Array<Contact>>) {
			state.gbpList = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},

	}
});

export const { setLocalList, setLoading, setEurList, setGbpList } = slice.actions;


export const getIbanData = async (iban: string) => {
	const response = await request.get(`/api-defi-network/beneficiary/iban/${iban}`);
	return response?.data;
};

export const lookupSystemUserContact = async (username: string, fromAccountId: number): Promise<any> => {
	const { data } = await request.get(`/api-defi-network/beneficiary/client/lookup?clientId=${username}&fromAccountId=${fromAccountId}`);
	return data;
};

export const validateGbpPaymentData = async (accountId: number, payload: GbpPaymentValidationPayload): Promise<any> => {
	const { data } = await request.post(`/api-defi-network/beneficiary/uk-payment/validate/${accountId}`, payload);
	return data;
};

export const getBeneficiaries = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api-defi-network/beneficiary');
			const data = response?.data || [];
			const $localList = data.filter((bene: Contact, index: number) => bene.supportedPayments.includes(ContactPaymentTypes.LOCAL) && data.findIndex((item: Contact) => item.clientId?.toString().toUpperCase() === bene.clientId?.toString().toUpperCase()) === index);
			const $eurList = data
				.filter((bene: Contact) => bene.supportedPayments.includes(ContactPaymentTypes.SEPA) || bene.supportedPayments.includes(ContactPaymentTypes.SEPA_INSTANT));
			const $gbpList = data
				.filter((bene: Contact) => bene.supportedPayments.includes(ContactPaymentTypes.UK_CHAPS) || bene.supportedPayments.includes(ContactPaymentTypes.UK_FPS));

			dispatch(setLocalList($localList));
			dispatch(setGbpList($gbpList));
			dispatch(setEurList($eurList));
		}
		catch (e) {
			dispatch(setLocalList([]));
			dispatch(setGbpList([]));
			dispatch(setEurList([]));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export default slice.reducer;
