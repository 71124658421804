import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { CreateProductPayload, Product, ProductImage, ProductStore, UpdateProductPayload } from './productTypes';
import { PaginateParams } from 'types/pagination';


const initialState: ProductStore = {
	list: [],
	failed: false,
	loading: false,
};

const statusSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setProductList(state, action: PayloadAction<Product[]>) {
			state.list = action.payload;
		},
		setError(state, action: PayloadAction<boolean>) {
			state.failed = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	}
});

export const { setError, setProductList, setLoading } = statusSlice.actions;


export const getProductsAll = () => {
	return async (dispatch: AppDispatch) => {
		try {

			dispatch(setError(false));
			dispatch(setLoading(true));

			const response = await request.get('/api-defi-network/product/all-products');
			const data = response?.data || [];
			dispatch(setProductList(data));

			dispatch(setLoading(false));
		}
		catch (e) {
			dispatch(setError(true));
		}
		dispatch(setLoading(false));
	};
};



export const postCreateProduct = async (payload: CreateProductPayload): Promise<Product> => {
	const response = await request.post('/api-defi-network/product', payload);
	return response?.data;
};


export const postUpdateProduct = async (payload: UpdateProductPayload): Promise<Product> => {
	const response = await request.patch('/api-defi-network/product', payload);
	return response?.data;
};

export const getProduct = async (id: string): Promise<Product> => {
	const response = await request.get(`/api-defi-network/product/details/${id}`);
	return response?.data;
};


export const uploadProducImages = async (
	productId: string,
	files: File[]
): Promise<any> => {
	const formData = new FormData();

	formData.append('productId', `${productId}`);

	if (files?.length > 0) {
		// eslint-disable-next-line array-callback-return
		files.map(file => { formData.append('files', file); });
	}


	const response = await request.post(
		'/api-defi-network/product-image',
		formData,
		{ headers: { "Content-Type": "multipart/form-data" } }
	);
	const { data } = response;
	return data.key;
};

export const deleteProduct = async (productId: string): Promise<any> => {
	await request.post(`/api-defi-network/product/remove/${productId}`);
};

export const deleteProducImages = async (productImage: ProductImage[]): Promise<any> => {

	await request.delete('/api-defi-network/product-image', { data: { productImage: productImage } });
};


export const getProductsListByStoreId = async (id: number, params: PaginateParams): Promise<any> => {
	const response = await request.get(`/api-defi-network/product/by-store/${id}`, { params });
	return response?.data;
};


/* export const getAllProducts = async (): Promise<Product[]> => {
	try {
		const response = await request.get('/api-defi-network/product/all-products');
		return response?.data;
	}
	catch (e) { return [] }
};
 */

export default statusSlice.reducer;
