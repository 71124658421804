import FormTitle from "@components/form/FormTitle";
import { BeneficiaryType, GbpPaymentValidationPayload, IbanData } from "@features/beneficiary/beneficiaryTypes";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import ButtonStyleTab from "@components/form/ButtonStyleTab";
import ButtonStyleTabs from "@components/form/ButtonStyleTabs";
import FormTextField from "@components/form/FormTextField";
import { getNames } from "i18n-iso-countries";
import LoadingButton from "@components/LoadingButton";
import { isEmpty } from "lodash";
import { isValidIBAN } from "ibantools";
import { getIbanData, validateGbpPaymentData } from "@features/beneficiary/beneficiarySlice";
import _ from "lodash";
import { Account } from "@features/accounts/accountsTypes";

interface Props {
    onClose: () => void;
    onBack: () => void;
    onNext: (payload: any) => void;
    recipient: any | null;
    isGbpPayment: boolean;
    account: Account;
}

const SendMoneyBankRecipientDetails = ({ onClose, onBack, onNext, isGbpPayment, recipient, account }: Props) => {
    const { t } = useTranslation();
    const [beneficiaryType, setBeneficiaryType] = useState<BeneficiaryType>(recipient?.beneficiaryType ?? BeneficiaryType.INDIVIDUAL);
    const [ibanData, setIbanData] = useState<IbanData | null>();
    const [iban, setIban] = useState<string>(recipient?.iban ?? '');
    const [ibanIsValid, setIbanIsValid] = useState<boolean>(true);
    const countries = getNames('en');

    const initialValues = {
        beneficiary: recipient?.beneficiary ?? '',
        identification: recipient?.identification ?? '',
        bic: recipient?.bic ?? '',
        bankCountry: recipient?.bankCountry ?? '',
        accountNumber: recipient?.accountNumber ?? '',
        sortCode: recipient?.sortCode ?? '',
    };

    const validationScheme = Yup.object({
        beneficiary: Yup.string()
            .required(t('form.validator.required'))
            .matches(
                beneficiaryType === BeneficiaryType.INDIVIDUAL ? /^([a-zA-Z-.' ])+$/ : /^([0-9a-zA-Z-.,()' ])+$/,
                t(beneficiaryType === BeneficiaryType.INDIVIDUAL ? 'form.validator.beneficiaryNamePrivate' : 'form.validator.beneficiaryNameBusiness')
            ),
        identification: Yup.string()
            .test('identification',
                t('form.validator.required'),
                (value) => !(beneficiaryType === BeneficiaryType.BUSINESS && isEmpty(value))),
        ...(!isGbpPayment ? {
            beneficiaryIban: Yup.string().required(t('form.validator.required')).test('iban',
                t('form.validator.invalidIban'),
                () => ibanIsValid),
            bicswift: Yup.string().test('bicswift',
                t('form.validator.required'),
                () => ibanData?.bic ? true : false),
        } :
            {
                sortCode: Yup.string().trim()
                    .required(t('form.validator.required'))
                    .matches(/^\d{6}$/, {
                        message: _.capitalize(t('form.validator.minChars', { chars: '6', field: t('fields.sortCode') }).toString().trim()),
                        excludeEmptyString: true
                    }),

                accountNumber: Yup.string().trim()
                    .required(t('form.validator.required'))
                    .matches(/^\d{8}$/, {
                        message: _.capitalize(t('form.validator.minChars', { chars: '8', field: t('fields.accountNumber') }).toString().trim()),
                        excludeEmptyString: true
                    }),
            }),
    });

    const submit = async (formData: any, formikProps: any) => {
        const { setSubmitting, setFieldError } = formikProps;
        const { beneficiary, identification, accountNumber, sortCode } = formData;

        if (isGbpPayment) {
            let payload: GbpPaymentValidationPayload = {
                accountNumber,
                sortCode,
                name: beneficiary,
                business: beneficiaryType === BeneficiaryType.BUSINESS,
            }

            const result = await validateGbpPaymentData(account.accountId, payload);

            if (result.matched === false) {
                setFieldError('accountNumber', result.falseMatchDetails.description);
                return;
            }
        }

        setSubmitting(true);
        onNext({
            beneficiary,
            beneficiaryType,
            identification,
            ibanData,
            accountNumber,
            sortCode,
            iban,
        })
        setSubmitting(false);


    };

    const handleIbanChange = async (event: React.ChangeEvent<any>, setFieldValue: any, setFieldTouched: any) => {
        const iban = event.target.value.replaceAll(' ', '');
        setFieldValue('beneficiaryIban', iban);
        setTimeout(() => {
            setFieldTouched('beneficiaryIban', true);
        }, 500);
        setIban(iban);
    };

    useEffect(() => {
        if (!isValidIBAN(iban)) {
            setIbanIsValid(false);
            setIbanData(null);
        } else {
            const get = async () => {
                const $ibanData = await getIbanData(
                    iban.replace(/\s/g, '')
                );
                if ($ibanData.valid) {
                    setIbanIsValid(true);
                } else {
                    setIbanIsValid(false);
                }
                setIbanData($ibanData);
            };
            get();
        }
    }, [iban])

    return (
        <>
            <DialogTitle>
                <FormTitle
                    title={t('titles.recipientDetails')}
                    onClose={onClose}
                    onBack={onBack}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors, setFieldValue, setFieldTouched }) => (
                        <Form>
                            <Grid container gap={2}>
                                <Grid item xs={12} display='flex'>
                                    <ButtonStyleTabs
                                        value={beneficiaryType}
                                        onChange={(event: any, newValue: any) => setBeneficiaryType(newValue)}
                                    >
                                        <ButtonStyleTab value={BeneficiaryType.INDIVIDUAL} label={t('fields.individual')} />
                                        <ButtonStyleTab value={BeneficiaryType.BUSINESS} label={t('fields.business')} />
                                    </ButtonStyleTabs>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="beneficiary"
                                        fullWidth
                                        label={t('fields.beneficiary')}
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                {beneficiaryType === BeneficiaryType.BUSINESS && <Grid item xs={12}>
                                    <FormTextField
                                        name="identification"
                                        fullWidth
                                        label={t('fields.registrationNumber')}
                                        autoComplete="new-password"
                                    />
                                </Grid>}
                                {isGbpPayment ? <Grid item xs={12} container columnSpacing={2}>
                                    <Grid item xs={6}>
                                        <FormTextField
                                            name="accountNumber"
                                            fullWidth
                                            label={t('fields.accountNumber')}
                                            autoComplete="new-password"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormTextField
                                            name="sortCode"
                                            fullWidth
                                            label={t('fields.sortCode')}
                                            autoComplete="new-password"
                                        />
                                    </Grid>
                                </Grid> : <>
                                    <Grid item xs={12}>
                                        <FormTextField
                                            name="beneficiaryIban"
                                            fullWidth
                                            label={t('fields.iban')}
                                            autoComplete="new-password"
                                            value={iban}
                                            onChange={(event) => handleIbanChange(event, setFieldValue, setFieldTouched)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container columnSpacing={2}>
                                        <Grid item xs={6}>
                                            <FormTextField
                                                name='bic'
                                                label={t('fields.bic')}
                                                fullWidth
                                                value={ibanData ? ibanData.bic : ''}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormTextField
                                                name='bankCountry'
                                                label={t('fields.bankCountry')}
                                                fullWidth
                                                value={ibanData ? countries[ibanData.country] : ''}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </>}
                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors) || (!isGbpPayment && !ibanIsValid)}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        {t('buttons.continue')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </>
    )

};

export default SendMoneyBankRecipientDetails;
