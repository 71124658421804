import { ExternalPaymentType } from '@features/beneficiary/beneficiaryTypes';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = {
	name: string;
	label: string;
	value: ExternalPaymentType;
	onChange: (value: ExternalPaymentType) => void;
};



const FormPaymentTypeRadio = ({ name, label, value, onChange }: Props) => {
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);


	return <FormControl>
		<FormLabel >{label}</FormLabel>
		<RadioGroup
			row
			value={value}
			onChange={(e, v) => {
				helpers.setValue(v, true);
				helpers.setTouched(true, false);
				onChange(v as ExternalPaymentType);
			}}
		>
			<FormControlLabel value={ExternalPaymentType.STANDARD} control={<Radio />} label={t('fields.standard')} />
			<FormControlLabel value={ExternalPaymentType.INSTANT} control={<Radio />} label={t('fields.instant')} />
		</RadioGroup>
		{meta && meta.touched && Boolean(meta.error) && <FormHelperText error>{meta && meta.touched && meta.error as string}</FormHelperText>}
	</FormControl>;
};

export default FormPaymentTypeRadio;