export interface TwoFAPayload {
	open: boolean,
	originalRequest: any
}

export interface TwoFaStore {
	open: boolean,
	loading: boolean,
	setupMissing: boolean,
	invalid: boolean,
	errorCode: string | null
}

export enum TwoFaErrorParamType {
	AUTH_CODE = 'authenticatorCode', 
	SETUP_AUTH_CODE='two_factor_auth'
}
