import { Buffer } from 'buffer';

export const getGenerateKeyPair = async () => {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt']
  );

  const exported = await window.crypto.subtle.exportKey(
    'spki',
    keyPair.publicKey
  );

  const publicUniarray = new Uint8Array(exported);
  const exportedAsBase64 = Buffer.from(publicUniarray).toString('base64');
  return {
    privateKey: keyPair.privateKey,
    publicKey: exportedAsBase64,
  };
};

export const decrypt = async (privateKey: any, data: string) => {
  const base64Buffer = Buffer.from(data, 'base64');

  const decryptedBuffer = await window.crypto.subtle.decrypt(
    { name: 'RSA-OAEP' },
    privateKey,
    base64Buffer
  );

  const decryptedString = Buffer.from(decryptedBuffer).toString('utf8');
  return JSON.parse(decryptedString);
};
