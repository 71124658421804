import * as React from 'react';
import { SvgIcon } from '@mui/material';

const CardsIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.52305 14.4851C6.02599 14.4851 5.62305 14.8881 5.62305 15.3851C5.62305 15.8822 6.02599 16.2851 6.52305 16.2851H12.786C13.283 16.2851 13.686 15.8822 13.686 15.3851C13.686 14.8881 13.283 14.4851 12.786 14.4851H6.52305Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.67041 7.98667C1.67041 6.38504 2.96878 5.08667 4.57041 5.08667H20.5704C22.172 5.08667 23.4704 6.38504 23.4704 7.98667V17.9867C23.4704 19.5883 22.172 20.8867 20.5704 20.8867H4.57041C2.96879 20.8867 1.67041 19.5883 1.67041 17.9867V7.98667ZM4.57041 6.88667C3.9629 6.88667 3.47041 7.37916 3.47041 7.98667V9.78589H21.6704V7.98667C21.6704 7.37916 21.1779 6.88667 20.5704 6.88667H4.57041ZM3.47041 17.9867V11.5859H21.6704V17.9867C21.6704 18.5942 21.1779 19.0867 20.5704 19.0867H4.57041C3.9629 19.0867 3.47041 18.5942 3.47041 17.9867Z" fill="white" />
        </svg>

    </SvgIcon>
);

export default CardsIcon;
