
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { useSelector } from '@store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbNameArray } from '../types/global';
import CustomIconButton from '@components/CustomIconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Link from '@mui/material/Link';
import {
    Link as RouterLink,
    useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { normalizeString } from '@utils/normalizeString';
import { memo } from 'react';
import { getText } from '@utils/translate';
import useCustomBreakpoints from '@hooks/useCustomBreakpoints';

function BreadcrumbsNav() {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const location = useLocation();
    const { isMobile } = useCustomBreakpoints();
    const storedElementNames = useSelector((state) => state.menu.pathElements);
    const lang = useSelector((state) => state.status.activeLanguage);

    const params = useParams();
    const pathParamsArray = Object.entries(params).map(([k, v]) => ({ key: k, value: v }));


    const pathnames = location.pathname.split('/').filter((x) => x) || [];
    const elementsToDisplayAll = pathnames.filter(e => { const b = breadcrumbNameArray.find(b => b.pathElement === e); return (!b || b?.show); })

    const elementsToDisplay = elementsToDisplayAll;
    //replace witdh didsplay values
    const relacedParams = elementsToDisplay.map(e => {
        const param = pathParamsArray.find(p => p.value === e);
        if (param) {
            const name = storedElementNames.find(n => n.key === param.key && n.pathValue.toString() === param.value?.toString());
            pathParamsArray.shift();
            const result = getText(name?.titles, lang?.id) || name?.name || e
            return result;

        } else {
            const crumb = breadcrumbNameArray.find(c => c.pathElement === e);
            return crumb ? t(crumb.displayName) : e;
        }
    });

    return (<Grid container flexWrap='nowrap' alignItems='center'>
       {relacedParams.length > 1 && <Grid item xs='auto' mr={1}>
             <CustomIconButton onClick={() => navigate(-1)}>
                <ArrowBackIosNewIcon sx={{ fontSize: isMobile ? '14px' : '18px' }} />
            </CustomIconButton>
        </Grid>}
        <Grid item xs >
            <Breadcrumbs aria-label="breadcrumb"
                maxItems={isMobile ? 2 : 8} 
                sx={{ 
                    '& .MuiBreadcrumbs-ol': {
                        flexWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',

                    },
                   /*  '& .MuiBreadcrumbs-li:last-of-type': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',

                    }, */
                    '& .MuiBreadcrumbs-li': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',

                    }
                }}>
                {relacedParams.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 2).join('/')}`;
                    const pathTitle = value || '';
                    const translation = pathTitle ? t(pathTitle) : normalizeString(value);

                    return last ? (
                        <Typography key={to} variant='h6' color='text.primary'>
                            {translation}
                        </Typography>
                    ) : (
                        <Link component={RouterLink as any} to={to} key={to}
                            underline="hover"
                            sx={{ color: 'text.secondary' }}
                        >
                            <Typography key={to} variant='h6' color='text.secondary'
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }} >
                                {translation}
                            </Typography>
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Grid>
    </Grid>
    );

}


export default memo(BreadcrumbsNav);
