
export interface SupportStore {
	messages: Message[],
	loading: boolean,
	chat: Chat | null,
}

export enum ChatStatusEnum {
	NEW = 'NEW',
	SOLVED = 'SOLVED',
	PENDING_ON_CLIENT = 'PENDING_ON_CLIENT',
  }
  
  export interface Attachment {
	id: string;
	chatId: string;
	chatMessageId: string;
	name: string;
	size: string;
	mimetype: string;
	key: string;
	createdDate: string;
	filePreviewUrl: string;
  };

export interface Chat {
	id: string;
	userId: string;
	wlpId: string;
	status: ChatStatusEnum;
	deleted: boolean;
	createdDate: string;
	updatedDate: string;
  };

  export interface Message {
	id: string;
	chatId: string;
	message: string | null;
	authorId: string;
	readByClient: boolean;
	clientMessage: boolean;
	createdDate: string;
	updatedDate: string;
	attachments: Attachment[];
  };

  export type SendMessagePayload = {
	message?: string | null;
	files?: File | null;
	chatId?: string | null;
  };