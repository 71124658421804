import { Grid } from "@mui/material";
import { dispatch, useSelector } from "@store/store";
import { useCallback, useEffect } from "react";
import AccountCard from "./components/AccountCard";
import AccountCardSkeleton from "./components/AccountCardSkeleton";
import { getRecentActivitiesList } from "@features/transactions/transactionsSlice";
import RecentActivities from "./components/RecentActivities";
import useAllowedActions from "@hooks/useAllowedActions";
import StatsCards from "./components/StatsCards";

const Home = () => {

    const { list, loading } = useSelector((state) => state.accounts);
    const { recentActivitiesList, recentActivitiesLoading } = useSelector((state) => state.transactions);
    const { canReadAccounts } = useAllowedActions();

    const getRecentActivities = useCallback(async () => {
        await dispatch(getRecentActivitiesList());
    }, []);

    useEffect(() => {
        if (!canReadAccounts) { return; }
        getRecentActivities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canReadAccounts]);

    return (<Grid container gap={2} rowGap={4}>
        <StatsCards />
        {canReadAccounts && <>
            <Grid item xs={12} container spacing={2}>
                {loading || !list || list.length === 0 ? <>
                    <Grid item xs={12} md={4}>
                        <AccountCardSkeleton />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AccountCardSkeleton />
                    </Grid>
                </> : <>
                    {list.map(acc => <Grid item xs={12} md={4} key={acc.accountId}><AccountCard account={acc} /></Grid>)}
                </>}

            </Grid>
            <Grid item xs={12}>
                <RecentActivities list={recentActivitiesList} loading={recentActivitiesLoading} />
            </Grid>
        </>}
    </Grid>)

}
export default Home;