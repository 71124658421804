import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface Props {
	value: string;
	valueFontSize?: string;
}
const CopyBox = ({ value, valueFontSize }: Props) => {
	const theme = useTheme();
	return (<Box padding='0.625rem 1.25rem' sx={{ backgroundColor: theme.palette.background.default, borderRadius: '10px' }}>
		<Grid container justifyContent='space-between' alignItems='center'>
			<Grid item xs={8} >
				<Typography variant='h6' sx={{ fontSize: valueFontSize ?? undefined }}>{value}</Typography>
			</Grid>
			<Grid item >
				<CopyToClipboard text={value}>
					<IconButton>
						<ContentCopyIcon />
					</IconButton>
				</CopyToClipboard >
			</Grid>
		</Grid>
	</Box>);
};

export default CopyBox;
