import { useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import { useEffect } from 'react';
import { GuardProps, Path } from '../types/global';


const GuestGuard = ({ children }: GuardProps) => {
    const { isAuthorized } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthorized) {
            navigate(Path.DASHBOARD_PATH, { replace: true });
        }
    }, [isAuthorized, navigate]);

    return children;
};

export default GuestGuard;
