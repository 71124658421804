import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const OneTwoDaysChip = () => {
    const { t } = useTranslation();

    return <Chip size="small" label={t('chips.oneTwoDays')} icon={<AccessTimeIcon />} />;
}

export default OneTwoDaysChip;