import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { CardsStore, CardCreationOption, Address } from './cardsTypes';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import { decrypt, getGenerateKeyPair } from '@utils/encrypt';
import { isEmpty } from 'lodash';



const initialState: CardsStore = {
	createOptions: [],
	groupedCards: [],
	singleCards: [],
	optionsLoading: false,
	listLoading: false,
};

const slice = createSlice({
	name: 'cards',
	initialState,
	reducers: {
		setCreateOptions(state, action: PayloadAction<CardCreationOption[]>) {
			state.createOptions = action.payload;
		},
		setGroupedCards(state, action: PayloadAction<any[]>) {
			state.groupedCards = action.payload;
		},
		setSingleCards(state, action: PayloadAction<any[]>) {
			state.singleCards = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.listLoading = action.payload;
		},
		setOptionsLoading(state, action: PayloadAction<boolean>) {
			state.optionsLoading = action.payload;
		},
	}
});

export const { setCreateOptions, setGroupedCards, setSingleCards, setLoading, setOptionsLoading } = slice.actions;


export const getCardsList = (withoutLoading?: boolean) => {
	return async (dispatch: AppDispatch) => {
		try {
			!withoutLoading && dispatch(setLoading(true));
			const response = await request.get('/api-defi-network/cards');
			const data = response?.data || [];
			const groupedCards = data.filter((g: { groupType: string; }) => g.groupType === 'MULTIPLE') || [];
			const singleCards = data.filter((g: { groupType: string; }) => g.groupType === 'SINGLE') || [];

			dispatch(setGroupedCards(groupedCards));
			dispatch(setSingleCards(singleCards));
		}
		catch (e) {
			console.log(e);
			dispatch(setGroupedCards([]));
			dispatch(setSingleCards([]));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getCreateOptionsList = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setOptionsLoading(true));
			const response = await request.get('/api-defi-network/cards/create/options');
			const data = response?.data || [];
			dispatch(setCreateOptions(data));
		}
		catch (e) {
			dispatch(setCreateOptions([]));
		} finally {
			dispatch(setOptionsLoading(false));
		}
	};
};

export const validateDeliveryAddress = async (payload: Address) => {
	const response = await request.post('/api-defi-network/cards/validateaddress', payload);
	return response?.data;
};

export const getCardSettings = async (proc: string) => {
	const response = await request.get(`/api-defi-network/cards/create/options?proc=${proc}`);
	const { data } = response;
	return data;
};


export const getShipmentOptions = async (integration: string, country: string) => {
	const response = await request.post('/api-defi-network/cards/delivery-options', { integration, country });
	const { data } = response;
	return data;
};

export const getCardActivationScenario = async (type: string, cardId: number, cardStatus: string) => {
	const response = await request.get(`/api-defi-network/cards/scenario/${type}`, {
		params: {
			cardId,
			cardStatus
		}
	});
	const { data } = response;
	return data;
};

export const requestVfCode = async (cardId: number, type = 'email') => {
	const payload = {
		cardId,
		type
	};
	const response = await request.post('/api-defi-network/cards/vfcode', payload);
	return response?.data;
};

export const getCardPin = async (cardId: number, verificationCode?: string) => {
	const keyPairs = await getGenerateKeyPair();
	const response = await request.post('/api-defi-network/cards/details/pin', {
		cardId,
		publicKey: keyPairs.publicKey,
		verificationCode: verificationCode
	});
	const data = response?.data?.cardData;
	if (data) {
		return await decrypt(keyPairs.privateKey, data);

	} else {
		return undefined;
	}
};

export const getCardPan = async (cardId: number, integration: string) => {
	const keyPairs = await getGenerateKeyPair();
	const response = await request.post('/api-defi-network/cards/details/pan', {
		cardId,
		publicKey: keyPairs.publicKey,
		integration,
	});

	if (integration === 'DECTA') {
		const data = response?.data?.cardData;
		if (data) {
			return await decrypt(keyPairs.privateKey, data);

		} else {
			return undefined;
		}
	} else {
		const data = response?.data;

		return data;
	}
};

export const getCardTransactions = async (cardId: number, skip: number, search?: string) => {
	const params = {
		skip,
		search,
		take: 20,
	};
	const response = await request.get(`/api-defi-network/cards/transactions/${cardId}`, { params });
	return response?.data;
};

let socketService: SocketService | null;
let updateSubscriber: Subscription;

export const connectCardsSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('cards');
	}
};

export const disconnectCardsSocket = (): void => {
	socketService = null;
};

export const subscribeCardsSocket = () => {
	return async (dispatch: AppDispatch) => {
		if (!socketService) return;
		try {
			updateSubscriber = socketService.listen('card.data', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(getCardsList(true));
			});
		} catch (error) {
			console.log(error);
		}
	};

};

export const unsubscribeCardsSocket = (): void => {
	updateSubscriber.unsubscribe();
}

export const downloadCardStatementFile = async (
	fileExtension: string,
	payload: any,
	name: string
  ) => {
	const response = await request.post(
	  `/api-defi-network/cards/statement/${fileExtension}`,
	  payload,
	  {
		responseType: "blob",
	  }
	);
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement("a");
	link.download = name;
	link.href = url;
	link.className = "hidden";
	document.body.appendChild(link);
  
	link.onclick = function () {
	  requestAnimationFrame(function () {
		URL.revokeObjectURL(url);
		setTimeout(() => link.remove(), 300);
	  });
	};
  
	link.click();
  };

export default slice.reducer;
