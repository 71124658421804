import { Account } from "@features/accounts/accountsTypes";
import { DialogContent, DialogTitle, Grid, InputAdornment, Typography } from "@mui/material";
import FormTitle from "@components/form/FormTitle";
import { useTranslation } from "react-i18next";
import { Contact } from "@features/beneficiary/beneficiaryTypes";
import * as Yup from 'yup';
import useFeeDebounce from "@hooks/useFeeDebounce";
import { useMemo, useState } from "react";
import bigDecimal from "js-big-decimal";
import { NEW_LINE_REGEX } from "@hooks/useValidations";
import { findProcessByType } from "@features/accounts/accountsSlice";
import LoadingButton from "@components/LoadingButton";
import { isEmpty } from "lodash";
import FormTextField from "@components/form/FormTextField";
import FormAmountTextField from "@components/form/FormAmountTextField";
import { getCurrencySign } from "@utils/currency";
import { Form, Formik } from "formik";
import { roundAny, toNumber } from "@utils/round";
import { numericFormatter } from "react-number-format";
import { lookupSystemUserContact } from "@features/beneficiary/beneficiarySlice";

interface Props {
    account: Account;
    onBack: () => void;
    onClose: () => void;
    onNext: (payload: any) => void;
    contact: Contact | null;
    detailsPayload: any;
}


const SendMoneyToOtherUserDetails = ({ account, onBack, onClose, contact, detailsPayload, onNext }: Props) => {
    const { t } = useTranslation();

    const [nextBlocked, setNextBlocked] = useState<boolean>(true);
    const [amount, setAmount] = useState<string>(detailsPayload?.amount ?? '');
    const { fee, callGetFee, feeLoading } = useFeeDebounce(0);

    const transferToOtherUserProcess = useMemo(() => findProcessByType(account, 'TRANSFER') || [], [account]);
    const youPay = bigDecimal.add(amount, fee);

    const initialValues = {
        amount: detailsPayload?.amount ?? '',
        reference: detailsPayload?.reference ?? '',
        username: contact ? contact.clientId : detailsPayload?.username ?? '',
    };

    const validationScheme = Yup.object({
        amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
            .moreThan(0, t('form.validator.moreThanZero'))
            .test('amount', t('form.validator.insufficientFunds'),
                () => bigDecimal.compareTo(youPay, account?.availableBalance ?? 0) <= 0),
        reference: Yup.string().trim()
            .required(t('form.validator.required'))
            .matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
            .test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
            .min(6, t('form.validator.minChars', { field: t('fields.reference'), chars: '6' }))
            .max(140, t('form.validator.maxAllowChars', { chars: 140 }))
            .test('reference', t('form.validator.minChars', { field: t('fields.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
        username: Yup.string().required(t('form.validator.required')),
    });

    const handleAmountChange = (_amount: string, setFieldValue: any, setFieldTouched: any) => {
        setNextBlocked(true);
        _amount = _amount.replaceAll(',', '');
        setAmount(_amount)
        setFieldValue('amount', _amount);
        setTimeout(() => setFieldTouched('amount', true));
        setTimeout(() => {
            setNextBlocked(false);
        }, 500);
        const parsed = toNumber(_amount);
        const newAmount = roundAny(parsed, 2);
        callGetFee(account.accountId, transferToOtherUserProcess?.proc, newAmount);

    };

    const formattedFee = useMemo(() => {
        return numericFormatter(fee.toString(), {
            displayType: 'text',
            decimalScale: 2,
            fixedDecimalScale: true,
            thousandsGroupStyle: 'thousand',
            thousandSeparator: true,
            valueIsNumericString: true,
            prefix: `${getCurrencySign(account.currency)}`
        });
    }, [account.currency, fee])

    const submit = async (formData: any, formikProps: any) => {
        const { setSubmitting, setFieldError } = formikProps;
        const { reference, username } = formData;
        let user = null;

        try {
            user = await lookupSystemUserContact(contact?.clientId ?? username, account.accountId);
            if (!user) {
                setFieldError('username', t('form.validator.invalidUsername'));
                return;
            }
        } catch (err) {
            setFieldError('username', t('form.validator.invalidUsername'));
            return;
        }


        setSubmitting(true);
        onNext({
            amount,
            reference,
            fee,
            process: transferToOtherUserProcess,
            accountToId: user?.accountId,
            recipient: user?.companyName ?? `${user.firstName} ${user.lastName}`,
            isBusiness: user?.companyName ? true : false,
            youPay
        })
        setSubmitting(false);
    };

    return (
        <>
            <DialogTitle>
                <FormTitle
                    title={t('titles.paymentDetails')}
                    onClose={onClose}
                    onBack={onBack}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors, setFieldValue, setFieldTouched }) => (
                        <Form>
                            <Grid container gap={2}>
                                <Grid item xs={12} mt={1}>
                                    <FormTextField
                                        name="username"
                                        fullWidth
                                        label={t('fields.username')}
                                        autoComplete="new-password"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <FormAmountTextField
                                        name="amount"
                                        onChange={value => handleAmountChange(value, setFieldValue, setFieldTouched)}
                                        value={amount}
                                        label={t('fields.amount')}
                                        currencySign={getCurrencySign(account.currency)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="reference"
                                        fullWidth
                                        label={t('fields.reference')}
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                {fee > 0 && <Grid item xs={12}>
                                    <Typography variant="subtitle1" color={'text.secondary'}>
                                        {t('texts.feeNote', { fee: formattedFee })}
                                    </Typography>
                                </Grid>}

                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors) || feeLoading || nextBlocked}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting || feeLoading}
                                    >
                                        {t('buttons.continue')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent >
        </>
    );
}



export default SendMoneyToOtherUserDetails;

