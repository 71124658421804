import { showError, showSuccess } from "@components/Alerts";
import ConfirmationLine from "@components/ConfirmationLine";
import FormTitle from "@components/form/FormTitle";
import LoadingButton from "@components/LoadingButton";
import { Account } from "@features/accounts/accountsTypes";
import { runOperation } from "@features/operations/operationsSlice";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import { getCurrencySign } from "@utils/currency";
import { useState } from "react";
import { useTranslation } from "react-i18next";



interface Props {
    account: Account;
    payload: any;
    onBack: () => void;
    onClose: () => void;
}


const SendMoneyToOtherUserConfirmation = ({ account, payload, onBack, onClose }: Props) => {

    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const beneName = payload.recipient;
    const reference = payload.reference;
    const amount = payload.amount;
    const fee = payload.fee;
    const youPay = payload.youPay;
    const currencySign = getCurrencySign(account.currency);


    const handleSubmit = async () => {
        const $payload = {
            accountFromId: account.accountId,
            accountToId: payload.accountToId,
            amount,
            details: reference,
            saveToContacts: true,
        }

        try {
            setSubmitting(true);
            await runOperation(payload?.process.proc, $payload);
            showSuccess({ text: t('alerts.paymentSubmitted') });
        } catch (error) {
            showError({error});
        } finally {
            setSubmitting(false);
            onClose();
        }
    }


    return (
        <>
            <DialogTitle>
                <FormTitle
                    title={t('titles.paymentConfirmation')}
                    onClose={onClose}
                    onBack={onBack}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container gap={1}>
                    <Grid item xs={12}>
                        <ConfirmationLine
                            label={t('fields.beneficiary')}
                            value={beneName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ConfirmationLine
                            label={t('fields.reference')}
                            value={reference}
                        />
                    </Grid>
                    <Grid item xs={12} container direction='row' justifyContent='space-between'>
                        <Grid item xs>
                            <ConfirmationLine
                                label={t('fields.amount')}
                                value={amount}
                                amountValue
                                currencySing={currencySign}
                            />
                        </Grid>
                        <Grid item xs>
                            <ConfirmationLine
                                label={t('fields.fee')}
                                value={fee}
                                amountValue
                                currencySing={currencySign}
                            />
                        </Grid>
                        <Grid item xs>
                            <ConfirmationLine
                                label={t('fields.youPay')}
                                value={youPay}
                                amountValue
                                currencySing={currencySign}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <LoadingButton
                            disabled={submitting}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                            loading={submitting}
                        >
                            {t('buttons.submit')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}



export default SendMoneyToOtherUserConfirmation;