import { Navigate, Outlet } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from '@components/Loadable';
import GuestGuard from '@guards/GuestGuard';
import { LegalPath } from '../types/global';
import LegalLayout from '@layout/LegalLayout';

const PrivacyPolicy = Loadable(lazy(() => import('@pages/policies/PrivacyPolicy')));
const TermsAndCondtions = Loadable(lazy(() => import('@pages/policies/TermsAndCondtions')));

const LegalRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <LegalLayout>
                <Outlet />
            </LegalLayout>
        </GuestGuard>
    ),
    children: [
        {
            path: LegalPath.TERMS_AND_CONDITIONS,
            element: <TermsAndCondtions />
        },
        {
            path: LegalPath.PRIVACY_POLICY,
            element: <PrivacyPolicy />
        },
        //Redirect to login
        {
            path: '*',
            element: <Navigate to='/' replace={true} />
        },
    ]
};

export default LegalRoutes;

