import { Account } from "@features/accounts/accountsTypes";
import { Dialog} from "@mui/material";
import { useState } from "react";
import ChooseDepositType from "./transfers/ChooseDepositType";
import AddMoneyViaBankTranfer from "./transfers/AddMoneyViaBankTransfer";
import TransferBetweenOwnAccounts from "./transfers/TransferBetweenOwnAccounts";
import AddMoneyCryptoTopUp from "./transfers/AddMoneyCryptoTopUp";

export enum DepositType {
    CHOOSE_TYPE = 'CHOOSE_TYPE',
    BANK_TRANSFER = 'BANK_TRANSFER',
    TOP_UP_BY_CRYPTO = 'TOP_UP_BY_CRYPTO',
    TRANSFER_BETWEEN_ACCOUNTS = 'TRANSFER_BETWEEN_ACCOUNTS',
}

interface Props {
    open: boolean;
    onClose: () => void;
    account: Account;
}

const AddMoneyDialog = ({ open, onClose, account }: Props) => {
    const [step, setStep] = useState<DepositType>(DepositType.CHOOSE_TYPE);

    const handleClose = () => {
        onClose();
        setStep(DepositType.CHOOSE_TYPE);
    }

    return (
        <Dialog
            open={open}
            maxWidth='xs'
        >
                {step === DepositType.CHOOSE_TYPE && <ChooseDepositType onChoose={(type: DepositType) => setStep(type)} account={account} onClose={handleClose} />}
                {step === DepositType.BANK_TRANSFER && <AddMoneyViaBankTranfer onBack={() => setStep(DepositType.CHOOSE_TYPE)} account={account} onClose={handleClose} />}
                {step === DepositType.TRANSFER_BETWEEN_ACCOUNTS && <TransferBetweenOwnAccounts onBack={() => setStep(DepositType.CHOOSE_TYPE)} account={account} direction="TO" onClose={handleClose} />}
                {step === DepositType.TOP_UP_BY_CRYPTO && <AddMoneyCryptoTopUp onBack={() => setStep(DepositType.CHOOSE_TYPE)} onClose={handleClose} />}
        </Dialog>
    );
};

export default AddMoneyDialog;