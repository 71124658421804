export const DEFAULT_CCY_SYMBOL = '€';

export const getCurrencySign = (currency: string): string => {
	switch (currency) {
		case 'EUR': return '€';
		case 'GBP': return '£';
		case 'USD': return '$';
		default: return currency;
	}
};


export const removeTrailingZeros = (value: string) => {
	let trailed = value.replace(/(\.\d*?)0+$/, '$1');
	if (trailed.endsWith('.')) {
		return trailed.slice(0, -1);
	} else {
		return trailed;
	}
};

export const getActualDecimals = (value: string, minDecimals: number = 2) => {
	if (!value) return minDecimals;
	const trailed = removeTrailingZeros(value);
	const trailedArr = trailed.split('.');
	if (trailedArr.length === 1) {
		return minDecimals;
	} else {
		const actualDecimals = trailedArr[1].length;
		return actualDecimals > minDecimals ? actualDecimals : minDecimals;
	}
};
