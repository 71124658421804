import { setActiveLang } from "@features/status/statusSlice";
import { MenuItem, Select } from "@mui/material";
import { dispatch } from "@store/store";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const supportedLanguages = ['en', 'it'];

    const changeLanguage = (event: any) => {
        const language = event.target.value as string;
        i18n.changeLanguage(language);
        dispatch(setActiveLang(language));
    };

    const _value = i18n.language === 'en' ? 'gb' : i18n.language;

    return (
        <Select
            value={i18n.language}
            onChange={changeLanguage}
            variant="standard"
            disableUnderline
            sx={{
                '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }
            }}
            IconComponent={() => null}
            renderValue={() => <img
                loading="lazy"
                width="21"
                height="14"
                src={`https://flagcdn.com/w20/${_value}.png`}
                srcSet={`https://flagcdn.com/w40/${_value}.png 2x`}
                alt=""
            />
            }
        >
            {supportedLanguages.map(lng => <MenuItem value={lng} key={lng}>
                <img
                    loading="lazy"
                    width="21"
                    height="14"
                    src={`https://flagcdn.com/w20/${lng === 'en' ? 'gb' : lng}.png`}
                    srcSet={`https://flagcdn.com/w40/${lng === 'en' ? 'gb' : lng}.png 2x`}
                    alt=""
                />
            </MenuItem>)}
        </Select>
    )
};

export default LanguageSwitcher;