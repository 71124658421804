import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@store/store';
import request from '../../services/request';
import { ComuneType, ItalyTerritory } from './italyTerritoryTypes';


const initialState: ItalyTerritory = {
    comuneOptions: [],
    comuneList: [],
};


const italyTerritorySlice = createSlice({
    name: 'italyTerritory',
    initialState,
    reducers: {
        setComunes(state, action: PayloadAction<ComuneType[]>) {
            state.comuneOptions = action.payload.map(p => ({ value: p.codiceCatastale, label: `${p.nameIt}  (${p.regionIt})` })) || [];
            state.comuneList = action.payload || [];
        },

    }
});

export const { setComunes } = italyTerritorySlice.actions;

export const getComuneList = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await request.get('/api-defi-network/fnd/comunes');
            dispatch(setComunes(response?.data || []));
        }
        catch (e) {
            dispatch(setComunes([]));

        }
    };
};

export default italyTerritorySlice.reducer;
