import { Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const Typography = (theme: Theme, fontFamily: string): TypographyOptions => ({
    fontFamily,
    /* h1: {
        fontSize: '1.75rem',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
            lineHeight: '1.5rem',
        }
    }, */
    h1: {
        fontWeight: 600, 
        fontSize: '40px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px', 
        }
    }, 
    h5: {
        fontWeight: 500, 
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px', 
        }
    }, 
    h6: {
        fontWeight: 500, 
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px', 
        }
    },
   body1: {
        fontWeight: 500, 
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px', 
        }
    },  
    body2: {
        fontWeight: 400, 
        fontSize: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '11px', 
        }
    }, 
    subtitle1: {
        fontWeight: 400, 
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px', 
        }
    }, 
    subtitle2: {
        fontWeight: 400, 
        fontSize: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px', 
        }
    }, 
    
});

export default Typography;
