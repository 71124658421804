import { Grid, Toolbar } from '@mui/material';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { dispatch } from '@store/store';
import { logout } from '@features/auth/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { Path } from '../types/global';
import { appBarHeight, mainGapTop } from '@theme/layoutConstant';
import useAuth from '@hooks/useAuth';
import CustomIconButton from '@components/CustomIconButton';
import { memo } from 'react';
import BreadcrumbsNav from './BreadcrumbsNav';
import useCustomBreakpoints from '@hooks/useCustomBreakpoints';
import Logo from '@components/Logo';

interface Props {
    showBearcrumbs?: boolean
    showLogo?: boolean
}
const ToolBarLayout = ({ showBearcrumbs, showLogo }: Props) => {
    const navigate = useNavigate();
    const { isAuthorized } = useAuth();

    const { isDesktop } = useCustomBreakpoints();

    const handleLogout = async () => {
        await dispatch(logout());
        navigate(Path.LOGIN, { replace: true });
        dispatch({ type: 'RESET_APP' });
    }

    return (
        <Toolbar
            disableGutters={true}
            sx={{
                marginBottom: `${showBearcrumbs ? mainGapTop : 0}px`,
                height: `${appBarHeight}px`,
                padding: 0,
            }}>
            {
                !isDesktop ?
                    <ContentSmall
                        showBearcrumbs={showBearcrumbs}
                        handleLogout={handleLogout}
                        isAuthorized={isAuthorized}
                        showLogo={showLogo}
                    /> :
                    <Content
                        showBearcrumbs={showBearcrumbs}
                        handleLogout={handleLogout}
                        isAuthorized={isAuthorized}
                        showLogo={showLogo}
                    />
            }
        </Toolbar>
    )

};


interface ContentProps {
    showBearcrumbs?: boolean,
    isAuthorized: boolean,
    handleLogout: () => void,
    showLogo?: boolean
}
const Content = ({ showBearcrumbs, isAuthorized, handleLogout, showLogo }: ContentProps) => {
    return (

        <Grid container alignItems='center'>
            <Grid item xs>
                {showLogo && <Link to="/">
                    <Logo />
                </Link>}
                {showBearcrumbs && <BreadcrumbsNav />}
            </Grid>
            <Grid item xs container justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <LanguageSwitcher />
                </Grid>
                <Grid item>
                    {isAuthorized &&
                        <CustomIconButton
                            edge="end"
                            onClick={handleLogout}
                            color='primary' size="small"
                        >
                            <LogoutIcon />
                        </CustomIconButton>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

const ContentSmall = ({ showBearcrumbs, isAuthorized, handleLogout, showLogo }: ContentProps) => {
    return (
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item xs={8}>
                {showLogo && <Link to="/">
                    <Logo />
                </Link>}
                {showBearcrumbs && <BreadcrumbsNav />}
            </Grid>
            <Grid item xs={3} container alignItems='center' justifyContent='flex-end' >
                <LanguageSwitcher />
                {isAuthorized &&
                    <CustomIconButton
                        edge="end"
                        onClick={handleLogout}
                        color='primary' size="small"
                    >
                        <LogoutIcon sx={{ fontSize: '14px' }} />
                    </CustomIconButton>
                }
            </Grid>

        </Grid>
    )
}


export default memo(ToolBarLayout);

