import { SupportedLanguages } from "@features/status/types";
import request from "@services/request";
import { TextTranslationType, ProductTextType, TranslatePayload, TranslateReponse } from "./translateSlice";


export const traslate = async (payload: TranslatePayload): Promise<TranslateReponse[]> => {
    const result = await request.post('/api-defi-network/translate', payload);
    return result?.data;
};



export const transformToFromTranslation = (
    languages: SupportedLanguages[],
    productText: ProductTextType[]): TextTranslationType[] => {
    const result: TextTranslationType[] = languages.map(p => ({ ...p, text: (productText?.length && productText.find(t => t.languageId === p.id)?.text?.trim()) || '' }));
    return result;
}
