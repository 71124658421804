
export const arrayToTree = (items: any = [], id = null, link = 'parentId') =>
	items.filter((item: any) => id == null ?
		!items.some((ele: any) => ele.id === item[link]) :
		item[link] === id).map((item: any) => ({ ...item, children: arrayToTree(items, item.id) }))


export const arrayProductToTree = (items: any = [], id = null, link = 'parentId') => {
	return items
		.filter((item: any) => id == null ?
			!items.some((ele: any) => ele.id === item[link]) :
			item[link] === id)
		.map((item: any) => {
			return ({ ...item, children: [...item?.children, ...arrayProductToTree(items, item.id)] })
		});
}
