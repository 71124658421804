
import { getAlpha2Code } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { validatePostalCode } from '../utils/postalCodeValidation';
import * as Yup from 'yup';
import * as validator from 'validator';
import { FormikCountryAutocompleteOptionType } from '@components/form/FormCountryAutocomplete';
import { convertDateToString } from '@utils/date';

export const LATIN_ADDRESS_REGEX = /^([a-zA-Z0-9-'`\s])+$/;
export const NEW_LINE_REGEX = /\n/;
export const NUMBERS_ONLY_REGEX = /[^0-9]/;

export const useCompanyName = (fieldName: any) => {
    const { t } = useTranslation();
    return Yup.string()
        .min(2, t('form.validator.companymin'))
        .max(99, t('form.validator.maxChars', { chars: 100, field: fieldName }))
        .required(t('form.validator.required'))
        .matches(/^([a-zA-Z0-9-&@£$€#:;.,'`\s])+$/, t('form.validator.companyName'));
};

export const useName = (fieldName: any) => {
    const { t } = useTranslation();
    return Yup.string()
        .min(2, t('form.validator.companymin'))
        .required(t('form.validator.required'))
        .matches(/^([a-zA-Z0-9-&@£$€#:;.,'`\s])+$/, t('form.validator.invalidValue', { field: fieldName }));
};


export const useCompanyRegistration = (fieldName: any) => {
    const { t } = useTranslation();
    return Yup.string()
        .min(2, t('form.validator.companymin'))
        .max(49, t('form.validator.maxChars', { chars: 50, field: fieldName }))
        .required(t('form.validator.required'))
        .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin'));
};



export const useDate = (fieldName: any) => {
    const { t } = useTranslation();
    return Yup.date()
        .max(new Date(), fieldName)
        .min(new Date('1900-01-01'), fieldName)
        .required(t('form.validator.required'))
        .typeError(t('form.validator.invalidDateFormat'));
};


export const useAddress = (fieldName: any) => {
    const { t } = useTranslation();
    return Yup.string()
        .min(2, t('form.validator.companymin'))
        .max(50, t('form.validator.maxChars', { chars: 50, field: fieldName }))
        .required(t('form.validator.required'))
        .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin'));
};



export const useLatinName = (fieldName: string) => {
    const { t } = useTranslation();
    return Yup.string()
        .label(fieldName)
        .min(2)
        .max(64)
        .required(t('form.validator.required'))
        .matches(/^[A-Za-z\s]+$/, t('form.validator.latinOnly'));
};

export const useEmail = () => {
    const { t } = useTranslation();
    return Yup.string()
        .required(t('form.validator.required'))
        .email(t('form.validator.email'))
        .test('email', t('form.validator.email'),
            (email) => {
                if (!email) return false;
                return validator.isAscii(email) && validator.isEmail(email, { ignore_max_length: true, allow_utf8_local_part: false });
            }
        );
};

export const useEmailOptional = () => {
    const { t } = useTranslation();
    return Yup.string().nullable().optional()
        .email(t('form.validator.email'))
        .max(255, t('form.validator.maxChars', { chars: 16, field: t('fields.email') }))
        .test('email', t('form.validator.email'),
            (email) => {
                return email && email?.length > 0 ? (
                    validator.isAscii(email) &&
                    validator.isEmail(email, { ignore_max_length: true, allow_utf8_local_part: false })
                ) : true
            }
        );
};

export const usePhone = () => {
    const { t } = useTranslation();
    return Yup.string()
        .matches(/^\s*([+]|[1-9])\s*[0-9\s]+$/, t('form.validator.phoneinvalid'))
        .min(4, t('form.validator.phonemin'))
        .test('phoneNumber',
            t('form.validator.phoneinvalid'),
            (value) => isValidPhoneNumber(`+${value?.replaceAll(' ', '').replaceAll('+', '').trim()}`))
        .required(t('form.validator.required'));
};



export const usePhoneOptional = () => {
    const { t } = useTranslation();
    return Yup.string().nullable().optional()
        .matches(/^\s*([+]|[1-9])\s*[0-9\s]+$/, t('form.validator.phoneinvalid'))
        .min(4, t('form.validator.phonemin'))
        .test('phoneNumber',
            t('form.validator.phoneinvalid'),
            (value) => { return value && value?.length > 0 ? isValidPhoneNumber(`+${value.replaceAll(' ', '').replaceAll('+', '').trim()}`) : true; });
};


export const usePasswordValidation = () => {
    const { t } = useTranslation();
    return Yup.string()
        .required(t('form.validator.required'))
        .min(8, t('registration.passwordvalidators.len'))
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])/, t('registration.passwordvalidators.upper'))
        .matches(
            /^(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.*?[^\w\s])/,
            t('registration.passwordvalidators.spec')
        );
};

export const usePasswordConfirmation = () => {
    const { t } = useTranslation();
    return Yup.string()
        .required(t('form.validator.required'))
        .oneOf([Yup.ref('password')], t('form.validator.confirmpassword'));
};


export const useBirthDateValidation = () => {
    const { t } = useTranslation();
    const maxDate = new Date();
    const minDate = new Date('1900-01-01');
    return Yup.date()
        .min(minDate, t('form.validator.minDate', { date: convertDateToString(minDate, 'dd-MM-yyyy') }))
        .max(maxDate, t('form.validator.maxDate', { date: convertDateToString(maxDate, 'dd-MM-yyyy') }))
        .required(t('form.validator.required'))
            .typeError(t('form.validator.invalidDateFormat'));
};


export const useAddressValidation = ({ maxCity, maxAddress }: { maxCity?: number, maxAddress?: number }) => {
    const { t } = useTranslation();
    const _maxCity = maxCity || 100;
    const _maxAddress = maxAddress || 1000;

    return Yup.object({
        city: Yup.string()
            .required(t('form.validator.required'))
            .max(_maxCity, t('form.validator.maxChars', { chars: _maxCity, field: t('form.fields.city') }))
            .matches(/^([a-zA-Z-'`\s])+$/, t('form.validator.latinAlpha')),
        address: Yup.string()
            .required(t('form.validator.required'))
            .max(_maxAddress, t('form.validator.maxChars', { chars: _maxAddress, field: t('form.fields.address') }))
            .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
        country: Yup.string().required(t('form.validator.required')).nullable(),
        postalCode: Yup.string()
            .required(t('form.validator.required'))
            .max(16, t('form.validator.maxChars', { chars: 16, field: t('form.fields.address') }))
            .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin'))
            .test(
                'postalCode',
                t('form.validator.invalidPostalCode'),
                (value, context) => {
                    const region = getAlpha2Code(context.parent.country, 'en');
                    if (value && region) {
                        try {
                            return validatePostalCode(value, region);
                        }
                        catch (e) {
                            return false;
                        }

                    }
                    return true;
                }
            )
    });

};


export const useDeliveryAddress = (countryList?: FormikCountryAutocompleteOptionType[]) => {
    const { t } = useTranslation();
    const countryOptions = countryList?.map(c => c.iso3CountryCode) || [];


    return Yup.object({
        city: Yup.string()
            .required(t('form.validator.required'))
            .max(20, t('form.validator.maxChars', { chars: 20, field: t('form.fields.city') }))
            .matches(/^([a-zA-Z-'`\s])+$/, t('form.validator.latinAlpha')),
        address: Yup.string()
            .required(t('form.validator.required'))
            .max(95, t('form.validator.maxChars', { chars: 95, field: t('form.fields.address') }))
            .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
        country: Yup.string().required(t('form.validator.required')).nullable().oneOf(countryOptions, t('form.validator.invalidCountry')),
        postalCode: Yup.string()
            .required(t('form.validator.required'))
            .max(16, t('form.validator.maxChars', { chars: 16, field: t('form.fields.address') }))
            .matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin'))
            .test(
                'postalCode',
                t('form.validator.invalidPostalCode'),
                (value, context) => {
                    const region = getAlpha2Code(context.parent.country, 'en');
                    if (value && region) {
                        try {
                            return validatePostalCode(value, region);
                        }
                        catch (e) {
                            return false;
                        }

                    }
                    return true;
                }
            )
    });

};

export const useComune = () => {
    const { t } = useTranslation();
    return Yup.string()
        .label(t('form.fields.field'))
        .required(t('form.validator.required'))
        .min(2)
        .max(100)
        .matches(/^([a-zA-Z0-9-'.,-_`\s])+$/, t('form.validator.latinOnly'));
};




export const useStoreValidation = () => {
    const { t } = useTranslation();
    const emailValidation = useEmailOptional();
    const phoneValidation = usePhoneOptional();


    return Yup.object({
        name: Yup.string().trim().required(t('form.validator.required'))
            .max(200, t('form.validator.maxChars', { chars: 200, field: t('fields.storeName') })),
        defaultTaxId: Yup.string().required(t('form.validator.required').toString()),
        country: Yup.mixed().required(t('form.validator.required').toString()),
        address: Yup.string().trim().required(t('form.validator.required')),
        city: Yup.string().trim().required(t('form.validator.required'))
            .max(100, t('form.validator.maxChars', { chars: 100, field: t('fields.city') })),
        zip: Yup.string().trim().required(t('form.validator.required'))
            .max(16, t('form.validator.maxChars', { chars: 16, field: t('fields.postalCode') })),
        websiteUrl: Yup.string().trim().nullable().optional()
            .max(255, t('form.validator.maxChars', { chars: 255, field: t('fields.websiteUrl') })),
        phone: phoneValidation,
        email: emailValidation,
    });


};