import * as React from 'react';
import { SvgIcon } from '@mui/material';

const IncomingTransferIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width={40}
            height={41}
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >

            <rect
                y={0.936035}
                width={40}
                height={40}
                rx={20}
                fill="#31F2DA"
                fillOpacity={0.1}
            />
            <path
                d="M20.9 13.178a.9.9 0 00-1.8 0v6.858h-6.858a.9.9 0 000 1.8H19.1v6.858a.9.9 0 001.8 0v-6.858h6.858a.9.9 0 000-1.8H20.9v-6.858z"
                fill="#31F2DA"
            />
        </svg>

    </SvgIcon>
);

export default IncomingTransferIcon;
