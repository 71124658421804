import React from 'react';
import { Container, Box, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '@components/Logo';
import AppBarLayout from './AppBarLayout';
import { mainGapTop, mainPadding } from '@theme/layoutConstant';

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" minHeight={`calc(100vh - ${mainPadding + mainGapTop}px)`}>
            <AppBarLayout />
            <Container
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                   // minHeight: 'calc(100vh - 64px - 78px)'
                }}
            >
                <Container
                    sx={{
                        maxWidth: { xs: 400, lg: 475 },
                        width: { xs: 400, lg: 475 },
                        borderRadius: '26px',
                        backgroundColor: theme.palette.secondaryBackground.main, //'rgba(255, 255, 255, 0.06)',
                        padding: { xs: 2, sm: 3, xl: 5 },
                        margin: 'auto',
                    }}
                >
                    {children}
                </Container>
            </Container>

            <Box component="footer" sx={{ py: 2, textAlign: 'center' }}>
                <Link to="/">
                    <Logo />
                </Link>
            </Box>
        </Box>
    );
};

export default AuthLayout;
