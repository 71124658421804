import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { CreateEmployeePayload, Employee, EmployeeStore, } from './employeesTypes';
import { PaginateParams, SortDirectionEnum } from '../../types/pagination';

export const initialEmployeePagination: PaginateParams = {
	filter: '',
	orderBy: 'createdDate',
	sort: SortDirectionEnum.DESC,
	skip: 0,
	take: 10,
	initial: true,
}

const initialState: EmployeeStore = {
	list: [],
	failed: false,
	loading: false,
	count: 0,
	pagination: initialEmployeePagination,
};

const slice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<Employee[]>) {
			state.list = action.payload;
		},
		setError(state, action: PayloadAction<boolean>) {
			state.failed = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setCount(state, action: PayloadAction<number>) {
			state.count = action.payload;
		},
		setPagination(state, action: PayloadAction<PaginateParams>) {
			state.pagination = action.payload;
		},
	}
});

export const { setError, setList, setLoading, setCount, setPagination } = slice.actions;


export const getEmployees = (params: PaginateParams) => {
	return async (dispatch: AppDispatch, state: () => { employees: any; }) => {
		const { employees } = state();
		const { list } = employees;

		try {
			dispatch(setLoading(true));
			dispatch(setError(false));
			const response = await request.get('/api-defi-network/employee/list', {
				params
			});
			const data = response?.data || null;
			dispatch(setPagination(params));
			dispatch(setList(params.initial ? data.list : [...list, ...data.list]));
			dispatch(setCount(data.count));
			dispatch(setLoading(false));
		}
		catch (e) {
			dispatch(setError(true));
		}
		dispatch(setLoading(false));
	};
};


export const createEmployee = async (payload: CreateEmployeePayload) => {
	const response = await request.post('/api-defi-network/employee/create', payload);
	return response?.data;
};

export const getEmployeeDetails = async (id: number) => {
	const response = await request.get(`/api-defi-network/employee/details/${id}`);
	return response?.data;
};

export const changeEmployeeStatus = async (id: number) => {
	const response = await request.post(`/api-defi-network/employee/update/${id}/change-status`);
	return response?.data;
};

export const resetEmployeeTwoFa = async (id: number) => {
	const response = await request.post(`/api-defi-network/employee/update/${id}/reset-two-fa`);
	return response?.data;
};

export const changeEmployeePassword = async (id: number, password: string) => {
	const response = await request.post(`/api-defi-network/employee/update/${id}/password`, { password });
	return response?.data;
};

export const updateEmployeePermissions = async (id: number, permissions: { code: string; writeable: boolean; }[]) => {
	const response = await request.post(`/api-defi-network/employee/update/${id}/permissions`, { permissions });
	return response?.data;
};

export const updateEmployeeStoreAssignment = async (id: number, storeId: number, action: 'ASSIGN' | 'UNASSIGN') => {
	const response = await request.post(`/api-defi-network/employee/update/${id}/store`, { action, storeId });
	return response?.data;
};

export default slice.reducer;
