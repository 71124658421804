import * as React from 'react';
import { SvgIcon } from '@mui/material';

const StoreIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.69802 5.0242C3.01551 4.13478 3.85791 3.54102 4.80231 3.54102H20.3359C21.2803 3.54102 22.1227 4.13478 22.4402 5.0242L22.9934 6.57412C23.6855 8.51293 22.8637 10.5232 21.2903 11.5234V19.5462C21.2903 21.0926 20.0367 22.3462 18.4903 22.3462H6.65889C5.11249 22.3462 3.85889 21.0926 3.85889 19.5462V11.5304C2.2783 10.5326 1.45105 8.51746 2.14475 6.57412L2.69802 5.0242ZM5.65889 12.1485V19.5462C5.65889 20.0985 6.1066 20.5462 6.65889 20.5462H8.80605V15.9102C8.80605 14.8608 9.65671 14.0102 10.7061 14.0102H14.4462C15.4956 14.0102 16.3462 14.8608 16.3462 15.9102V20.5462H18.4903C19.0426 20.5462 19.4903 20.0985 19.4903 19.5462V12.1474C19.3459 12.1622 19.1988 12.1699 19.0494 12.1699C17.7382 12.1699 16.5488 11.5624 15.7752 10.5936C15.0494 11.5444 13.904 12.1699 12.5904 12.1699C11.2642 12.1699 10.1095 11.5323 9.38477 10.566C8.61181 11.5509 7.41243 12.1699 6.08884 12.1699C5.94322 12.1699 5.79981 12.1626 5.65889 12.1485ZM14.5462 20.5462V15.9102C14.5462 15.8549 14.5015 15.8102 14.4462 15.8102H10.7061C10.6508 15.8102 10.6061 15.8549 10.6061 15.9102V20.5462H14.5462ZM14.324 5.34102L14.7787 7.88889C14.945 9.19641 13.9255 10.3699 12.5904 10.3699C11.2173 10.3699 10.178 9.12861 10.4193 7.77687L10.854 5.34102H14.324ZM16.5577 7.6115L16.6987 8.40157C16.9019 9.54049 17.8924 10.3699 19.0494 10.3699C20.7005 10.3699 21.8533 8.73426 21.2982 7.17926L20.7449 5.62934C20.6832 5.45644 20.5195 5.34102 20.3359 5.34102H16.1553L16.5336 7.4606C16.5426 7.51099 16.5506 7.5613 16.5577 7.6115ZM4.80231 5.34102C4.61872 5.34102 4.45497 5.45644 4.39325 5.62934L3.83998 7.17926C3.2849 8.73426 4.43773 10.3699 6.08884 10.3699C7.24576 10.3699 8.23625 9.54049 8.43953 8.40157L8.98578 5.34102H4.80231Z" fill="inherit" />
        </svg>
    </SvgIcon>
);

export default StoreIcon;
