import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';
import { CSSProperties } from 'react';

export const CustomInputStyle: CSSProperties = {
    boxSizing: `border-box`,
    position: 'static'
}

export default function componentStyleOverrides(theme: Theme) {

    return createTheme(theme, {
        typography: {
            fontFamily: 'Inter, Arial, sans-serif',
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: '#000000', // Set the background color to black
                        color: '#FFFFFF', // Set the text color to white
                    },
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.primary.light}`,
                    '&::-webkit-scrollbar': {
                        width: '12px',
                    },
                    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        backgroundColor: theme.palette.primary.light,
                        width: 4
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: theme.palette.primary.light,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: theme.palette.primary.main,
                    },
                    div: {
                        /*  --- swal popup style -- */
                        '&.swal2-container': { zIndex: 1400 },
                        '&.swal2-popup': {
                            background: theme.palette.secondaryBackground.main,
                        },
                    },
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#000000',
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        textTransform: 'none', // prevent text transform on buttons
                        borderRadius: '10px',
                        padding: '0.72rem 1rem',
                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                        height: '3.125rem',
                        [theme.breakpoints.down('sm')]: {
                            height: '2.125rem',
                            fontSize: '0.725rem'
                        }
                    },
                    containedPrimary: {
                        color: '#31F2DA',
                        backgroundColor: 'rgba(49, 242, 218, 0.1)',
                        fontWeight: 700,
                        fontSize: '1rem',
                        '&:hover': {
                            backgroundColor: 'rgba(49, 242, 218, 0.1)',
                        },
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '0.725rem'
                        }
                    },
                },
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.secondaryBackground.main,
                        borderRadius: 16,
                    },
                }
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        textTransform: 'none', // prevent text transform on buttons
                        padding: '0.72rem 1rem',
                        '&.Mui-disabled': {
                            opacity: 0.5,
                        },
                        height: '3.125rem',
                        '&:hover': {
                            backgroundColor: 'rgba(49, 242, 218, 0.1)',
                        },
                        '&.Mui-selected': {
                            color: theme.palette.primary.main,
                            backgroundColor: 'rgba(49, 242, 218, 0.1)',
                            '&:hover': {
                                backgroundColor: theme.palette.primary.light
                            },
                        }
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& input::placeholder': {
                            fontSize: '0.688rem',
                            fontWeight: 400
                        },
                    },
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        cursor: 'pointer',
                        textDecoration: 'none',
                        fontSize: '0.813rem',
                        fontWeight: 400,
                        "&[disabled]": {
                            color: theme.palette.text.secondary,
                            cursor: "default",
                            "&:hover": {
                                textDecoration: "none",
                            }
                        },
                    },
                },
            },
            FormControlLabel: {
                styleOverrides: {
                    label: {
                        fontSize: 14
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',

                    },
                }
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        background: '#1D2122',
                        borderRadius: '20px',
                    },
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        '+ .MuiDialogContent-root': {
                            paddingTop: '10px !important',
                        }
                    },
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        // remove arrows up/down on Number input
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                            display: 'none',
                        },
                        '& input[type=number]': {
                            MozAppearance: 'textfield',
                        },
                    },
                    //Remove default backgorund from filled in field
                    input: {
                        '&:-webkit-autofill': {
                            transitionDelay: '9999s',
                            'WebkitBoxShadow': '0 0 0 1000px transparent inset !important',

                        },

                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '13px'
                    }
                }

            },
            MuiTab: {
                defaultProps: {
                    disableRipple: true
                },
                styleOverrides:
                {
                    root: {
                        backgroundColor: 'transparent',
                        textTransform: 'none',
                        borderRadius: '20px',
                        padding: '10px 20px',
                        lineHeight: '18px',
                        fontSize: '14px',
                        fontWeight: 500,
                        '&.MuiButtonBase-root.Mui-selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.06)',
                            minHeight: '29px',
                            minWidth: 'unset'
                        },
                        '&.MuiButtonBase-root': {
                            minHeight: '29px',
                            minWidth: 'unset'
                        },
                    },

                }
            },
        },
    } as ThemeOptions);
}
