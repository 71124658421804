import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { isEmpty } from 'lodash';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import { Chat, ChatStatusEnum, Message, SendMessagePayload, SupportStore } from './supportTypes';



const initialState: SupportStore = {
	messages: [],
	loading: false,
	chat: null
};

const slice = createSlice({
	name: 'support',
	initialState,
	reducers: {
		setMessages(state, action: PayloadAction<Message[]>) {
			state.messages = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setChat(state, action: PayloadAction<Chat | null>) {
			state.chat = action.payload;
		},
		pushMessage: (state, action: PayloadAction<Message>) => {
			const exists = state.messages?.some((existingMessage) => existingMessage.id === action.payload.id);
			if (!exists) {
				state.messages = state.messages ? [...state.messages, action.payload] : [action.payload];
			}
		},
		setStatus: (state, action: PayloadAction<ChatStatusEnum>) => {
			if (state.chat) {
				state.chat.status = action.payload;
			}
		},
		deleteMessage: (state, action: PayloadAction<string>) => {
			state.messages = state.messages.filter(message => message.id !== action.payload);
		},
		deleteChat: (state, action: PayloadAction<string>) => {
			if (state.chat?.id === action.payload) {
				state.chat = null;
				state.messages = [];
			}
		},
		editMessage: (state, action: PayloadAction<Message>) => {
			const index = state.messages.findIndex(message => message.id === action.payload.id);
			if (index !== -1) {
				state.messages[index] = action.payload;
			}
		},
	}
});

export const { setMessages, setLoading, setChat, pushMessage, setStatus, deleteMessage, deleteChat, editMessage } = slice.actions;


export const getChat = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api-defi-network/support', {
				params:
					{ ignorePagination: true },
			});
			const { data } = response;
			dispatch(setMessages(data.messages));
			dispatch(setChat(data.chat));
		} catch (error) {
			console.error('getChat error', error);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

const markAsRead = async (payload: { chatId: number }) => {
	try {
		await request.post('/api-defi-network/support/message/mark-as-read', payload);
	} catch (error) {
		console.error('markAsRead error', error);
	}
};

export const sendChatMessage = (payload: SendMessagePayload) => {
	return async (dispatch: AppDispatch) => {
		const formData = new FormData();
		Object.entries(payload).forEach(([key, value]) => {
			if (value !== null && value !== undefined) {
				formData.append(key, value as string | Blob);
			}
		});
		try {
			const response = await request.post('/api-defi-network/support/message', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			const { message, chat } = response.data;
			dispatch(setChat(chat));
			dispatch(pushMessage(message));
		} catch (error) {
			console.error(error);
		}
	};
};

export const downloadAttachmentForPreview = async (key: string) => {
	const response = await request.get(`/api-defi-network/support/message/attachment/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	return url;
};

export const getMessagePdfAttachment = async (key: string, mimeType: string): Promise<Blob> => {
	const file = await request.get(`/api-defi-network/support/message/attachment/${key}`, { responseType: 'arraybuffer' }).then((response) => {
		const blob = new Blob([response.data], { type: mimeType });
		return blob;
	});

	return file;
};

export const downloadAttachment = async (key: string, name: string) => {
	const response = await request.get(`/api/support/message/attachment/${key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};
	link.click();
};


let socketService: SocketService | null;
let messegeSubscriber: Subscription;
let deletedMsgSubscriber: Subscription;
let deletedChatSubscriber: Subscription;
let editMsgSubscriber: Subscription;
let statusSubscriber: Subscription;

export const connectChatSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('chat');
	}
};

export const disconnectChatSocket = (): void => {
	socketService = null;
};

export const subscribeChatSocket = () => {
	return async (dispatch: AppDispatch) => {
		if (!socketService) return;
		try {
			messegeSubscriber = socketService
				.listen('chat.message', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(pushMessage(data.message));
					markAsRead({ chatId: Number(data.message.chatId) });
				});
			statusSubscriber = socketService
				.listen('chat.status.solve', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(setStatus(ChatStatusEnum.SOLVED));
				});
			editMsgSubscriber = socketService
				.listen('chat.message.edit', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(editMessage(data));
				});
			deletedMsgSubscriber = socketService
				.listen('chat.message.deleted', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(deleteMessage(data.messageId));
				});
			deletedChatSubscriber = socketService
				.listen('chat.deleted', {})
				.subscribe((data) => {
					if (isEmpty(data)) {
						return;
					}
					dispatch(deleteChat(data.chatId));
				});
		} catch (error) {
			console.log(error);
		}
	};

};

export const unsubscribeChatSocket = (): void => {
	messegeSubscriber.unsubscribe();
	deletedMsgSubscriber.unsubscribe();
	deletedChatSubscriber.unsubscribe();
	editMsgSubscriber.unsubscribe();
	statusSubscriber.unsubscribe();
}


export default slice.reducer;
