import { useEffect, useRef } from 'react';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Box, Container, CssBaseline } from '@mui/material';
import ToolBarLayout from './ToolBarLayout';
import { appCollapsedDrawerWidth, contentMargin, contentMarginMobile, drawerWidth, drawerWidthMobile, mainGapTop, mainPadding } from '@theme/layoutConstant';

interface MainStyleProps {
    theme: Theme;
    open: boolean
}


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px!important',
    width: '100%',
    minHeight: `calc(100vh - ${mainGapTop}px - ${contentMargin}px)`,
    marginRight: `${contentMargin}px`,
    [theme.breakpoints.down('sm')]: {
        marginRight: `${contentMarginMobile}px`,
    },
    marginTop: `${contentMargin}px`,
    marginBottom: `${contentMargin}px`,
    //  minHeight: `calc(100vh - ${appBarHeight+20}px)`,
    //  marginTop: `${appBarHeight + mainGapTop}px`,
    flexGrow: 1,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        width: `calc(100% - ${appCollapsedDrawerWidth}px)`,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),

        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('sm')]: {
            width:`calc(100% - ${drawerWidthMobile}px)`,
        },
    }),
}));


const LegalLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    
    const windowScrollRef = useRef<any>();
    const drawerScrollRef = useRef<any>();

    const drawerPaper = document.querySelector('#side-bar .MuiDrawer-paper');
    drawerScrollRef.current = drawerPaper;

    useEffect(() => {
        let prevScrollY = window.scrollY;
        const handleWindowScroll = () => {
            if (drawerScrollRef.current) {
                const scrollDiff = window.scrollY - prevScrollY;
                drawerScrollRef.current.scrollTop += scrollDiff;
                prevScrollY = window.scrollY;
            }
        };
        window.addEventListener('scroll', handleWindowScroll);
        windowScrollRef.current = window;
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []);

    return (
        <Box sx={{
            display: 'flex', paddingY: `${mainPadding}`, paddingRight: `${mainPadding}`
        }}>
            <CssBaseline />
            <Main theme={theme} open={false}>
                <Container maxWidth="xl">
                    <ToolBarLayout showBearcrumbs={false} showLogo />
                    {children}
                </Container>
            </Main>
        </Box >
    );
};

export default LegalLayout;
