import { Button } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ThemeCustomization from '@theme/index';
import './Alerts.css';
import { t } from '@utils/translate';

const ReactSwal = withReactContent(Swal);

interface AlertProps {
    title?: string;
    text?: string;
    confirmButtonText?: string;
    error?: any;
}

export const showSuccess = (props?: AlertProps) => {
    return ReactSwal.fire({
        icon: "success",
        title: props?.title ?? t('alerts.success'),
        text: props?.text ?? '',
        customClass: {
            popup: 'custom-alert',
            title: 'custom-title',
        },
        showConfirmButton: false,
        html: (<>
            {props?.text && <div className='custom-text'>{props.text}</div>}
            <CustomConfirmButton
                onConfirm={() => {
                    ReactSwal.clickConfirm(); // Manually trigger the SweetAlert confirm
                }}
            > {props?.confirmButtonText ?? 'Ok!'}</CustomConfirmButton>
        </>
        ),
    });
};


export const showWarning = (props?: AlertProps) => {
    return ReactSwal.fire({
        icon: "info",
        title: props?.title ?? t('alerts.warning'),
        text: props?.text ?? '',
        customClass: {
            popup: 'custom-alert',
            title: 'custom-title',
        },
        showConfirmButton: false,
        html: (<>
            {props?.text && <div className='custom-text'>{props.text}</div>}
            <CustomConfirmButton
                onConfirm={() => {
                    ReactSwal.clickConfirm(); // Manually trigger the SweetAlert confirm
                }}
            > {props?.confirmButtonText ?? 'Ok!'}</CustomConfirmButton>
        </>
        ),
    });
};

export const showError = (props?: AlertProps) => {
    if (props?.error === 'CANCELLED') return;

    return ReactSwal.fire({
        icon: "error",
        title: props?.title ?? 'Oops...',
        text: props?.text ?? t('alerts.somethingWentWrong'),
        customClass: {
            popup: 'custom-alert',
            title: 'custom-title',
        },
        showConfirmButton: false,
        html: (
            <>
                {props?.text && <div className='custom-text'>{props.text}</div>}
                < CustomConfirmButton
                    onConfirm={() => {
                        ReactSwal.clickConfirm(); // Manually trigger the SweetAlert confirm
                    }}
                > {props?.confirmButtonText ?? 'Ok!'}</CustomConfirmButton>
            </>
        ),
    });
};

type ButtonProps = React.ComponentProps<typeof Button>;
const CustomConfirmButton = ({ onConfirm, children }: ButtonProps & { onConfirm: () => void }) => {
    return (
        <ThemeCustomization>
            <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={onConfirm}>
                {children}
            </Button>
        </ThemeCustomization>
    );
};