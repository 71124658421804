import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { matchIsNumeric } from "@utils/numeric";
import { SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import EmailIcon from '@mui/icons-material/Email';
import SecurityIcon from '@mui/icons-material/Security';
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTranslation } from "react-i18next";

interface Props {
    onConfirm: (otpCode: string) => void;
    variant: 'email' | 'twofa' | 'smsCode';
    onResend?: () => void;
    invalid: boolean;
    onBack?: () => void;
}

const OtpCode = ({ onConfirm, variant, onResend, invalid, onBack }: Props) => {
    const { t } = useTranslation();
    const [otpCode, setOtpCode] = useState('');
    const isTwoFa = variant === 'twofa';
    const [counter, setCounter] = useState<number>(60);
    const otpRef = useRef(null);


    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [counter]);

    const validateChar = (value: any, index: any) => {
        return matchIsNumeric(value)
    }

    const handleOnComplete = (val: string) => {
        onConfirm(val);
        setTimeout(() => {
            setOtpCode('');
            (document.querySelectorAll('.MuiOtpInput-TextField-1 input')[0] as HTMLElement)?.focus();
        }, 500);
    }

    const handleResend = () => {
        setCounter(60);
        onResend && onResend();
    };

    const title = useMemo(() => {
        switch (variant) {
            case 'email': return t('titles.verifyEmail');
            case "twofa": return t('titles.verifyTwoFa');
            case "smsCode": return t('titles.verifyPhoneNumberDialog');
        }
    }, [t, variant])

    return (
        <Grid container gap={1} maxWidth={{ xs: 400, lg: 475 }}>
            <Grid item xs={12} container justifyContent='center'>
                {isTwoFa ? <SecurityIcon color='primary' sx={{ width: 60, height: 60 }} /> : <EmailIcon color='primary' sx={{ width: 60, height: 60 }} />}
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" textAlign="center">{title}</Typography>
            </Grid>
            <Grid item xs={12} mb={2} mt={2}>
                <MuiOtpInput
                    length={6}
                    onComplete={(val: string) => handleOnComplete(val)}
                    autoFocus
                    value={otpCode}
                    onChange={(val: SetStateAction<string>) => setOtpCode(val)}
                    validateChar={validateChar}
                    ref={otpRef}
                />
                {invalid && <FormHelperText error>{t('form.validator.otpInvalid')}</FormHelperText>}
            </Grid>
            {!isTwoFa && onResend && <Grid item xs={12}>
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleResend}
                    disabled={counter > 0}
                >
                    {counter > 0 ? counter : variant === 'email' ? t('buttons.requestNewEmail') : t('buttons.requestNewSms') } 
                </Button>
            </Grid>}
            {!onResend && onBack && <Grid item xs={12}>
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={onBack}
                >
                    {t('buttons.back')}
                </Button>
            </Grid>}
        </Grid>
    )
}

export default OtpCode;