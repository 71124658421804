import { IconButton as MuiIconButton, styled } from '@mui/material';

/* 
Give  background  and size to icon
*/

const CustomIconButton = styled(MuiIconButton)(({ color, theme }) => ({
	background: `${color === 'primary' ?
		`${theme.palette.primary.main}1A` :
		color === 'secondary' ?
			`${theme.palette.secondary.main}1A` :
			color === 'info' ?
				`${theme.palette.info.main}1A` :
				theme.palette.secondaryBackground.main}`,
	borderRadius: 10,
	padding: '8px'
}));


export default CustomIconButton;