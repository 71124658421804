
export interface AllowedActionsStore {
	list: AllowedAction[],
}



export interface AllowedAction {
	code: MerchantPermissions,
	writeable: boolean
}


export enum MerchantPermissions {
	FINANCES = "FINANCES",
	STORES = "STORES",
	PRODUCTS = "PRODUCTS",
	EMPLOYEES = "EMPLOYEES",
}