
import { Grid } from '@mui/material';
import { appBarHeight, mainGapTop, mainPadding } from '@theme/layoutConstant';
import SideBarMenuList from './SideBarMenuList';

export interface MenuItemType {
    id: number, title: string, icon: JSX.Element, to: string
}
interface Props {
    drawerIsOpen: boolean,
    list: MenuItemType[],
    bottomList?: MenuItemType[],
}

const SidebarMenu = ({ list, drawerIsOpen, bottomList }: Props) => {

    return (
        <Grid container sx={{
            height: `calc(100vh - ${appBarHeight + mainPadding + mainGapTop}px)`,
            justifyContent: 'center',
        }}>
            <Grid item style={{ flex: 1 }}>
                <SideBarMenuList list={list} drawerIsOpen={drawerIsOpen} />

            </Grid>
            <Grid item style={{ flex: 1, alignItems: 'flex-end', display: 'flex', marginBottom: 4 }}>
                {bottomList?.length && <SideBarMenuList list={bottomList} drawerIsOpen={drawerIsOpen} />}
            </Grid>
        </Grid>
    );
};


export default SidebarMenu;
