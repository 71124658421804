import { next, reject2FA, set2FAInvalid, set2FALoading } from "@features/twoFa/twoFaSlice";
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { RootState } from "@store/reducer";
import { dispatch, useSelector } from "@store/store";
import OtpCode from "./OtpCode";
import CloseIcon from '@mui/icons-material/Close';


const TwoFAWeb = (): React.ReactElement => {
    const { open, invalid, loading } = useSelector(
        (state: RootState) => state.twoFa
    );

    const confirm = async (otpCode: string) => {
        try {
            dispatch(set2FAInvalid(false));
            dispatch(set2FALoading(true));
            dispatch(next(otpCode));
        }
        catch (e: any) {
            console.error(e);
        }
    };

    const handleCancel = () => {
        dispatch(reject2FA('CANCELLED'));
    };

    return (
        <Dialog
            open={open}
            maxWidth='xs'
            onClose={handleCancel}
            PaperProps={{
                sx: {
                    borderRadius: 3,
                },
            }} >
            <DialogTitle display='flex' justifyContent='flex-end'>
                <IconButton
                    onClick={handleCancel} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={handleCancel}
                >
                    <CircularProgress />
                </Backdrop>
                <OtpCode
                    invalid={invalid}
                    variant="twofa"
                    onConfirm={(otpCode: string) => confirm(otpCode)}
                />
            </DialogContent>
        </Dialog >
    );
};

export default TwoFAWeb;

