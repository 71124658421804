import { Account } from "@features/accounts/accountsTypes";
import { useState } from "react";
import SendMoneyChooseRecipient from "./SendMoneyChooseRecipient";
import { Contact } from "@features/beneficiary/beneficiaryTypes";
import SendMoneyToOtherUserDetails from "./SendMoneyToOtherUserDetails";
import SendMoneyToOtherUserConfirmation from "./SendMoneyToOtherUserConfirmation";


interface Props {
    account: Account;
    onBack: () => void;
    onClose: () => void;
}

enum Step {
    CHOOSE_RECIPIENT = "CHOOSE_RECIPIENT",
    DETAILS = "DETAILS",
    TRANSFER_CONFIRMATION = "TRANSFER_CONFIRMATION",
}

const SendMoneyToOtherUser = ({ account, onBack, onClose }: Props) => {
    const [step, setStep] = useState<Step>(Step.CHOOSE_RECIPIENT);
    const [contact, setContact] = useState<Contact | null>(null);
    const [payload, setPayload] = useState<any>(null);


    const handleOnRecipientChoose = ($contact: Contact | null) => {
        if ($contact) {
            setContact($contact);
        }
        setStep(Step.DETAILS);
    }

    const handleNextFromDetails = ($payload: any) => {
        setPayload($payload);
        setStep(Step.TRANSFER_CONFIRMATION);
    }

    return (
        <>
            {step === Step.CHOOSE_RECIPIENT && <SendMoneyChooseRecipient onBack={onBack} onChoose={(contact: Contact | null) => handleOnRecipientChoose(contact)} onClose={onClose} recipientType={'LOCAL'} />}
            {step === Step.DETAILS && <SendMoneyToOtherUserDetails
                onBack={() => setStep(Step.CHOOSE_RECIPIENT)}
                detailsPayload={payload}
                onNext={(payload: any) => handleNextFromDetails(payload)}
                onClose={onClose}
                account={account}
                contact={contact}
            />}
            {step === Step.TRANSFER_CONFIRMATION && <SendMoneyToOtherUserConfirmation
                account={account}
                onBack={() => setStep(Step.DETAILS)}
                onClose={onClose}
                payload={payload}
            />}
        </>
    );
}



export default SendMoneyToOtherUser;