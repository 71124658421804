
export interface IbanData {
	bank: string;
	bic: string;
	country: string;
	iban: string;
	valid: boolean;
	address: string;
}

export interface BeneficiaryStore {
	localList: Contact[];
	loading: boolean;
	eurList: Contact[];
	gbpList: Contact[];
}

export enum ContactPaymentTypes {
	LOCAL = 'LOCAL',
	CRYPTO = 'CRYPTO',
	SEPA = 'SEPA_STEP2',
	SEPA_INSTANT = 'SEPA_INSTANT',
	SWIFT = 'SWIFT',
	UK_CHAPS = 'UK_CHAPS',
	UK_FPS = 'UK_FPS',
	US_WIRE = 'US_WIRE',
	INTERNATIONAL_WIRE = 'INTERNATIONAL_WIRE',
	ACH = 'ACH',
	SAME_DAY_ACH = 'SAME_DAY_ACH',
	SCAN = 'SCAN',
}

export interface Contact {
	beneficiaryAccountId: number;
	supportedPayments: string[];
	walletId: string;
	userTag: string;
	clientId: string;
	name: string;
	account: string;
	accountNumber: string;
	sortCode: string;
	identification: string;
	bic: string;
	bankCountry: string;
	business: boolean;
	bankAddress: string;
	bank: string;
}

export enum BeneficiaryType {
	BUSINESS = 'BUSINESS',
	INDIVIDUAL = 'INDIVIDUAL',
}

export enum ExternalPaymentType {
	STANDARD = 'STANDARD',
	INSTANT = 'INSTANT',
}

export interface GbpPaymentValidationPayload {
	name: string;
	accountNumber: string;
	sortCode: string;
	business: boolean;
}