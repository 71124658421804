import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { KYC } from './types';

const initialState: KYC = {
	error: null,
	token: null,
	loading: false,
};

const statusSlice = createSlice({
	name: 'kyc',
	initialState,
	reducers: {
		setToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		},
		setError(state, action: PayloadAction<string | null>) {
			state.error = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	}
});

export const { setToken, setError, setLoading  } = statusSlice.actions;


export const getKycToken = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			dispatch(setError(null));
			const response = await request.get(`/api-defi-network/kyc/accesstoken`);
			const { data } = response;

			dispatch(setToken(data.token));
			setLoading(false);
		}
		catch (e) {
			dispatch(setError('Access token generation failed'));
		}
		dispatch(setLoading(false));
	};
};

export const getRefreshedToken = async () => {
	const response = await request.get('/api-defi-network/kyc/accesstoken');
	const { data } = response;
	return data.token;
};

export const requestFinalizeReviewStatus = async () => {
	return async () => {
		await request.get('/api-defi-network/kyc/finalize-review-status');
	};
};

export default statusSlice.reducer;
