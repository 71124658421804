// material-ui
import { createTheme } from '@mui/material/styles';
import { green, orange, red } from '@mui/material/colors';

const Palette = () => {

    return createTheme({
        palette: {
            mode: 'dark', // sets the default mode to dark
            background: {
                default: '#000000', // background is black
            },
            primary: {
                main: '#31F2DA', // primary color
                light: '#31F2DA0D', // primary color
            },
            error: {
                main: red[500], // error color (red)
            },
            warning: {
                main: orange[500], // warning color (orange)
            },
            success: {
                main: green[500], // success color (green)
            },
            text: {
                primary: '#FFFFFF', // text color on dark background
                secondary: '#B0B0B0', // secondary text color
            },
            secondaryBackground: {
                main: '#1D2122',  
                light: '#ffffff0d',
            },
            info: {
                main: '#31ADF2',
            },
        }
    });
};

export default Palette; 
