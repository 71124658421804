
export interface AccountsStore {
	list: Account[],
	loading: boolean,
}

export interface Account {
	accountId: number;
	availableBalance: number;
	availableBalanceString: string;
	bankName: string;
	bicCode: string;
	bankCountry: string;
	bankAddress: string;
	currency: string;
	currencySymbol: string;
	iban: string;
	name: string;
	proc: string;
	status: string;
	subProcesses: any;
	walletId: string;
	providerType: string;
	recipient: string;
	ukAccountNumber: string;
	ukSortCode: string;
	type: string;
	currencyLogo: string;
}

export enum PaymentMethod {
	SEPA = 'SEPA_STEP2',
	SEPA_INSTANT = 'SEPA_INSTANT',
	UK_CHAPS = 'UK_CHAPS',
	UK_FPS = 'UK_FPS',
	LOCAL = 'LOCAL',
}


export interface DepositAsset {
	accountAddresses: {
		accountAddressId: number;
		address: string;
		label: string;
		network: string;
		token: string;
	}[];
	accountId: number;
	currency: string;
	currencyLogo: string;
	currencyName: string;
	displayDecimals: number;
	minDepositAmount: number;
	minDepositAmountString: string;
}