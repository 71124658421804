import { Account, PaymentMethod } from "@features/accounts/accountsTypes";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import TransferTypeCard from "./TransferTypeCard";
import BankTransferIcon from "@assets/icons/transferTypes/BankTransferIcon";
import TransferBetweenAccountsIcon from "@assets/icons/transferTypes/TransferBetweenAccountsIcon";
import { useSelector } from "@store/store";
import { findPaymentTypes, findProcessByType, findSelfPaymentTypes } from "@features/accounts/accountsSlice";
import { useTranslation } from "react-i18next";
import FormTitle from "@components/form/FormTitle";
import InstantChip from "@components/chips/InstantChip";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { SendMoneyType } from "../SendMoneyDialog";
import StandardPaymentChip from "@components/chips/StandardPaymentChip";
import TransferToOtherUserIcon from "@assets/icons/transferTypes/TransferToOtherUserIcon";

export const supportedPaymentMethods = [
    PaymentMethod.SEPA,
    PaymentMethod.SEPA_INSTANT,
    PaymentMethod.UK_CHAPS,
    PaymentMethod.UK_FPS,
];

interface Props {
    onChoose: (type: SendMoneyType) => void;
    onClose: () => void;
    account: Account;
}

const ChooseSendMoneyType = ({ onChoose, account, onClose }: Props) => {
    const { t } = useTranslation();
    const { list } = useSelector((state) => state.accounts);

    const otherAccounts = list.filter(acc => acc.accountId !== account.accountId || acc.currency !== account.currency) || [];
    const ownTransferProcess = useMemo(() => findProcessByType(account, 'OWN_TRANSFER'), [account]);
    const ownTransferAllowed = !isEmpty(ownTransferProcess) && otherAccounts.length > 0 && otherAccounts.some((acc: Account) => account.currency === acc.currency);

    const transferToOtherUserProcess = useMemo(() => findProcessByType(account, 'TRANSFER') || [], [account]);
    const isTransferToOtherUserAllowed = !isEmpty(transferToOtherUserProcess);

    const paymentProcessList = useMemo(() => findPaymentTypes(account)
        .filter(p => supportedPaymentMethods.includes(p.processProperties?.paymentType)) || [], [account]);
    const selfPaymentProcessList = useMemo(() => findSelfPaymentTypes(account)
        .filter(p => supportedPaymentMethods.includes(p.processProperties?.paymentType)) || [], [account]);
    const bankTransferAllowed = paymentProcessList?.length > 0;
    const selfBankTransferAllowed = selfPaymentProcessList?.length > 0;
    const isBankTransferMaintenanceEnabled = paymentProcessList.filter(p => p.maintenance).length === paymentProcessList.length;
    const isSelfBankTransferMaintenanceEnabled = selfPaymentProcessList.filter(p => p.maintenance).length === selfPaymentProcessList.length;

    const renderBankTransferChips = (processList: any[]) => {
        const isSepaEnabled = Boolean(processList.find(p => p.processProperties.paymentType === PaymentMethod.SEPA && p.maintenance === false));
        const isSepaInstantEnabled = Boolean(processList.find(p => p.processProperties.paymentType === PaymentMethod.SEPA_INSTANT && p.maintenance === false));
        const isChapsEnabled = Boolean(processList.find(p => p.processProperties.paymentType === PaymentMethod.UK_CHAPS && p.maintenance === false));
        const isFpsEnabled = Boolean(processList.find(p => p.processProperties.paymentType === PaymentMethod.UK_FPS && p.maintenance === false));

        return (<Grid container gap={0.5}>
            {(isSepaEnabled || isChapsEnabled) && <StandardPaymentChip />}
            {(isSepaInstantEnabled || isFpsEnabled) && <InstantChip />}
        </Grid>)
    }


    return (<>
        <DialogTitle>
            <FormTitle
                title={t('titles.sendMoney')}
                onClose={onClose}
            />
        </DialogTitle>
        <DialogContent>
            <Grid container gap={1}>
                {bankTransferAllowed && <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(SendMoneyType.BANK_TRANSFER)}
                        title={t('titles.bankTransfer')}
                        description={t('descriptions.bankTransfer')}
                        icon={<BankTransferIcon />}
                        chips={renderBankTransferChips(paymentProcessList)}
                        maintenanceEnabled={isBankTransferMaintenanceEnabled}
                    />
                </Grid>}
                {selfBankTransferAllowed && <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(SendMoneyType.SELF_BANK_TRANSFER)}
                        title={t('titles.bankTransfer')}
                        description={t('descriptions.bankTransfer')}
                        icon={<BankTransferIcon />}
                        chips={renderBankTransferChips(selfPaymentProcessList)}
                        maintenanceEnabled={isSelfBankTransferMaintenanceEnabled}
                    />
                </Grid>}
                {isTransferToOtherUserAllowed && <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(SendMoneyType.TRANSFER_TO_OTHER_USER)}
                        fillIconFully
                        title={t('titles.transferToOtherUser')}
                        description={t('descriptions.transferToOtherUser')}
                        icon={<TransferToOtherUserIcon />}
                        chips={<InstantChip />}
                        maintenanceEnabled={transferToOtherUserProcess?.maintenance}
                    />
                </Grid>}
                {ownTransferAllowed && <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(SendMoneyType.TRANSFER_BETWEEN_ACCOUNTS)}
                        fillIconFully
                        title={t('titles.ownTransfers')}
                        description={t('descriptions.ownTransfers')}
                        icon={<TransferBetweenAccountsIcon />}
                        chips={<InstantChip />}
                        maintenanceEnabled={ownTransferProcess?.maintenance}
                    />
                </Grid>}

            </Grid>
        </DialogContent>
    </>
    );
}

export default ChooseSendMoneyType;