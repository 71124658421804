import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { EmployeePermissionsStore, Permission, } from './employeePermissionsTypes';



const initialState: EmployeePermissionsStore = {
	list: [],
};

const slice = createSlice({
	name: 'employeePermissions',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<Permission[]>) {
			state.list = action.payload;
		},

	}
});

export const { setList, } = slice.actions;


export const getEmployeePermissions = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await request.get('/api-defi-network/employee/permissions/list');
			const data = response?.data || null;
			dispatch(setList(data));
		}
		catch (e) {
			dispatch(setList([]));
		}
	};
};


export default slice.reducer;
