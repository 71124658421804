import * as React from "react";
import { SvgIcon } from '@mui/material';

const SidebarOpen = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path opacity="0.5" d="M1.66797 10.0013C1.66797 6.07293 1.66797 4.10875 2.88836 2.88836C4.10875 1.66797 6.07293 1.66797 10.0013 1.66797C13.9297 1.66797 15.8939 1.66797 17.1142 2.88836C18.3346 4.10875 18.3346 6.07293 18.3346 10.0013C18.3346 13.9297 18.3346 15.8939 17.1142 17.1142C15.8939 18.3346 13.9297 18.3346 10.0013 18.3346C6.07293 18.3346 4.10875 18.3346 2.88836 17.1142C1.66797 15.8939 1.66797 13.9297 1.66797 10.0013Z" stroke="white" strokeWidth="1.2" />
            <path d="M6 10L12 10M12 10L9.42857 7M12 10L9.42857 13" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 13L15 10L15 7" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default SidebarOpen;