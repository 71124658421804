import { Avatar } from "@mui/material";

interface Props {
    fullName: string;
    randomColor?: boolean;
}

const FullNameAvatar = ({ fullName, randomColor }: Props) => {

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const fullNameAvatar = () => {
        const splitted = fullName.split(' ');
        const $child = splitted.length > 1 ? `${splitted[0][0]}${splitted[1][0]}` : splitted[0].slice(0, 2);

        return {
            sx: {
                bgcolor: randomColor ? stringToColor(fullName) : 'default',
            },
            children: $child.toUpperCase(),
        };
    }

    return (
        <Avatar {...fullNameAvatar()} />
    );
}

export default FullNameAvatar;