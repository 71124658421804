import { Account } from "@features/accounts/accountsTypes";
import { Dialog } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TransferBetweenOwnAccounts from "./transfers/TransferBetweenOwnAccounts";
import ChooseSendMoneyType from "./transfers/ChooseSendMoneyType";
import { dispatch } from "@store/store";
import { getBeneficiaries } from "@features/beneficiary/beneficiarySlice";
import SendMoneyBankTransfer from "./transfers/SendMoneyBankTransfer";
import SendMoneyToOtherUser from "./transfers/SendMoneyToOtherUser";
import SendSelfBankTransfer from "./transfers/SendSelfBankTransfer";

export enum SendMoneyType {
    CHOOSE_TYPE = 'CHOOSE_TYPE',
    BANK_TRANSFER = 'BANK_TRANSFER',
    SELF_BANK_TRANSFER = 'SELF_BANK_TRANSFER',
    TRANSFER_TO_OTHER_USER = 'TRANSFER_TO_OTHER_USER',
    TRANSFER_BETWEEN_ACCOUNTS = 'TRANSFER_BETWEEN_ACCOUNTS',
}

interface Props {
    open: boolean;
    onClose: () => void;
    account: Account;
}

const SendMoneyDialog = ({ open, onClose, account }: Props) => {
    const [step, setStep] = useState<SendMoneyType>(SendMoneyType.CHOOSE_TYPE);

    
    const getContacts = useCallback(async () => {
        await dispatch(getBeneficiaries());
    }, []);

    useEffect(() => {
        getContacts();
    }, [getContacts]);

    const handleClose = () => {
        onClose();
        setStep(SendMoneyType.CHOOSE_TYPE);
    }



    return (
        <Dialog
            open={open}
            maxWidth='xs'
            fullWidth
        >
            {step === SendMoneyType.CHOOSE_TYPE && <ChooseSendMoneyType onChoose={(type: SendMoneyType) => setStep(type)} account={account} onClose={handleClose} />}
            {step === SendMoneyType.TRANSFER_BETWEEN_ACCOUNTS && <TransferBetweenOwnAccounts onBack={() => setStep(SendMoneyType.CHOOSE_TYPE)} account={account} direction="FROM" onClose={handleClose} />}
            {step === SendMoneyType.BANK_TRANSFER && <SendMoneyBankTransfer onBack={() => setStep(SendMoneyType.CHOOSE_TYPE)} account={account} onClose={handleClose} />}
            {step === SendMoneyType.TRANSFER_TO_OTHER_USER && <SendMoneyToOtherUser onBack={() => setStep(SendMoneyType.CHOOSE_TYPE)} account={account} onClose={handleClose} />}
            {step === SendMoneyType.SELF_BANK_TRANSFER && <SendSelfBankTransfer onBack={() => setStep(SendMoneyType.CHOOSE_TYPE)} account={account} onClose={handleClose} />}
        </Dialog>
    );
};

export default SendMoneyDialog;