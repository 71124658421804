export enum SortDirectionEnum {
    DESC = 'DESC',
    ASC = 'ASC'
}

export interface PaginateParams {
    filter?: string;
    orderBy?: string;
    sort?: SortDirectionEnum;
    take: number;
    skip: number;
    initial?: boolean;
    search?: string;
    sortLangCode?: string | undefined;
}


export const defaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'createdDate',
    sort: SortDirectionEnum.DESC,
    take: 5,
    skip: 0
};