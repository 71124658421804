import { format } from 'date-fns';

export const PATTERN = 'dd-MM-yyyy'

export const convertDateToString = (
  originalDate: Date | string | number,
  pattern?: string
): string => {
  const $pattern = pattern ?? PATTERN;
  const date = new Date(originalDate);
  return format(date, $pattern);
};


export const convertStringToDate = (dateString: string) => {
  const [day, month, year] = dateString.split('-');
  return new Date(+year, +month - 1, +day);
}


export const formatDistanceDate = (date: Date | string) => {
  let seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);

  let interval = Math.floor(seconds / 3600);
  if (interval === 1) {
    return { interval, data: 'HOUR', date: null };
  } else if (interval > 1 && interval < 24) {
    return { interval, data: 'HOURS', date: null };
  }

  interval = Math.floor(seconds / 60);
  if (interval === 1) {
    return { interval, data: 'MINUTE', date: null };
  } else if (interval > 1 && interval < 60) {
    return { interval, data: 'MINUTES', date: null };
  }

  if (seconds < 60) return { interval, data: 'JUST_NOW', date: null };

  return { interval, data: 'DATE', date: convertDateToString(date, 'dd-MMM-yyyy HH:mm:ss') };
}