import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credentials } from './types';

export const CREDENTIAL_KEY = 'authCredentials';
const savedCredentials = sessionStorage.getItem(CREDENTIAL_KEY);

const initialState: Credentials = {
	username: null,
	clientId: null,
	token: null,
	profileType: null,
};
if (savedCredentials) {
	try {
		const credentials = JSON.parse(savedCredentials);
		initialState.token = credentials.token;
		initialState.username = credentials.username;
		initialState.clientId = credentials.clientId;
		initialState.profileType = credentials.profileType;
	}
	catch (e) {
		console.log(e);
	}
}

const credentialsSlice = createSlice({
	name: 'credentials',
	initialState,
	reducers: {
		setCredentials(state, action: PayloadAction<Credentials>) {
			state.username = action.payload.username;
			state.token = action.payload.token;
			state.clientId = action.payload.clientId;
			state.profileType = action.payload.profileType;
			sessionStorage.setItem(CREDENTIAL_KEY, JSON.stringify({
				token: action.payload.token,
				clientId: action.payload.clientId,
				username: action.payload.username,
				profileType: action.payload.profileType,
			}));
		},
		clearCredentials(state,) {
			state.username = null;
			state.token = null;
			state.clientId = null;
			state.profileType = null;
			sessionStorage.removeItem(CREDENTIAL_KEY);
		},
		setTokenData(state, action: PayloadAction<Credentials>) {
			state.token = action.payload.token;
			sessionStorage.setItem(CREDENTIAL_KEY, JSON.stringify(
				{
					...state,
					token: action.payload.token,
				}
			));
		},
	}
});

export const { setCredentials, clearCredentials, setTokenData } = credentialsSlice.actions;

export const credentialsSelector = (state: { credentialsStore: Credentials }) =>
	state.credentialsStore;

export default credentialsSlice.reducer;
