import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Status, SupportedLanguages } from './types';
import { AppDispatch } from '@store/store';

const initialState: Status = {
	hrsk: '',
	mandatoryReferalId: false,
	sumsubUrl: '',
	languages: [],
	activeLanguage: undefined,
	androidAppLink: '',
	iosAppLink: ''
};

const statusSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {
		setStatus(state, action: PayloadAction<Status>) {
			state.hrsk = action.payload.hrsk;
			state.sumsubUrl = action.payload.sumsubUrl;
			state.mandatoryReferalId = action.payload.mandatoryReferalId;
			state.languages = action.payload.languages;
			state.androidAppLink = action.payload.androidAppLink;
			state.iosAppLink = action.payload.iosAppLink;
			if (!state?.activeLanguage && action.payload?.languages?.length) {
				state.activeLanguage = action.payload.languages.find((p: SupportedLanguages) => p.code === 'en');
			}
		},
		setActiveLang(state, action: PayloadAction<string>) {
			const lang = state?.languages?.find((p: SupportedLanguages) => p.code === action.payload);
			if (lang) {
				state.activeLanguage = lang;
			}
		}
	}
});

export const { setStatus, setActiveLang } = statusSlice.actions;

export const statusSelector = (state: { statusStore: Status }) =>
	state.statusStore;


export const getStatus = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await request.get('/api-defi-network/status');
			const { data } = await response;
			dispatch(setStatus(data));
		} catch (error) {
			console.log(error);
		}
	};
};


export default statusSlice.reducer;
