import { Transfer } from "@features/transactions/transactionsTypes";
import { Button, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TransferLine from "./activities/TransferLine";
import { useNavigate } from "react-router-dom";
import { DashboardPath } from "../../../types/global";

interface Props {
    list: Transfer[];
    loading: boolean;
}

const RecentActivities = ({ list, loading }: Props) => {
    const { t } = useTranslation();
    const isListEmpty = !list || list.length === 0;
    const navigate = useNavigate();
    
    const handleSeeAllClick = () => {
        navigate(DashboardPath.TRANSFERS);
    };

    return (
        <Card
            sx={theme => ({
                background: theme.palette.secondaryBackground.main,
                padding: '20px',
                borderRadius: '16px',
            })}>
            <Grid container>
                <Grid item xs={12} container justifyContent='space-between' alignItems='center' mb={2}>
                    <Typography variant="h5">{t('titles.recentActivities')}</Typography>
                    <Button variant="outlined" color="primary" sx={{ height: '40px' }} disabled={isListEmpty} onClick={handleSeeAllClick}>{t('buttons.seeAll')}</Button>
                </Grid>
                {loading ? <Grid item xs={12} container justifyContent='center' alignItems='center' minHeight={80}>
                    <CircularProgress />
                </Grid> : <>{isListEmpty ? <Grid item xs={12} container justifyContent='center' alignItems='center' minHeight={80}>
                   {t('texts.noRecentActivities')}
                </Grid> : <Grid container gap={1}>
                    {list.map((transfer: Transfer) => <TransferLine transfer={transfer} key={transfer.transactionNumber} />)}
                </Grid>}
                </>}
            </Grid>
        </Card>
    );
}

export default RecentActivities;