import { InputAdornment } from '@mui/material';
import { useField } from 'formik';
import { NumericFormat } from 'react-number-format';
import FormTextField from './FormTextField';

type Props = {
	name: string;
	currencySign: string;
	label: string;
	onChange: (val: string) => void;
	value: string;
	disabled?: boolean;
};

const FormAmountTextField = ({ name, currencySign, label, value, onChange, disabled }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta] = useField(name);

	return <NumericFormat
		name={name}
		onChange={event => {
			let newValue = event.target.value;
			if(newValue.startsWith('.')) {
				newValue = '0.'
			}
			onChange(newValue)
		}}
		value={value}
		label={label}
		allowNegative={false}
		decimalScale={2}
		thousandsGroupStyle='thousand'
		thousandSeparator={true}
		InputProps={{
			startAdornment: <InputAdornment position="start">{currencySign}</InputAdornment>,
		}}
		helperText={meta && meta.touched && meta.error as string}
		error={meta && meta.touched && Boolean(meta.error)}
		customInput={FormTextField}
		inputProps={{ autoComplete: 'off' }}
		displayType={'input'}
		fullWidth
		disabled={disabled}
	/>;
};

export default FormAmountTextField;