import CustomIconButton from "@components/CustomIconButton";
import { Grid, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

interface Props {
    title?: string,
    onClose: () => void
    backIcon?: JSX.Element,
    cloceIcon?: JSX.Element,
    onBack?: () => void,
}
const FormTitle = ({ title, backIcon, onBack, onClose, cloceIcon }: Props) => {

    return (<Grid container alignItems='center' paddingTop={2}>
        {onBack && <Grid item sx={{ position: 'absolute' }}>
            <CustomIconButton onClick={onBack}>{backIcon ?? <BackIcon />}</CustomIconButton>
        </Grid>}
        <Grid item margin='auto'>
            <Typography variant="h6">{title ?? ''}</Typography>
        </Grid>
        <Grid item >
            <CustomIconButton onClick={onClose}>{cloceIcon ?? <CloseIcon />}</CustomIconButton>
        </Grid>
    </Grid>);
};
export default FormTitle;
