import { Alert, Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DepositAsset } from "@features/accounts/accountsTypes";
import ButtonStyleTab from "@components/form/ButtonStyleTab";
import ButtonStyleTabs from "@components/form/ButtonStyleTabs";
import { getQRData } from "@utils/qrData";
import CopyBox from "@components/CopyBox";
import { useTranslation } from "react-i18next";
import { numericFormatter } from "react-number-format";
import { getActualDecimals } from "@utils/currency";

interface Props {
    asset: DepositAsset;
}

const AddMoneyCryptoTopUpAssetDetails = ({ asset }: Props) => {
    const { t } = useTranslation();
    const [address, setAddress] = useState<any>(asset.accountAddresses[0])
    const [qrCode, setQrCode] = useState<string | null>(null);

    useEffect(() => {
        const fetchQR = async () => {
            if (address?.address) {
                try {
                    setQrCode(null);
                    const qr = await getQRData(address.address);
                    setQrCode(qr);
                }
                catch (e) {
                    setQrCode(null);
                }
            }
            else { setQrCode(null); }
        };
        fetchQR();
    }, [address?.address]);

    const formattedMinAmount = useMemo(() => {
        return numericFormatter(asset.minDepositAmountString, {
            displayType: 'text',
            decimalScale: getActualDecimals(asset.minDepositAmountString, 0),
            fixedDecimalScale: false,
            thousandsGroupStyle: 'thousand',
            thousandSeparator: true,
            valueIsNumericString: true,
            suffix: ` ${asset.currency}`
        });
    }, [asset])

    return (
        <Grid container gap={2}>
            <Grid item xs={12}>
                <Alert severity="info">{t('texts.cryptoTopUpMinAmount', { amount: formattedMinAmount })}</Alert>
            </Grid>
            {asset.accountAddresses.length > 1 && <Grid item xs={12}>
                <ButtonStyleTabs
                    value={address}
                    onChange={(event: any, newValue: any) => setAddress(newValue)}
                >
                    {asset.accountAddresses.map((address) =>
                        <ButtonStyleTab value={address} label={address.network} />
                    )}
                </ButtonStyleTabs>
            </Grid>}
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', mx: 'auto', borderRadius: '10px', height: { xs: 'auto', md: 'auto' }, width: { xs: '40%', md: '40%' } }}>
                    {qrCode ? <img style={{ borderRadius: '10px', height: '100%', width: '100%' }} alt='QR code' src={qrCode} /> : <CircularProgress />}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <CopyBox value={address.address} valueFontSize="11px" />
            </Grid>
            <Grid item xs={12}>
                <Alert severity="warning">{t('texts.cryptoTopUpNote', { ccy: `${asset.currencyName} (${asset.currency})` })}</Alert>
            </Grid>
        </Grid>
    );
}




export default AddMoneyCryptoTopUpAssetDetails;