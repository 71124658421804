export const drawerWidth = 220;
export const drawerWidthMobile = 140;
export const sidebarLargeIconSize = 20;
export const appCollapsedDrawerWidth = sidebarLargeIconSize + 40;
export const appBarHeight = 78;
export const appMobileDrawerWidth = sidebarLargeIconSize + 15;
export const mainGapTop = 10;
export const mainPadding = 18; 
export const contentMargin = 10;
export const contentMarginMobile = 0; 


export const heighBreakpoints = {
    laptops: '900px'
};
