import { useEffect, useRef } from 'react';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Box, Container, CssBaseline, useMediaQuery } from '@mui/material';
import ToolBarLayout from './ToolBarLayout';
import { appCollapsedDrawerWidth, contentMargin, contentMarginMobile, drawerWidth, drawerWidthMobile, mainGapTop, mainPadding } from '@theme/layoutConstant';
import { openDrawer } from '@features/menu/menuSlice';
import { useDispatch, useSelector } from '@store/store';
import { RootState } from '@store/reducer';
import SidebarLayout from './Sidebar/SidebarLayout';
import BusinessMenu from './Sidebar/BusinessMenu';
import { ProfileType } from '../types/global';
import IndividualMenu from './Sidebar/IndividualMenu'; 

interface MainStyleProps {
    theme: Theme;
    open: boolean
}


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px!important',
    width: '100%',
    minHeight: `calc(100vh - ${mainGapTop}px - ${contentMargin}px)`,
    marginRight: `${contentMargin}px`,
    [theme.breakpoints.down('sm')]: {
        marginRight: `${contentMarginMobile}px`,
    },
    marginTop: `${contentMargin}px`,
    marginBottom: `${contentMargin}px`,
    //  minHeight: `calc(100vh - ${appBarHeight+20}px)`,
    //  marginTop: `${appBarHeight + mainGapTop}px`,
    flexGrow: 1,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        width: `calc(100% - ${appCollapsedDrawerWidth}px)`,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),

        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('sm')]: {
            width:`calc(100% - ${drawerWidthMobile}px)`,
        },
    }),
}));


const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg')); 
    
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state: RootState) => state.menu);
    const { user } = useSelector((state) => state.user);  

    useEffect(() => {
        matchDownMd && dispatch(openDrawer(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);



    const windowScrollRef = useRef<any>();
    const drawerScrollRef = useRef<any>();

    const drawerPaper = document.querySelector('#side-bar .MuiDrawer-paper');
    drawerScrollRef.current = drawerPaper;

    useEffect(() => {
        let prevScrollY = window.scrollY;
        const handleWindowScroll = () => {
            if (drawerScrollRef.current) {
                const scrollDiff = window.scrollY - prevScrollY;
                drawerScrollRef.current.scrollTop += scrollDiff;
                prevScrollY = window.scrollY;
            }
        };
        window.addEventListener('scroll', handleWindowScroll);
        windowScrollRef.current = window;
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []);

    const isBussiness = [ProfileType.BUSINESS.toString(), ProfileType.EMPLOYEE.toString()].includes(user?.type ?? '');

    return (
        <Box sx={{
            display: 'flex', paddingY: `${mainPadding}`, paddingRight: `${mainPadding}`
        }}>
            <CssBaseline />
            <SidebarLayout >
                {isBussiness && <BusinessMenu />}
                {user?.type === ProfileType.INDIVIDUAL && <IndividualMenu />}
            </SidebarLayout >
            <Main theme={theme} open={drawerOpen}>
                <Container maxWidth="xl">
                    <ToolBarLayout showBearcrumbs={true} />
                    {children}
                </Container>
            </Main>
        </Box >
    );
};

export default DashboardLayout;
