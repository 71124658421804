import { useTranslation } from 'react-i18next';
import './App.css';
import { useEffect } from 'react';
import * as countries from 'i18n-iso-countries';
import Loader from './components/Loader';
import Routes from './routes/Routes';
import { AuthProvider } from './contexts/AuthContext';
import { dispatch } from '@store/store';
import { getStatus } from './features/status/statusSlice';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

function App() {

  const { ready } = useTranslation();

  useEffect(() => {
    dispatch(getStatus());
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  }, []);

  if (!ready) {
    return <Loader />;
  }

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Routes />
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
