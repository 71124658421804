// @ts-nocheck
import * as io from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { CREDENTIAL_KEY } from '@features/auth/credentialsSice';

interface HashTable<T> {
	[key: string]: T;
}

export class SocketService {
	private socket: SocketIOClient.Socket | null = null;
	private url = '/';
	private namespace: string;
	private token: string;
	static connections: HashTable<SocketService> = {};
	private channelList: HashTable<BehaviorSubject<any>> = {};
	// eslint-disable-next-line @typescript-eslint/ban-types
	private handleList: HashTable<Function> = {};

	constructor(namespace: string) {
		this.namespace = namespace;
		const credentials = JSON.parse(sessionStorage.getItem(CREDENTIAL_KEY));
		this.token = credentials.token;
		this.connect();
	}

	private connect(): SocketService {
		if (SocketService.connections[this.namespace]) {
			this.socket = SocketService.connections[this.namespace].socket;
			return SocketService.connections[this.namespace];
		}
		this.socket = io.connect(`${process.env.REACT_APP_WS_URL || ''}/${this.namespace}`, { path: '/socket.io', transports: ['websocket'], query: { token: this.token } });
		SocketService.connections[this.namespace] = this;
		return this;
	}

	public send(type: string, message?: any): void {
		this.socket?.emit(type, message);
	}

	public listen(type: string, init: any): BehaviorSubject<any> {
		if (this.channelList[type]) {
			return this.channelList[type];
		}
		this.channelList[type] = new BehaviorSubject(init);
		this.handleList[type] = (data) => {
			this.channelList[type].next(data);
		};
		this.socket.on(type, this.handleList[type]);
		return this.channelList[type];
	}

	public static disconnect(): void {
		SocketService.connections = {};
		for (const conn in SocketService.connections) {
			SocketService.connections[conn].socket.disconnect();
			delete SocketService.connections[conn];
		}
	}
}
