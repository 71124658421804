import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import axios from 'axios';
import { TwoFaStore } from './twoFaTypes';
import { AppDispatch } from '@store/store';

const initialState: TwoFaStore = {
	open: false,
	loading: false,
	invalid: false,
	errorCode: null,
	setupMissing: false
};

let originalRequest: any = null;
let error: any = null;
let callbacks: any = [];
let cancelSource: any;


const twoFASlice = createSlice({
	name: 'twoFa',
	initialState,
	reducers: {
		open2FAModal(state) {
			state.open = true;
		},
		close2FAModal(state) {
			state.open = false;
			originalRequest = null;
			callbacks = [];
		},
		set2FAInvalid(state, action: PayloadAction<boolean>) {
			state.invalid = action.payload;
		},
		set2FALoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setSetupMissing(state, action: PayloadAction<boolean>) {
			state.setupMissing = action.payload;
		},
		setErrorCode(state, action: PayloadAction<string>) {
			state.errorCode = action.payload;
		}
	}
});

export const { open2FAModal,
	close2FAModal,
	set2FAInvalid,
	set2FALoading,
	setErrorCode,
	setSetupMissing } = twoFASlice.actions;


export const request2FA = (payload: any, resolve_cb: any, reject_cb: any, parent_error: any) => {
	originalRequest = payload;
	error = parent_error;
 
	if (callbacks.length > 0) {
		const previous_callbacks = callbacks.slice(-1);
		callbacks.push({
			reject_cb: previous_callbacks[0].reject_cb(reject_cb),
			resolve_cb: previous_callbacks[0].resolve_cb(resolve_cb)
		});
	} else {
		callbacks.push({
			reject_cb: reject_cb,
			resolve_cb: resolve_cb
		});
	}
};

export const rerunRequest2FA = (resolve_cb: any, reject_cb: any, parent_error: any) => {

	error = parent_error;
	const CancelToken = axios.CancelToken;
	cancelSource = CancelToken.source();
	originalRequest.cancelToken = cancelSource.token;

	callbacks = [{
		reject_cb: reject_cb,
		resolve_cb: resolve_cb
	}];
};


export const next = (authenticatorCode: string) => {
	return async (dispatch: AppDispatch) => {
		const CancelToken = axios.CancelToken;

		cancelSource = CancelToken.source();
		originalRequest.cancelToken = cancelSource.token;

		const payload = originalRequest;
		const payloadData = JSON.parse(payload.data);


		payloadData.authenticatorCode = authenticatorCode;

		payload.data = JSON.stringify(payloadData);
		const cb = callbacks.slice(-1);
		if (cb?.length > 0) {
			const { resolve_cb, reject_cb } = cb[0];
			callbacks.pop();
			try {
				resolve_cb(await request(payload));
			} catch (e) {
				reject_cb(e);
			}
		}
		setTimeout(() => dispatch(set2FALoading(false)), 2000);
		dispatch(close2FAModal());
	};
};


export const reject2FA = (cancelError?: string) => {
	return async (dispatch: AppDispatch) => {
		const cb = callbacks.slice(-1);
		if (cb?.length > 0) {
			const { reject_cb } = cb[0];
			if (reject_cb) { reject_cb(cancelError || error); }
		}
		else {
			//cancel original request 
			if (originalRequest && cancelSource) {
				cancelSource.cancel();
			}
		}
		dispatch(close2FAModal());
		dispatch(set2FAInvalid(false));
	};
};


export default twoFASlice.reducer;
