import { getOrderStatistics } from "@features/invoices/invoicesSlice";
import useCustomBreakpoints from "@hooks/useCustomBreakpoints";
import { Card, CardContent, Typography, Grid, Link, Autocomplete, TextField, CircularProgress } from "@mui/material";
import { getCurrencySign } from "@utils/currency";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const initialStats = {
    totalOrders: 31,
    completedOrders: 3,
    totalOrderAmount: 167631.95,
    totalReceivedAmount: 0
}

enum FilterType {
    THIS_MONTH = 'THIS_MONTH',
    LAST_30_DAYS = 'LAST_30_DAYS',
    PREVIOUS_MONTH = 'PREVIOUS_MONTH',
    THIS_YEAR = 'THIS_YEAR',
    PREVIOUS_YEAR = 'PREVIOUS_YEAR',
}

const StatsCards = () => {
    const { t } = useTranslation();
    const { isDesktop } = useCustomBreakpoints();
    const [selectedFilter, setSelectedFilter] = useState<FilterType>(FilterType.LAST_30_DAYS);
    const [statistics, setStatistics] = useState<{ totalOrders: number, completedOrders: number, totalOrderAmount: number, totalReceivedAmount: number }>(initialStats);
    const [statisticsLoading, setStatisticsLoading] = useState<boolean>(true);
    const [statisticsError, setStatisticsError] = useState<boolean>(false);

    const amountValues = ['totalOrderAmount', 'totalReceivedAmount'];
    const filterOptions = Object.values(FilterType).map((filter) => {
        return { value: filter, label: t(`stats.filter.${filter}`) }
    })

    const getRecentActivities = useCallback(async (filter: string) => {
        try {
            setStatisticsError(false);
            setStatisticsLoading(true);
            const response = await getOrderStatistics(filter);
            setStatistics(response);
        } catch (err) {
            setStatisticsError(true);
        } finally {
            setStatisticsLoading(false);
        }
    }, []);

    useEffect(() => {
        getRecentActivities(selectedFilter)
    }, [getRecentActivities, selectedFilter]);


    return (
        <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} container justifyContent='flex-end'>
                {isDesktop ? filterOptions.map((option, index) => (
                    <React.Fragment key={option.value}>
                        <Link component='button' disabled={selectedFilter === option.value} onClick={() => setSelectedFilter(option.value)}>{option.label}</Link>
                        {index < filterOptions.length - 1 && <span style={{ margin: "0 4px" }}>|</span>}
                    </React.Fragment>
                )) : <Autocomplete
                    options={filterOptions ?? []}
                    onChange={(event: any, newValue: { value: FilterType, label: string } | null) => {
                        if (!newValue) {
                            return;
                        }
                        setSelectedFilter(newValue.value);
                    }}
                    value={filterOptions.find(option => option.value === selectedFilter) || undefined}
                    autoComplete={false}
                    getOptionLabel={(option) => option?.label ?? ""}
                    renderInput={(params) => <TextField {...params} />}
                    disableClearable
                    sx={{ minWidth: 200 }}
                />}
            </Grid>
            {Object.entries(statistics).map(([key, value]) => (
                <Grid item xs={12} sm={6} md={6} lg={3} key={key}>
                    <Card
                        sx={theme => ({
                            background: theme.palette.secondaryBackground.main,
                            textAlign: "center",
                            border: "1px solid #31F2DA",
                            borderRadius: '16px',
                            boxShadow: "0 4px 10px rgba(49, 242, 218, 0.3)",
                        })}
                    >
                        <CardContent>
                            <Typography
                                variant="h6"
                                sx={{ color: "#31F2DA", marginBottom: 1 }}
                            >
                                {t(`stats.label.${key}`)}
                            </Typography>
                            {statisticsError ? <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    <ReportProblemIcon color="error" />
                                    <Typography variant="subtitle2">
                                        {t('alerts.couldntFetchData')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link component='button' sx={theme => ({ fontSize: theme.typography.subtitle2.fontSize })} onClick={() => getRecentActivities(selectedFilter)}>{t('buttons.refresh')}</Link>
                                </Grid>
                            </Grid> : <Typography
                                variant="h4"
                                fontWeight="bold"
                            >
                                {statisticsLoading ? <CircularProgress /> : <>{amountValues.includes(key) ?
                                    <NumericFormat
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandsGroupStyle='thousand'
                                        thousandSeparator
                                        prefix={`${getCurrencySign('EUR')} `}
                                        value={value ?? 0} />
                                    : value}
                                </>}
                            </Typography>}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default StatsCards;