import * as React from 'react';
import { SvgIcon } from '@mui/material';

const TransferIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.78312 7.4944L9.50576 5.40589C9.90015 5.10336 9.97461 4.5384 9.67208 4.14401C9.36955 3.74963 8.80459 3.67516 8.41021 3.97769L4.10796 7.2779C3.37742 7.83829 3.37743 8.93917 4.10796 9.49955L8.41021 12.7998C8.80459 13.1023 9.36955 13.0278 9.67208 12.6334C9.97461 12.239 9.90015 11.6741 9.50576 11.3716L6.79793 9.2944L19.395 9.2944C19.8921 9.2944 20.295 8.89146 20.295 8.39441C20.295 7.89735 19.8921 7.49441 19.395 7.49441L6.78312 7.4944ZM5.64861 8.36468L5.61726 8.38873L5.64831 8.41254L5.64813 8.3944C5.64813 8.38446 5.64829 8.37455 5.64861 8.36468Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.4965 18.3726L15.6351 20.5675C15.2407 20.8701 15.1663 21.435 15.4688 21.8294C15.7713 22.2238 16.3363 22.2983 16.7307 21.9957L21.0329 18.6955C21.7635 18.1351 21.7635 17.0343 21.0329 16.4739L16.7307 13.1737C16.3363 12.8711 15.7713 12.9456 15.4688 13.34C15.1663 13.7344 15.2407 14.2993 15.6351 14.6019L18.2042 16.5726L5.74587 16.5726C5.24881 16.5726 4.84587 16.9755 4.84587 17.4726C4.84587 17.9697 5.24881 18.3726 5.74587 18.3726L18.4965 18.3726ZM19.4888 17.558C19.4869 17.5779 19.4844 17.5977 19.4812 17.6173L19.5236 17.5847L19.4888 17.558Z" fill="white" />
        </svg>

    </SvgIcon>
);

export default TransferIcon;
