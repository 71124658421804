import MaintenanceChip from "@components/chips/MaintananceChip";
import CustomIconButton from "@components/CustomIconButton";
import { Card, Grid, Typography } from "@mui/material";
import { IconButton } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

interface Props {
    onClick: () => void;
    title: string;
    description: string;
    icon: JSX.Element;
    chips?: JSX.Element;
    fillIconFully?: boolean;
    maintenanceEnabled?: boolean;
}

const TransferTypeCard = ({ onClick, title, description, icon, chips, fillIconFully, maintenanceEnabled }: Props) => {


    return (
        <Card
            sx={theme => ({
                background: theme.palette.secondaryBackground.light,
                padding: '20px',
                borderRadius: '16px',
                cursor: maintenanceEnabled ? 'default' : 'pointer',
                opacity: maintenanceEnabled ? 0.5 : 1
            })}
            onClick={() => !maintenanceEnabled && onClick()}
        >
            <Grid container>
                <Grid item xs={12} container justifyContent='space-between' mb={1} alignItems='center'>
                    <CustomIconButton color="primary" sx={theme => ({ stroke: theme.palette.primary.main, fill: fillIconFully ? theme.palette.primary.main : theme.palette.primary.light })}>
                        {icon}
                    </CustomIconButton>
                    <IconButton sx={theme => ({ background: theme.palette.secondaryBackground.main, opacity: 0.7 })}><ArrowOutwardIcon /></IconButton>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontWeight={600} variant="body1">{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color='text.secondary' variant="subtitle2" >{description}</Typography>
                </Grid>
                {chips && !maintenanceEnabled && <Grid item xs={12} mt={1}>{chips}</Grid>}
                {maintenanceEnabled && <Grid item xs={12} mt={1}><MaintenanceChip /></Grid>}
            </Grid>
        </Card>
    )

};

export default TransferTypeCard;
