import * as React from 'react';
import { SvgIcon } from '@mui/material';

const HomeIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.28553 23.0098H17.8549C19.4013 23.0098 20.6549 21.7562 20.6549 20.2098V14.0895H21.3334C23.5131 14.0895 24.4701 11.34 22.7615 9.9866L14.9297 3.78294C13.5473 2.68792 11.5931 2.68791 10.2107 3.78294L2.37887 9.9866C0.670246 11.34 1.62727 14.0895 3.80698 14.0895H4.48553V20.2098C4.48553 21.7562 5.73913 23.0098 7.28553 23.0098ZM11.3284 5.19391L3.49652 11.3976C3.12508 11.6918 3.33313 12.2895 3.80698 12.2895H5.28553C5.83781 12.2895 6.28553 12.7372 6.28553 13.2895V20.2098C6.28553 20.762 6.73324 21.2098 7.28553 21.2098H9.0574V14.2767C9.0574 13.2273 9.90806 12.3767 10.9574 12.3767H14.1831C15.2325 12.3767 16.0831 13.2273 16.0831 14.2767V21.2098H17.8549C18.4071 21.2098 18.8549 20.762 18.8549 20.2098V13.2895C18.8549 12.7372 19.3026 12.2895 19.8549 12.2895H21.3334C21.8073 12.2895 22.0153 11.6918 21.6439 11.3976L13.812 5.19392C13.0844 4.61759 12.056 4.61759 11.3284 5.19391ZM14.2831 21.2098V14.2767C14.2831 14.2214 14.2383 14.1767 14.1831 14.1767H10.9574C10.9022 14.1767 10.8574 14.2214 10.8574 14.2767V21.2098H14.2831Z"
                    fill="white" />
            </g>
        </svg>

    </SvgIcon>
);

export default HomeIcon;
