import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { AllowedActionsStore, AllowedAction, MerchantPermissions } from './allowedActionsTypes';
import { User } from '@features/auth/types';
import { ProfileType } from '../../types/global';


const initialState: AllowedActionsStore = {
	list: [],
};

const slice = createSlice({
	name: 'allowedActions',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<AllowedAction[]>) {
			state.list = action.payload;
		},

	}
});

export const { setList, } = slice.actions;


export const getAllowedActions = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await request.get('/api-defi-network/employee/allowed-actions');
			const data = response?.data || null;
			dispatch(setList(data));
		}
		catch (e) {
			dispatch(setList([]));
		}
	};
};

export const isWriteAllowed = (permission: MerchantPermissions, user?: User, allowedActions?: AllowedAction[]) => {
	if (!user) return false;
	if (!user?.type) return false;

	if (user.type === ProfileType.EMPLOYEE) {

		if (!allowedActions || !allowedActions?.length) return false;
		return allowedActions.some(p => p.code === permission && p.writeable);
	}
	else { return true };
}

export const isReadAllowed = (permission: MerchantPermissions, user?: User, allowedActions?: AllowedAction[]) => {
	if (!user) return false;
	if (!user?.type) return false;

	if (user.type === ProfileType.EMPLOYEE) {

		if (!allowedActions || !allowedActions?.length) return false;
		return allowedActions.some(p => p.code === permission);
	}
	else { return true };
}


export default slice.reducer;
