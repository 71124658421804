import { UnknownAction, combineReducers, Reducer } from "redux";
import user from "@features/auth/userSlice";
import credentials from "@features/auth/credentialsSice";
import status from "@features/status/statusSlice";
import twoFa from "@features/twoFa/twoFaSlice";
import italyTerritory from '@features/italyTerritory/italyTerritorySlice';
import kyc from '@features/kyc/kycSlice';
import menu from '@features/menu/menuSlice';
import merchantStore from '@features/merchantStore/merchantStoreSlice';
import employees from '@features/employees/employeesSlice';
import employeePermissions from '@features/employeePermissions/employeePermissionsSlice';
import category from '@features/category/categorySlice';
import product from '@features/product/productSlice';
import accounts from '@features/accounts/accountsSlice';
import contacts from '@features/beneficiary/beneficiarySlice';
import transactions from '@features/transactions/transactionsSlice';
import cards from '@features/cards/cardsSlice';
import allowedActions from '@features/allowedActions/allowedActionsSlice';
import cardsTransactions from '@features/cardsTransactions/cardsTransactionsSlice';
import invoices from '@features/invoices/invoicesSlice';
import merchantTax from '@features/merchantTax/merchantTaxSlice';
import support from '@features/support/supportSlice';



const appReducer = combineReducers({
  user,
  credentials,
  status,
  twoFa,
  italyTerritory,
  kyc,
  menu,
  merchantStore,
  employees,
  employeePermissions,
  category,
  product,
  accounts,
  contacts,
  transactions,
  cards,
  allowedActions,
  cardsTransactions,
  invoices,
  merchantTax,
  support
});

const rootReducer: Reducer = (state: RootState, action: UnknownAction) => {
  if (action.type === "RESET_APP") {
    const { credentials, status } = state;
    const cleanedCredendials = { ...credentials, token: null, username: null, agent: undefined };

    state = {} as RootState;
    state = {
      ...state,
      status,
      credentials: cleanedCredendials,
      menu: { drawerOpen: true, pathElements: [] }
    };
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
