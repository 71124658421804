import { PaginateParams } from "types/pagination";

export interface TransactionsStore {
	list: Transfer[],
	loading: boolean,
	count: number,
	pagination: PaginateParams,
	recentActivitiesList: Transfer[],
	recentActivitiesLoading: boolean,
}

export interface Transfer {
	accountName: string;
	accountNumber: string;
	activity: string;
	amount: number;
	amountString: string;
	counterpartyName: string;
	counterpartyAccountNumber: string;
	currency: string;
	date: number;
	decimals: number;
	details: string;
	documentNumber: number;
	status: TransactionStatus;
	totalFeeAmount: number | null;
	totalFeeAmountString: string | null;
	transactionNumber: string;
}

export enum TransactionStatus {
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
	PENDING = 'PENDING',
}