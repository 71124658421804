import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import { isEmpty } from 'lodash';
import { CardsTransactionsStore, CardTransaction } from './cardsTransactionsTypes';



const initialState: CardsTransactionsStore = {
	transactions: [],
	hasMore: false,
	loading: false,
	initialLoading: false,
	cardId: null,
	skip: 0,
	search: null,
};

const slice = createSlice({
	name: 'cardsTransactions',
	initialState,
	reducers: {
		setTransactions(state, action: PayloadAction<CardTransaction[]>) {
			state.transactions = action.payload;
		},
		setHasMore(state, action: PayloadAction<boolean>) {
			state.hasMore = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setInitialLoading(state, action: PayloadAction<boolean>) {
			state.initialLoading = action.payload;
		},
		setCardId(state, action: PayloadAction<number>) {
			state.cardId = action.payload;
		},
		setSkip(state, action: PayloadAction<number>) {
			state.skip = action.payload;
		},
		setSearch(state, action: PayloadAction<string | null>) {
			state.search = action.payload;
		},
		putNewTransaction(state, action: PayloadAction<{ cardId: number, transaction: CardTransaction}>) {
			if(action.payload.cardId !== state.cardId) return;
			const transaction = action.payload.transaction;
			const lastPendingIndex = state.transactions.map(item => item.status).lastIndexOf('PENDING');
			const trxArr = state.transactions;

			if (lastPendingIndex === -1) {
				// If no 'PENDING' status is found, add the object at the start
				trxArr.unshift(transaction);
			  } else {
				// Otherwise, insert the new object after the last 'PENDING' status
				trxArr.splice(lastPendingIndex + 1, 0, transaction);
			  }

			state.transactions = trxArr;
		}

	}
});

export const { setTransactions, setHasMore, setInitialLoading, setCardId, setSkip, setLoading, setSearch } = slice.actions;



export const getCardTransactions = (cardId: number, skip: number, search?: string | null) => {
	return async (dispatch: AppDispatch, state: () => { cardsTransactions: any; }) => {
		try {
			dispatch(setCardId(cardId));
			if (skip === 0) {
				dispatch(setInitialLoading(true));
			}
			dispatch(setLoading(true));
			const params = {
				skip,
				search: search ?? '',
				take: 20,
			};
			const response = await request.get(`/api-defi-network/cards/transactions/${cardId}`, { params });
			const data = response?.data || null;

			if (skip === 0) {
				dispatch(setTransactions(data.transactions));
			} else {
				const { cardsTransactions } = state();
				const { transactions } = cardsTransactions;

				dispatch(setTransactions([...transactions, ...data.transactions]));
			}
			dispatch(setHasMore(data.hasMore));
		} catch (err) {
			dispatch(setHasMore(false));
			dispatch(setTransactions([]));
			console.error('Error fetching transactions:', err);
		} finally {
			dispatch(setInitialLoading(false));
			dispatch(setLoading(false));
		};
	}
};

let socketService: SocketService | null;
let updateSubscriber: Subscription;

export const connectCardTransactionsSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('card-transactions');
	}
};

export const disconnectCardTransactionsSocket = (): void => {
	socketService = null;
};

export const subscribeCardTransactionsSocket = () => {
	return async (dispatch: AppDispatch) => {
		if (!socketService) return;
		try {
			updateSubscriber = socketService.listen('transaction.new', {}).subscribe((data) => {
				if (isEmpty(data)) return;
			});
		} catch (error) {
			console.log(error);
		}
	};

};

export const unsubscribeCardTransactionsSocket = (): void => {
	updateSubscriber.unsubscribe();
}

export default slice.reducer;
