import * as React from 'react';
import { SvgIcon } from '@mui/material';

const BankTransferIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <rect
                x={3.85352}
                y={24.7285}
                width={25.2915}
                height={5.80664}
                rx={1.5}
                stroke="inherit"
                strokeWidth={2}
            />
            <path d="M3.854 10.665a2 2 0 011.025-1.747l10.159-5.665a3 3 0 012.923 0l10.158 5.665a2 2 0 011.026 1.747v2.757a1.5 1.5 0 01-1.5 1.5H5.354a1.5 1.5 0 01-1.5-1.5v-2.757z"
                stroke="#inherit"
                strokeWidth={2}
            />
            <path
                d="M7.268 15.408v8.627M13.656 15.408v8.627M25.73 15.408v8.627M20.045 15.408v8.627"
                stroke="inherit"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>

    </SvgIcon>
);

export default BankTransferIcon;
