import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { UserStore, Credentials, SignInPayload, SignUpPayload, ForgotPasswordPayload, User, VerifyPhonePayload } from './types';
import { clearCredentials, setCredentials, setTokenData } from './credentialsSice';
import { SocketService } from '@services/socketService';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { clearPathElements } from '@features/menu/menuSlice';

const initialState: UserStore = {
	status: null,
	error: null,
	user: null,
	backupKey: null,
	twoFactorCode: null,
};


const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		clearStatus(state) {
			state.status = null;
		},
		setError(state, action: PayloadAction<any | null>) {
			state.error = action.payload;
		},
		setUser(state, action: PayloadAction<User>) {
			state.user = action.payload;
		},
		updateUser(state, action: PayloadAction<Partial<User>>) {
			state.user = { ...state.user, ...action.payload };
		},
		clearUser(state) {
			state.user = null;
		},
		setTwoFactorCode(state, action: PayloadAction<string>) {
			state.twoFactorCode = action.payload;
		},
		setBackupKey(state, action: PayloadAction<string>) {
			state.backupKey = action.payload;
		},
	}
});

export const {
	clearStatus,
	setError,
	setUser,
	updateUser,
	clearUser,
	setTwoFactorCode,
	setBackupKey
} = userSlice.actions;

export const login = (payload: SignInPayload) => {
	return async (dispatch: AppDispatch) => {
		const response = request.post('/api-defi-network/auth/sign-in',
			payload
		);
		const { data } = await response;

		const credentials: Credentials = { token: data.token, clientId: data.clientId, username: data.username, profileType: data?.profileType };
		dispatch(setCredentials(credentials));
		dispatch(setError(null));
	}
};

export const register = (payload: SignUpPayload) => {
	return async (dispatch: AppDispatch) => {
		const response = request.post('/api-defi-network/auth/sign-up',
			payload
		);
		const { data } = await response;

		const credentials: Credentials = { token: data.token, clientId: data.clientId, username: data.username, profileType: data?.profileType };
		dispatch(setCredentials(credentials));
		dispatch(setError(null));

		return data;
	}
};


export const logout = () => {
	return async (dispatch: AppDispatch) => {
		try {
			await request.get('/api-defi-network/auth/logout');
			dispatch(clearCredentials());
			dispatch(clearPathElements());
		} catch (error) {
			console.log(error);
		}
	};
};

export const isUsernameValid = async (username: string) => {
	const response = await request.get('/api-defi-network/auth/username/lookup', { params: { username } });
	const { data } = response;
	return data.valid;
};

export const refreshToken = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await request.post('/api-defi-network/auth/refresh-token');
			const { data } = response;
			dispatch(setTokenData(data));
		} catch (err) {
			console.log(err);
		}
	};
};

export const refreshUser = () => {
	return async (dispatch: AppDispatch) => {
		try {
			const response = await request.get('/api-defi-network/user/me');
			const { data } = response;
			dispatch(setUser(data));
			dispatch(updateUser(data));
			return data;
		} catch (err) {
			console.error('refreshUser', err);
		}
	};
};

export const postForgotPassword = async (payload: ForgotPasswordPayload) => {
	const response = await request.post('/api-defi-network/auth/forgot-password', payload);
	const { data } = response;
	return data;
};

export const postVerifyPhoneNumber = async (payload: VerifyPhonePayload) => {
	const response = await request.post('/api-defi-network/user/verify-phone', payload);
	const { data } = response;
	return data;
};

export const postResendOtpCode = async (email: string, type: 'welcome' | 'otp') => {
	const response = await request.post(`/api-defi-network/auth/resend-email/${type}`, { email });
	const { data } = response;
	return data;
};

export const postResendSmsCode = async (phoneNumber: string, countryCode: string) => {
	const response = await request.post('/api-defi-network/user/resend-otp/sms', { phoneNumber, countryCode });
	const { data } = response;
	return data;
};


export const generate2FACode = () => {
	return async (dispatch: AppDispatch) => {
		const response = await request.post('/api-defi-network/user/two-factor/generate', {});
		const { data } = response;
		const { qrCodeImage, backupKey } = data;
		dispatch(setTwoFactorCode(qrCodeImage));
		dispatch(setBackupKey(backupKey));
		dispatch(setError(null));
	};
};

export const enableTwoFactorAuthentication = async (authenticatorCode: string) => {
	await request.post('/api-defi-network/user/two-factor/enable', { authenticatorCode });
};

export const disableTwoFactorAuthentication = async () => {
	await request.post('/api-defi-network/user/two-factor/disable', { authenticatorCode: null });
};

export const closeAccount = async () => {
	await request.post('/api-defi-network/user/close-account', { authenticatorCode: null });
};

export const changeEmail = async (payload: any) => {
	await request.post('/api-defi-network/user/change-email', payload);
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
	await request.post('/api-defi-network/user/change-password', { oldPassword, newPassword });
};




let socketService: SocketService | null;
let dataSubscriber: Subscription;
let updateSubscriber: Subscription;

export const connectUserSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('network-user');
	}
};

export const disconnectUserSocket = (): void => {
	socketService = null;
};

export const subscribeUserSocket = () => {
	return async (dispatch: AppDispatch) => {
		if (!socketService) return;
		try {
			dataSubscriber = socketService.listen('dataR', []).subscribe((data: Partial<User>) => {
				if (isEmpty(data)) return;
				dispatch(setUser(data));
				dispatch(updateUser(data));
			});
			updateSubscriber = socketService.listen('update', {}).subscribe((data) => {
				if (isEmpty(data)) return;
				dispatch(updateUser(data));
			});

			socketService.send('data');
		} catch (error) {
			console.log(error);
			dispatch(setError(error));
		}
	};
};

export const unsubscribeUserSocket = (): void => {
	dataSubscriber.unsubscribe();
	updateSubscriber.unsubscribe();
}


export default userSlice.reducer;
