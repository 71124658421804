import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import DashboardBusinessRoutes from './DashboardBusinessRoutes';
import DashboardIndividualRoutes from './DashboardIndividualRoutes';
import { ProfileType } from '../types/global';
import { useSelector } from '@store/store';
import LegalRoutes from './LegalRoutes';


export default function Routes() {

    const { profileType } = useSelector((state) => state.credentials);
    const isBussiness = [ProfileType.BUSINESS.toString(), ProfileType.EMPLOYEE.toString()].includes(profileType ?? '');
    return useRoutes([LoginRoutes, OnboardingRoutes, LegalRoutes,
        isBussiness ? DashboardBusinessRoutes : DashboardIndividualRoutes]);
}
