import React, { useEffect } from 'react';
import request from '@services/request';
import { useDispatch } from '@store/store';
import { logout } from '@features/auth/userSlice';
import { TwoFaErrorParamType } from '@features/twoFa/twoFaTypes';
import { open2FAModal, request2FA, set2FAInvalid, set2FALoading } from '@features/twoFa/twoFaSlice';
import { CREDENTIAL_KEY } from '@features/auth/credentialsSice';
import { useNavigate } from 'react-router-dom';
import { DashboardPath } from '../types/global';

const Interceptors = (): React.ReactElement => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getStatus = (err: any) => {
		const response = err?.response;
		if (response) {
			const status = response?.status;
			return status;
		} else {
			const status = err?.status;
			return status;
		}
	};

	const getErrorAndParam = (err: any) => {

		if (!err) return { error: null, errorParam: null, message: null };

		const data = err?.response ? err?.response?.data : err?.data;
		if (!data) return { error: null, errorParam: null, message: null };

		if (data?.errors && data?.errors?.length > 0) {
			return { error: data.errors[0].error, errorParam: data.errors[0]?.error_param, message: data?.message }
		}
		else if (data?.error) {
			return { error: data.error, errorParam: data?.error_param, message: data?.message }
		}
		else {
			return { error: null, errorParam: null, message: null };
		}
	}


	useEffect(() => {
		// Register intersectors
		request.interceptors.request.use(
			(config: any) => {
				// Get token and add it to header "Authorization"
				const credentialsKey = CREDENTIAL_KEY;
				try {
					const key = sessionStorage.getItem(credentialsKey);
					const credentials = key ? JSON.parse(key) : undefined;
					// If user not authorized just forward the request further
					if (!credentials) {
						return config;
					}
					config.headers.Authorization = credentials.token;
				}
				catch (e) {
					console.log(e);
				}
				if (config.headers['Content-Type'] !== 'multipart/form-data') {
					config.headers['Content-Type'] = 'application/json';
				}

				return config;
			},
			(error: any) => Promise.reject(error)
		);
		request.interceptors.response.use(undefined, (err: any) => {

			try {
				//abort of axios call
				//console.log(err);
				if (err === 'CANCELLED') {
					return Promise.reject('CANCELLED');
				}

				const originalRequest = err?.config;


				const response = err?.response ? err.response : err;
				const status = getStatus(err);
				const errorData = getErrorAndParam(err);

				if (status === 417) {
					if (errorData.error === 'required' && errorData.errorParam === TwoFaErrorParamType.AUTH_CODE) {
						dispatch(set2FAInvalid(false));
						dispatch(open2FAModal());
						return new Promise((resolve, reject) => {
							request2FA(originalRequest, resolve, reject, response);
						});
					} else if ((errorData.error === 'invalid' && errorData.errorParam === TwoFaErrorParamType.AUTH_CODE) ||
						errorData.message === 'wrong two factor code') {
						if (originalRequest?.url.includes('/api-defi-network/user/two-factor/enable')) {
							return Promise.reject(response);
						}
						setTimeout(() => dispatch(set2FALoading(false)), 1000);
						dispatch(set2FAInvalid(true));
						return new Promise((resolve, reject) => {
							request2FA(originalRequest, resolve, reject, response);
						});
					}
				}

				if (status === 401) {
					dispatch(logout());
				}

				if (status === 403) {
					navigate(DashboardPath.FORBIDDEN);
				}


				return Promise.reject(response);

			} catch (e) {
				return Promise.reject(e);
			}
		});
		// eslint-disable-next-line 
	}, [dispatch]);

	return (
		<div id="interceptors"></div>
	);
};

export default Interceptors; 
