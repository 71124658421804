import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const StandardPaymentChip = () => {
    const { t } = useTranslation();

    return <Chip size="small" label={t('chips.standard')} icon={<AccessTimeIcon />} />;
}

export default StandardPaymentChip;