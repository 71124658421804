import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CSSObject, Grid } from '@mui/material';
import { useSelector } from '@store/store';
import HomeIcon from '@assets/icons/HomeIcon';
import CardsIcon from '@assets/icons/CardsIcon';
import TransferIcon from '@assets/icons/TransferIcon';
import PaymentsIcon from '@assets/icons/PaymentsIcon';
import { DashboardPath } from '../../types/global';
import SidebarMenu from './SidebarMenuItem';
import SettingsIcon from '@assets/icons/SettingsIcon';
import HelpIcon from '@assets/icons/HelpIcon';

const iconStyle: CSSObject = {
    opacity: 0.7,
    fill: 'white'
};

const IndividualMenu = () => {
    const { t } = useTranslation();
    const { drawerOpen } = useSelector((state) => state.menu);

    const items = [{
        id: 1,
        title: t('menu.home'),
        icon: <HomeIcon sx={iconStyle} />,
        to: DashboardPath.HOME
    }, {
        id: 2,
        title: t('menu.cards'),
        icon: <CardsIcon sx={iconStyle} />,
        to: DashboardPath.CARDS
    },
    {
        id: 3,
        title: t('menu.transfers'),
        icon: <TransferIcon sx={iconStyle} />,
        to: DashboardPath.TRANSFERS
    },
    {
        id: 4,
        title: t('menu.orders'),
        icon: <PaymentsIcon sx={iconStyle} />,
        to: DashboardPath.ORDERS
    }]

    const bottomItems = [
        {
            id: 1,

            title: t('menu.settings'),
            icon: <SettingsIcon sx={iconStyle} />,
            to: DashboardPath.SETTINGS
        },
        {
            id: 2,

            title: t('menu.help'),
            icon: <HelpIcon sx={iconStyle} />,
            to: DashboardPath.HELP
        },
    ];

    return (
        <Grid
            container
            flexDirection='column'  >
            <SidebarMenu list={items} drawerIsOpen={drawerOpen} bottomList={bottomItems} />
        </Grid >
    );
}

export default IndividualMenu;