import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuStore, PathElementType } from './menuType';

const key = 'app_active_menu_item';

const savedMenuUnparsed = sessionStorage.getItem(key);
const savedMenu = savedMenuUnparsed ? JSON.parse(savedMenuUnparsed) : undefined;


const initialState: MenuStore = {
    drawerOpen: savedMenu?.drawerOpen || false,
    pathElements: savedMenu?.pathElements || [],
};

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
            sessionStorage.setItem(key, JSON.stringify({
                ...state,
                drawerOpen: action.payload,
            }));
        },
        // map 
        setPathElementName(state, action: PayloadAction<PathElementType>) {
            const newElement = {
                key: action.payload.key,
                pathValue: action.payload.pathValue,
                name: action.payload.name,
                titles: action.payload.titles || [],
            };

            state.pathElements = [...state.pathElements.filter(p => p.key !== newElement.key), newElement]

            sessionStorage.setItem(key, JSON.stringify({
                ...state,
                pathElements: state.pathElements,
            }));
        },
        clearPathElements(state,) {
            state.pathElements = [];
            sessionStorage.removeItem(key);
        },

    }
});

export default menu.reducer;

export const { openDrawer, setPathElementName, clearPathElements } = menu.actions;
