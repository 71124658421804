import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { Account, AccountsStore } from './accountsTypes';
import _ from 'lodash';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import { getRecentActivitiesList } from '@features/transactions/transactionsSlice';



const initialState: AccountsStore = {
	list: [],
	loading: false,
};

const slice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<Account[]>) {
			state.list = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},

	}
});

export const { setList, setLoading } = slice.actions;


export const getAccountsList = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api-defi-network/accounts/list');
			const data = response?.data || null;
			dispatch(setList(data));
		}
		catch (e) {
			dispatch(setList([]));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getCryptoTopUpAssetList = async () => {
	const response = await request.get('/api-defi-network/accounts/deposit/assets');
	return response?.data;
};

//check if suprocesses conatines supbrocess with certain type
export const hasSubprocessWithType = (procType: string, subProcesses: any) => {
	if (subProcesses) {
		const tmp = _.keys(subProcesses).filter(key => subProcesses[key].type === procType);
		return tmp.length > 0;
	}
	else return false;
};

//Returns first subprocces  with  passed TYPE 
export const findProcessByType = (account: any, processType: string) => {
	return account?.subProcesses ? _.keys(account.subProcesses)
		.filter(key => account.subProcesses[key].type === processType)
		.map(key => account.subProcesses[key])[0] : null;
};

export const findSubProcessByType = (subProcesses: any, processType: string) => {
	return subProcesses ? _.keys(subProcesses)
		.filter(key => subProcesses[key].type === processType)
		.map(key => subProcesses[key])[0] : null;
};

//Returns  ALL subprocesses with  type='PAYMENT'
export const findPaymentTypes = (account: any) => {
	return account?.subProcesses ? _.keys(account.subProcesses)
		.filter(key => account.subProcesses[key].type === 'PAYMENT')
		.map(key => account.subProcesses[key]) : [];
};

//Returns  ALL subprocesses with  type='PAYMENT_SELF'
export const findSelfPaymentTypes = (account: any) => {
	return account?.subProcesses ? _.keys(account.subProcesses)
		.filter(key => account.subProcesses[key].type === 'PAYMENT_SELF')
		.map(key => account.subProcesses[key]) : [];
};

let socketService: SocketService | null;
let updateSubscriber: Subscription;

export const connectAccountsSocket = (): void => {
	if (!socketService) {
		socketService = new SocketService('account');
	}
};

export const disconnectAccountsSocket = (): void => {
	socketService = null;
};

export const subscribeAccountsSocket = () => {
	return async (dispatch: AppDispatch) => {
		if (!socketService) return;
		try {
			updateSubscriber = socketService.listen('bank.account', {}).subscribe(() => {
				dispatch(getAccountsList());
				dispatch(getRecentActivitiesList());
			});
		} catch (error) {
			console.log(error);
		}
	};
	
};

export const unsubscribeAccountsSocket = (): void => {
	updateSubscriber.unsubscribe();
}


export default slice.reducer;
