import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import { dispatch, useSelector } from '@store/store';
import { GuardProps, OnboardingPath, Path, ProfileType } from '../types/global';
import { RootState } from '@store/reducer';
import { OnboardingStep } from '@features/auth/types';
import Loader from '@components/Loader';
import { getAllowedActions } from '@features/allowedActions/allowedActionsSlice';
import useAllowedActions from '@hooks/useAllowedActions';
import { connectAccountsSocket, disconnectAccountsSocket, subscribeAccountsSocket, unsubscribeAccountsSocket } from '@features/accounts/accountsSlice';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isAuthorized, isOnboarded } = useAuth();
    const navigate = useNavigate();

    const { user } = useSelector((state: RootState) => state.user);
    const { canReadAccounts } = useAllowedActions();

    useEffect(() => {
        if (!canReadAccounts || !isOnboarded) { return; }
        connectAccountsSocket();
        dispatch(subscribeAccountsSocket());

        return () => {
            unsubscribeAccountsSocket();
            disconnectAccountsSocket();
        };
    }, [canReadAccounts, isOnboarded]);

    useEffect(() => {
        const authorizeAndNavigate = async () => {
            if (!isAuthorized) {
                await navigate(Path.LOGIN, { replace: true });
                dispatch({ type: 'RESET_APP' });
            }
        };
        if (user?.type === ProfileType.EMPLOYEE) {
            dispatch(getAllowedActions());
        }
        authorizeAndNavigate();
    }, [isAuthorized, navigate, user?.type]);

    useEffect(() => {
        if (!isOnboarded) {
            if (!user) { return; }
            switch (user?.onboardingStep) {
                case OnboardingStep.TWOFA:
                    navigate(OnboardingPath.TWOFA, { replace: true });
                    break;
                case OnboardingStep.QUESTIONARY:
                    navigate(OnboardingPath.QUESTIONARY, { replace: true });
                    break;
                case OnboardingStep.ADDRESS:
                    navigate(OnboardingPath.ADDRESS, { replace: true });
                    break;
                case OnboardingStep.DOCUMENT:
                    navigate(OnboardingPath.DOCUMENT, { replace: true });
                    break;
                case OnboardingStep.ITA_DETAILS:
                    navigate(OnboardingPath.ITA_DETAILS, { replace: true });
                    break;
                case OnboardingStep.KYC:
                    navigate(OnboardingPath.KYC, { replace: true });
                    break;
                case OnboardingStep.VERIFYING:
                    navigate(OnboardingPath.VERIFYING, { replace: true });
                    break;
                case OnboardingStep.FINAL_REJECTED_KYB:
                    navigate(OnboardingPath.FINAL_REJECTED_KYB, { replace: true });
                    break;
                case OnboardingStep.FINAL_REJECTED_KYC:
                    navigate(OnboardingPath.FINAL_REJECTED_KYC, { replace: true });
                    break;
                case OnboardingStep.WAITING_FOR_PAYMENT:
                    navigate(OnboardingPath.WAITING_FOR_PAYMENT, { replace: true });
                    break;
                case OnboardingStep.BASIC_INFORMATION:
                    navigate(OnboardingPath.KYB_BASIC_INFORMATION, { replace: true });
                    break;
                case OnboardingStep.BUSINESS_INFORMATION:
                    navigate(OnboardingPath.KYB_BUSINESS_INFORMATION, { replace: true });
                    break;
                case OnboardingStep.REPRESENTERS:
                    navigate(OnboardingPath.KYB_REPRESENTERS, { replace: true });
                    break;
                case OnboardingStep.SHAREHOLDERS:
                    navigate(OnboardingPath.KYB_SHAREHOLDERS, { replace: true });
                    break;
                case OnboardingStep.ORDERS:
                    navigate(OnboardingPath.KYB_ORDERS, { replace: true });
                    break;
                case OnboardingStep.HISTORY:
                    navigate(OnboardingPath.KYB_HISTORY, { replace: true });
                    break;
                case OnboardingStep.MERCHANT_DETAILS:
                    navigate(OnboardingPath.KYB_MERCHANT_DETAILS, { replace: true });
                    break;
                case OnboardingStep.BANK_ACCOUNT_DETAILS:
                    navigate(OnboardingPath.KYB_BANK_ACCOUNT_DETAILS, { replace: true });
                    break;
                case OnboardingStep.DOCUMENTS:
                    navigate(OnboardingPath.KYB_DOCUMENTS, { replace: true });
                    break;
                case OnboardingStep.SUBMIT_KYB:
                    navigate(OnboardingPath.KYB_SUBMIT, { replace: true });
                    break;
                case OnboardingStep.DOWNLOAD_APP:
                    navigate(OnboardingPath.DOWNLOAD_APP, { replace: true });
                    break;
                case OnboardingStep.NONE:
                    navigate(Path.DASHBOARD_PATH, { replace: true });
                    break;
                default:
                    navigate(OnboardingPath.TWOFA, { replace: true });
                    break;
            }
        }
    }, [isOnboarded, navigate, user?.onboardingStep, user]);

    if (!user) {
        return <Loader />;
    }

    return children;
};

export default AuthGuard;
