import React, { createContext, useEffect, useState } from 'react';
import Loader from '@components/Loader';
import { RootState } from '@store/reducer';
import { dispatch, useSelector } from '@store/store';
import { OnboardingStep } from '@features/auth/types';
import { clearUser, connectUserSocket, disconnectUserSocket, refreshToken, subscribeUserSocket, unsubscribeUserSocket } from '@features/auth/userSlice';
import { SocketService } from '@services/socketService';

interface InitialStateType {
    isAuthorized: boolean;
    isOnboarded: boolean | undefined
}
const initialState: InitialStateType = {
    isAuthorized: false,
    isOnboarded: undefined
};

const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

    const { user } = useSelector((state: RootState) => state.user);

    const { token } = useSelector(
        (state: RootState) => state.credentials
    );

    const tokenPresents = !!token;

    useEffect(() => {
        // Used to refresh token after page reload
        if (isAuthorized) {
            dispatch(refreshToken());
        }
    }, [isAuthorized]);

    useEffect(() => {
        if (!isAuthorized || !tokenPresents) return;
        const intervalId = setInterval(() => {
            dispatch(refreshToken());
        }, 60 * 1000 * 1); // 1 min

        connectUserSocket();
        dispatch(subscribeUserSocket());

        return () => {
            unsubscribeUserSocket();
            dispatch(clearUser());
            disconnectUserSocket();
            SocketService.disconnect();
            intervalId && clearInterval(intervalId);
        };
    }, [isAuthorized, tokenPresents]);

    useEffect(() => {
        if (!token) {
            setIsAuthorized(false);
            return setLoading(false);
        }
        else {
            setIsAuthorized(true);
            return setLoading(false);
        }
    }, [token]);

    if (loading) {
        return <Loader />;
    };

    return (
        <>
            <AuthContext.Provider
                value={{
                    isAuthorized,
                    isOnboarded: user?.onboardingStep && user?.onboardingStep === OnboardingStep.NONE
                }} >
                {children}
            </AuthContext.Provider>
        </>
    );
};

export default AuthContext;