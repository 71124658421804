import { SupportedLanguages } from "@features/status/types";
import { ProductTextType, TextTranslationType, TranslatePayload } from "@features/translate/translateSlice";
import { traslate } from "@features/translate/translateType";
import i18n from "../i18n/i18n";

//Translation function, can be used, where translation hool is not supported, 
//e.g. funtions outside Functional components
export const t = (value: string) => {
    return i18n.t(value).toString();
};

export const onTranslate = async (fieldName: string, value: TextTranslationType, values: TextTranslationType[], languages: SupportedLanguages[], setFieldValue: any,) => {

    try {
        const from = value?.code;
        const text = value?.text?.trim();
        const targetLangs = languages.filter(p => p.code !== from) || [];

        if (!!from && !!text && targetLangs.length > 0) {
            const payload: TranslatePayload = {
                source: from,
                target: targetLangs.map(p => p.code),
                text: text?.trim() || ''
            }
            const results = await traslate(payload) || [];
            results.map(i => setFieldValue(`${fieldName}.${values.findIndex(p => p.code === i.languageCode)}.text`, i.text));
        }
    }
    catch (e) { console.log(e) }
};

export const getText = (texts?: ProductTextType[], langId?: number ) => {
    if (texts?.length && langId) {
        return texts?.find(p => p.languageId === langId)?.text || '';
    }
    else return '';
}

export const getTextOrFirst = (texts?: ProductTextType[], langId?: number ) => {
    if (texts?.length && langId) {
        return texts?.find(p => p.languageId === langId)?.text || texts[0].text;
    }
    else return texts?.length ? texts[0].text : '';
}