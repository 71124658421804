import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { TransactionsStore, Transfer } from './transactionsTypes';
import { PaginateParams } from 'types/pagination';

export const initialTransactionsPagination: PaginateParams = {
	search: '',
	skip: 0,
	take: 10,
}

const initialState: TransactionsStore = {
	list: [],
	loading: false,
	count: 0,
	pagination: initialTransactionsPagination,
	recentActivitiesList: [],
	recentActivitiesLoading: false,
};

const slice = createSlice({
	name: 'transactions',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<Transfer[]>) {
			state.list = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setCount(state, action: PayloadAction<number>) {
			state.count = action.payload;
		},
		setPagination(state, action: PayloadAction<PaginateParams>) {
			state.pagination = action.payload;
		},
		setRecentActivitiesList(state, action: PayloadAction<Transfer[]>) {
			state.recentActivitiesList = action.payload;
		},
		setRecentActivitiesLoading(state, action: PayloadAction<boolean>) {
			state.recentActivitiesLoading = action.payload;
		},
	}
});

export const { setList, setLoading, setRecentActivitiesLoading, setRecentActivitiesList, setCount, setPagination } = slice.actions;


export const getTransactionsList = (params: PaginateParams, accountId?: number | null) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			let response = null;
			if (accountId) {
				response = await request.get(`/api-defi-network/transfers/list/${accountId}`, { params });
			} else {
				response = await request.get('/api-defi-network/transfers/list', { params });
			}
			const data = response?.data || [];
			dispatch(setPagination(params));
			dispatch(setList(data.list));
			dispatch(setCount(data.count));
		}
		catch (e) {
			dispatch(setList([]));
			dispatch(setPagination(initialTransactionsPagination));

		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getRecentActivitiesList = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setRecentActivitiesLoading(true));
			const response = await request.get('/api-defi-network/transfers/list', {
				params: {
					search: '',
					skip: 0,
					take: 5,
				}
			});
			const data = response?.data || null;
			dispatch(setRecentActivitiesList(data?.list || []));
		}
		catch (e) {
			dispatch(setRecentActivitiesList([]));
		} finally {
			dispatch(setRecentActivitiesLoading(false));
		}
	};
};


export const downloadStatementFile = async (
	fileExtension: string,
	payload: any,
	name: string
  ) => {
	const response = await request.post(
	  `/api-defi-network/transfers/statement/${fileExtension}`,
	  payload,
	  {
		responseType: "blob",
	  }
	);
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement("a");
	link.download = name;
	link.href = url;
	link.className = "hidden";
	document.body.appendChild(link);
  
	link.onclick = function () {
	  requestAnimationFrame(function () {
		URL.revokeObjectURL(url);
		setTimeout(() => link.remove(), 300);
	  });
	};
  
	link.click();
  };

export default slice.reducer;
