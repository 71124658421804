// @ts-nocheck 
import { Suspense, LazyExoticComponent, ComponentType } from 'react';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import Loader from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

interface LoaderProps extends LinearProgressProps {
    [key: string]: any;
 }

const Loadable = <P extends LoaderProps>(Component: LazyExoticComponent<ComponentType<P>> | ComponentType<P>) => (props: P) => 
(
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;