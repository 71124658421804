import { DeviceInfo, ProfileType } from "../../types/global";

export interface UserStore {
    status: Status | null;
    error: any;
    user: User | null;
    twoFactorCode: string | null;
    backupKey: string | null;
}

export interface Credentials {
    username: string | null;
    clientId: string | null;
    token: string | null;
    profileType: string | null;
}

export interface Status {
    loggedIn: boolean;
}

export interface SignInPayload {
    username: string;
    password: string;
    deviceInfo: DeviceInfo;
    emailOtpCode?: string;
}

export interface SignUpPayload {
    username: string;
    password: string;
    email: string;
    type: ProfileType;
    deviceInfo: DeviceInfo;
    politicalExposed?: boolean;
    usResident?: boolean;
    recaptchaToken?: string;
    emailOtpCode?: string;
}

export interface ForgotPasswordPayload {
    email: string;
    password?: string;
    recaptchaToken?: string;
    emailOtpCode?: string;
}

export interface VerifyPhonePayload {
    phoneNumber: string;
    countryCode: string;
    recaptchaToken?: string;
    smsCode?: string;
}

export interface User {
    address?: string;
    addressCountry?: string;
    birthDate?: string;
    city?: string;
    clientId?: string;
    companyAddress?: string;
    companyCity?: string;
    companyCountry?: string;
    companyName?: string;
    companyPostalCode?: string;
    companyRegNumber?: string;
    country?: string;
    createdDate?: string;
    email?: string;
    emailConfirmed?: boolean;
    firstName?: string;
    lastName?: string;
    id?: number;
    kyc?: any[];
    kycTier?: number;
    mobileNumber?: string;
    mobileNumberConfirmed?: boolean;
    onboardingStep?: OnboardingStep;
    postalCode?: string;
    preferredDocType?: string;
    preferredLanguage?: string;
    status?: string;
    tag?: string;
    type?: ProfileType;
    occupationCode?: string;
    projectedDepositCode?: string;
    sourceOfIncomeCode?: string;
    monthlyIncomeCode?: string;
    detained?: boolean;
    twoFactorAuthEnabled?: boolean;
}

export enum OnboardingStep {
    TWOFA = 'TWOFA',
    QUESTIONARY = 'QUESTIONARY',
    ADDRESS = 'ADDRESS',
    DOCUMENT = 'DOCUMENT',
    ITA_DETAILS = 'ITA_DETAILS',
    NONE = 'NONE',
    KYC = 'KYC',
    VERIFYING = 'VERIFYING',
    FINAL_REJECTED_KYB = 'FINAL_REJECTED_KYB',
    FINAL_REJECTED_KYC = 'FINAL_REJECTED_KYC',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    BASIC_INFORMATION = "BASIC_INFORMATION",
    BUSINESS_INFORMATION = "BUSINESS_INFORMATION",
    REPRESENTERS = "REPRESENTERS",
    SHAREHOLDERS = "SHAREHOLDERS",
    ORDERS = "ORDERS",
    HISTORY = "HISTORY",
    MERCHANT_DETAILS = "MERCHANT_DETAILS",
    BANK_ACCOUNT_DETAILS = "BANK_ACCOUNT_DETAILS",
    DOCUMENTS = "DOCUMENTS",
    SUBMIT_KYB = "SUBMIT_KYB",
    DOWNLOAD_APP = "DOWNLOAD_APP",
}