import FormTitle from "@components/form/FormTitle";
import { Account, PaymentMethod } from "@features/accounts/accountsTypes";
import { DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { Form, Formik } from "formik";
import FormTextField from "@components/form/FormTextField";
import LoadingButton from "@components/LoadingButton";
import { isEmpty } from "lodash";
import { getCurrencySign } from "@utils/currency";
import FormPaymentTypeRadio from "@components/form/FormPaymentTypeRadio";
import useFeeDebounce from "@hooks/useFeeDebounce";
import { ExternalPaymentType } from "@features/beneficiary/beneficiaryTypes";
import { roundAny, toNumber } from "@utils/round";
import bigDecimal from "js-big-decimal";
import { NEW_LINE_REGEX } from "@hooks/useValidations";
import FormAmountTextField from "@components/form/FormAmountTextField";
import { numericFormatter } from "react-number-format";

interface Props {
    onClose: () => void;
    onBack: () => void;
    onNext: (payload: any) => void;
    amountPayload: any | null;
    isGbpPayment: boolean;
    account: Account;
    paymentProcessList: any[];
}

const SendMoneyAmountDetails = ({ onClose, onBack, onNext, isGbpPayment, amountPayload, account, paymentProcessList }: Props) => {
    const { t } = useTranslation();

    const [amount, setAmount] = useState<string>(amountPayload?.amount ?? '');
    const [nextBlocked, setNextBlocked] = useState<boolean>(true);
    const { fee, callGetFee, feeLoading } = useFeeDebounce(0);

    // const paymentProcessList = useMemo(() => findPaymentTypes(account)
    //     .filter(p => supportedPaymentMethods.includes(p.processProperties?.paymentType)) || [], [account]);
    const isStandardEnabled = Boolean(paymentProcessList.find(p => p.processProperties.paymentType === (isGbpPayment ? PaymentMethod.UK_CHAPS : PaymentMethod.SEPA) && p.maintenance === false));
    const isInstantEnabled = Boolean(paymentProcessList.find(p => p.processProperties.paymentType === (isGbpPayment ? PaymentMethod.UK_FPS : PaymentMethod.SEPA_INSTANT) && p.maintenance === false));
    const isBothEnabled = isStandardEnabled && isInstantEnabled;

    const [paymentType, setPaymentType] = useState<ExternalPaymentType>(amountPayload?.paymentType ?? isStandardEnabled ? ExternalPaymentType.STANDARD : ExternalPaymentType.INSTANT);
    const [selectedProcess, setSelectedProcess] = useState<any>(null);

    useEffect(() => {
        if (!paymentType) { return; }
        let proc;
        setNextBlocked(true);
        if (paymentType === ExternalPaymentType.STANDARD) {
            proc = paymentProcessList.find(p => p.processProperties.paymentType === (isGbpPayment ? PaymentMethod.UK_CHAPS : PaymentMethod.SEPA));
        } else {
            proc = paymentProcessList.find(p => p.processProperties.paymentType === (isGbpPayment ? PaymentMethod.UK_FPS : PaymentMethod.SEPA_INSTANT));
        }
        setSelectedProcess(proc);
        setTimeout(() => {
            setNextBlocked(false);
        }, 500);
        if (amount && bigDecimal.compareTo(amount, 0) > 0) {
            const parsed = toNumber(amount);
            const newAmount = roundAny(parsed, 2);
            callGetFee(account.accountId, proc?.proc, newAmount);
        }
    }, [account.accountId, amount, callGetFee, isGbpPayment, paymentProcessList, paymentType])

    const youPay = bigDecimal.add(amount, fee);

    const initialValues = {
        amount: amountPayload?.amount ?? '',
        reference: amountPayload?.reference ?? '',
    };

    const validationScheme = Yup.object({
        amount: Yup.number().typeError(t('form.validator.required')).required(t('form.validator.required')).nullable()
            .moreThan(0, t('form.validator.moreThanZero'))
            .test('amount', t('form.validator.insufficientFunds'),
                () => bigDecimal.compareTo(youPay, account?.availableBalance ?? 0) <= 0),
        reference: Yup.string().trim()
            .required(t('form.validator.required'))
            .matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
            .test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
            .min(6, t('form.validator.minChars', { field: t('fields.reference'), chars: '6' }))
            .max(140, t('form.validator.maxAllowChars', { chars: 140 }))
            .test('reference', t('form.validator.minChars', { field: t('fields.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true)
    });

    const submit = (formData: any, formikProps: any) => {
        const { setSubmitting } = formikProps;
        const { reference } = formData;

        setSubmitting(true);
        onNext({
            amount,
            reference,
            process: selectedProcess,
            paymentType: paymentType,
            fee,
        })
        setSubmitting(false);
    };


    const handleAmountChange = (_amount: string, setFieldValue: any, setFieldTouched: any) => {
        setNextBlocked(true);
        _amount = _amount.replaceAll(',', '');
        setAmount(_amount)
        setFieldValue('amount', _amount);
        setTimeout(() => setFieldTouched('amount', true));
        setTimeout(() => {
            setNextBlocked(false);
        }, 500);
        const parsed = toNumber(_amount);
        const newAmount = roundAny(parsed, 2);
        callGetFee(account.accountId, selectedProcess?.proc, newAmount);
    };

    const formattedFee = useMemo(() => {
        return numericFormatter(fee.toString(), {
            displayType: 'text',
            decimalScale: 2,
            fixedDecimalScale: true,
            thousandsGroupStyle: 'thousand',
            thousandSeparator: true,
            valueIsNumericString: true,
            prefix: `${getCurrencySign(account.currency)}`
        });
    }, [account.currency, fee])

    return (
        <>
            <DialogTitle>
                <FormTitle
                    title={t('titles.transferAmountDetails')}
                    onClose={onClose}
                    onBack={onBack}
                />
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={submit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors, setFieldValue, setFieldTouched }) => (
                        <Form>
                            <Grid container gap={2}>
                                <Grid item xs={12} mt={1}>
                                    <FormAmountTextField
                                        name="amount"
                                        onChange={value => handleAmountChange(value, setFieldValue, setFieldTouched)}
                                        value={amount}
                                        label={t('fields.amount')}
                                        currencySign={getCurrencySign(account.currency)}

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name="reference"
                                        fullWidth
                                        label={t('fields.reference')}
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                {isBothEnabled && <Grid item xs={12}>
                                    <FormPaymentTypeRadio
                                        name='paymentMethod'
                                        label={t('fields.paymentMethod')}
                                        onChange={(type: ExternalPaymentType) => setPaymentType(type)}
                                        value={paymentType}
                                    />
                                </Grid>}
                                {fee > 0 && <Grid item xs={12}>
                                    <Typography variant="subtitle1" color={'text.secondary'}>
                                        {t('texts.feeNote', { fee: formattedFee })}
                                    </Typography>
                                </Grid>}

                                <Grid item xs={12} mt={2}>
                                    <LoadingButton
                                        disabled={isSubmitting || !isEmpty(errors) || feeLoading || nextBlocked}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting || feeLoading}
                                    >
                                        {t('buttons.continue')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent >
        </>
    )

};

export default SendMoneyAmountDetails;
