import { ProductTextType } from "@features/translate/translateSlice";

export interface MenuStore {
    drawerOpen: boolean;
    pathElements: PathElementType[]
}


export interface PathElementType {
    key: PathParamEnum,
    pathValue: number | string;
    name?: string,
    titles?: ProductTextType[],
}


export enum PathParamEnum {
    STORE_ID = 'storeId',
    EMPLOYEE_ID = 'empId',
    PRODUCT_ID = 'productId',
    INVOICE_ID = 'invoiceId',
};
