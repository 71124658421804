import useCustomBreakpoints from '@hooks/useCustomBreakpoints';
import { Box, Grow, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { MenuItemType } from './SidebarMenuItem';

interface Props {
    list: MenuItemType[],
    drawerIsOpen?: boolean
}
const SideBarMenuList = ({ drawerIsOpen, list }: Props) => {

    const { isMobile } = useCustomBreakpoints();
    const btnMargin = 1;
    const location = useLocation();
    const path = location?.pathname;

    return (<MenuList disablePadding
        sx={{
            mx: btnMargin,
            borderRadius: '10px',
            flex: 1,
        }}>
        {
            list.map((p) => {
                const active = path.startsWith(p.to);
                return (<MenuItem
                    component={NavLink} to={p.to}
                    sx={{
                        paddingY: isMobile ? 1 : 1.5,
                        paddingX: 1.5,
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '10px',
                        minHeight: '36px',
                        '&.Mui-selected': {
                            '& .MuiSvgIcon-root': { opacity: 1 },
                        },
                    }}
                    key={p.to}
                    selected={active}
                >

                    <Tooltip disableFocusListener={drawerIsOpen}
                        disableHoverListener={drawerIsOpen}
                        title={p.title}
                        placement="right">
                        <Box sx={{ display: 'flex' }}>{p.icon}</Box>
                    </Tooltip>
                    <Grow in={drawerIsOpen} >
                        <Typography
                            sx={theme => ({
                                color: active ? theme.palette.text.primary : `${theme.palette.text.primary}B3`,
                                display: 'flex',
                                flex: drawerIsOpen ? 1 : 0,
                                paddingLeft: drawerIsOpen ? 2 : 0
                            })}>{drawerIsOpen ? p.title : ''}</Typography>

                    </Grow>
                </MenuItem>)
            })
        }

    </MenuList>);
}

export default SideBarMenuList;
