import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConstructionIcon from '@mui/icons-material/Construction';

const MaintenanceChip = () => {
    const { t } = useTranslation();

    return <Chip size="small" label={t('chips.maintenance')} color='primary' icon={<ConstructionIcon />} variant="outlined" />;
}

export default MaintenanceChip;