import { startCase } from 'lodash';

/*
Changes values like PENDING_EXTERNALLY to Pending externally*/


export const normalizeString = (value: string) => {

    const v = value ? value.toString().toUpperCase().replaceAll('-', ' ').replaceAll('_', ' ') : '';
    return v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();
};

/* Changes values like PENDING_EXTERNALLY to Pending Externally*/
export const capitalCaseString = (value: string) => {

    const v = value ? value.toString().toUpperCase().replaceAll('_', ' ') : '';
    return startCase(v.toLowerCase());
};
