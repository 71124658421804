export const roundTwo = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

/* rounds down */
export const trimDecimals = (num: string, precision: number) => {
	const valString = num;

	if (valString) {
		const valNum = parseFloat(num ?? '0');
		//round down
		const r = (Math.floor(valNum * Math.pow(10, precision)) / Math.pow(10, precision));
		return r;
	}
	else { return 0; }
};

export const roundAny = (num: number, precision: number) => {
	const numSafe = isNaN(num) ? 0 : num;
	const d = Math.pow(10, precision);
	const r = Math.round((numSafe + Number.EPSILON) * d) / d;
	return r;
};

export const toNumber = (value: any) => {
	const parsed = parseFloat(value);
	return isNaN(parsed) ? 0 : parsed;
};
