import * as React from 'react';
import { SvgIcon } from '@mui/material';

const PaymentsIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.91635 11.5718C7.26783 11.2203 7.83767 11.2203 8.18915 11.5718C8.54062 11.9232 8.54062 12.4931 8.18915 12.8446L6.44564 14.5881C5.19677 15.8369 5.19677 17.8618 6.44564 19.1106C7.69451 20.3595 9.71934 20.3595 10.9682 19.1106L12.7117 17.3671C13.0632 17.0157 13.633 17.0157 13.9845 17.3671C14.336 17.7186 14.336 18.2885 13.9845 18.6399L12.241 20.3834C10.2892 22.3353 7.12466 22.3353 5.17285 20.3834C3.22103 18.4316 3.22103 15.2671 5.17285 13.3153L6.91635 11.5718Z" fill="white" />
            <path d="M16.9519 13.1269C16.6005 13.4784 16.6005 14.0483 16.9519 14.3997C17.3034 14.7512 17.8732 14.7512 18.2247 14.3997L19.9682 12.6563C21.92 10.7045 21.92 7.53995 19.9682 5.58813C18.0163 3.63631 14.8518 3.63631 12.9 5.58813L11.1566 7.33157C10.8051 7.68304 10.8051 8.25289 11.1566 8.60436C11.508 8.95583 12.0779 8.95583 12.4294 8.60436L14.1728 6.86092C15.4217 5.61205 17.4465 5.61205 18.6954 6.86092C19.9442 8.1098 19.9442 10.1346 18.6954 11.3835L16.9519 13.1269Z" fill="white"/>
            <path d="M7.86428 16.277C7.47376 16.6675 7.47376 17.3007 7.86428 17.6912C8.2548 18.0818 8.88797 18.0818 9.27849 17.6912L17.2785 9.69123C17.669 9.30071 17.669 8.66754 17.2785 8.27702C16.888 7.88649 16.2548 7.88649 15.8643 8.27702L7.86428 16.277Z" fill="white" fillOpacity="0.7" />
        </svg>
    </SvgIcon>
);

export default PaymentsIcon;
