import { Card, Grid, Skeleton } from "@mui/material";

const AccountCardSkeleton = () => {


    return (<Card
        sx={theme => ({
            background: theme.palette.secondaryBackground.main,
            padding: '20px',
            borderRadius: '16px',
            height: '180px'
        })}>
        <Grid container gap={2}>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} width={80}/>
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" width={200} height={48} />
            </Grid>
            <Grid item xs={12} container gap={2}>
                <Skeleton animation="wave" variant="rounded" width={96} height={40} />
                <Skeleton animation="wave" variant="rounded" width={135} height={40} />
            </Grid>
        </Grid>
    </Card>)
}

export default AccountCardSkeleton;