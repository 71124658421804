import { Account } from "@features/accounts/accountsTypes";
import { Alert, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from "react-copy-to-clipboard";
import FormTitle from "@components/form/FormTitle";

interface Props {
    account: Account;
    onBack: () => void;
    onClose: () => void;
}

const AddMoneyViaBankTranfer = ({ account, onBack, onClose }: Props) => {
    const { t } = useTranslation();
    const isGbp = account.currency === 'GBP';
    const isReferenceNeeded = account.type === 'WALLET';

    return (<>
        <DialogTitle>
            <FormTitle
                title={t('titles.bankTransfer')}
                onClose={onClose}
                onBack={onBack}
            />
        </DialogTitle>
        <DialogContent>
            <Grid container gap={2}>
                <Grid item xs={12}>
                    <Alert severity="info">{t('texts.depositFiatInfo', { ccy: account.currency })}</Alert>
                </Grid>
                <Grid item container gap={2}>
                    <Line
                        label={t('fields.nameOnAccount')}
                        value={account.recipient}
                    />
                    <>
                        <Line
                            label={t('fields.iban')}
                            value={account.iban}
                        />
                    </>
                    {isGbp && <>
                        <Line
                            label={t('fields.accountNumber')}
                            value={account.ukAccountNumber}
                        />
                        <Line
                            label={t('fields.sortCode')}
                            value={account.ukSortCode}
                        />
                    </>}
                    <Line
                        label={t('fields.bic')}
                        value={account.bicCode}
                    />
                    <Line
                        label={t('fields.bankName')}
                        value={account.bankName}
                    />
                    <Line
                        label={t('fields.address')}
                        value={account.bankAddress}
                    />
                </Grid>
                {isReferenceNeeded && <><Line
                    label={t('fields.referenceNumber')}
                    value={account.walletId}
                />
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <Typography variant="subtitle1" fontWeight={500}>{t('texts.depositFiatWarningTitle')}</Typography>
                            <Typography variant="subtitle2">{t('texts.depositFiatWarningDesc')}</Typography>
                        </Alert>

                    </Grid></>}
            </Grid>
        </DialogContent>
    </>
    );
}


interface LineProps {
    label: string;
    value: string;
}

const Line = ({ label, value }: LineProps) => {
    return (<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs={10}>
            <Typography variant="body1">{value}</Typography>
            <Typography variant="subtitle1" color='text.secondary'>{label}</Typography>
        </Grid>
        <CopyToClipboard text={value}>
            <IconButton>
                <ContentCopyIcon />
            </IconButton>
        </CopyToClipboard >
    </Grid>);
};

export default AddMoneyViaBankTranfer;