import FormTitle from "@components/form/FormTitle";
import FullNameAvatar from "@components/FullNameAvatar";
import SearchInput from "@components/SearchInput";
import { Contact } from "@features/beneficiary/beneficiaryTypes";
import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useSelector } from "@store/store";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectArrowIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
    onBack: () => void;
    onClose: () => void;
    onChoose: (recipient: Contact | null) => void;
    recipientType: 'LOCAL' | 'GBP' | 'EUR';
}


const SendMoneyChooseRecipient = ({ onBack, onClose, recipientType, onChoose }: Props) => {
    const { t } = useTranslation();
    const { eurList, gbpList, localList } = useSelector((state) => state.contacts);

    const [search, setSearch] = useState<string | null>(null);

    const recipients = useMemo(() => {
        switch (recipientType) {
            case 'EUR': return eurList;
            case 'GBP': return gbpList;
            case 'LOCAL': return localList;
        }
    }, [eurList, gbpList, localList, recipientType])


    const filteredContacts = useMemo(() => {
        return search ? recipients.filter(recipient => recipient.name.includes(search)) : recipients
    }, [recipients, search]);


    return (<>
        <DialogTitle>
            <FormTitle
                onBack={onBack}
                title={t('titles.selectRecipient')}
                onClose={onClose}
            />
            <SearchInput onSearch={setSearch} placeholder={t('fields.searchRecipient')}
                fullWidth sx={{ marginTop: '0.5em' }} />
        </DialogTitle>
        <DialogContent>
            <Grid container gap={2}>
                <Grid item xs={12}>
                    {filteredContacts.length > 0 ?
                        <Grid container gap={1}>
                            {filteredContacts.map(contact => <ContactLine contact={contact} onClick={() => onChoose(contact)} recipientType={recipientType} key={contact.beneficiaryAccountId} />)}
                        </Grid> : <Grid container justifyContent='center' mb={2} mt={2} >
                            <Typography variant="body1" fontWeight={600}>{search ? t('texts.recipientNotFound') : t('texts.recipientNotExist')}</Typography>
                        </Grid>}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '20px 24px' }}>
            <Button
                fullWidth
                onClick={() => onChoose(null)}
                variant="contained"
            >
                {t('buttons.newRecipient')}
            </Button>
        </DialogActions>
    </>);
}


interface ContactLineProps {
    contact: Contact;
    onClick: () => void;
    recipientType: 'LOCAL' | 'GBP' | 'EUR';
}

const ContactLine = ({ contact, onClick, recipientType }: ContactLineProps) => {

    const secondLine = useMemo(() => {
        switch (recipientType) {
            case "LOCAL":
                return contact.clientId;
            case "GBP":
                return `${contact.accountNumber}, ${contact.sortCode}`;
            case "EUR":
                return contact.account;
        }
    }, [contact.account, contact.accountNumber, contact.clientId, contact.sortCode, recipientType])

    return (
        <Grid container justifyContent='space-between' onClick={onClick} alignItems='center'
            sx={theme => ({
                background: theme.palette.secondaryBackground.light,
                padding: { md: '10px 20px', xs: '10px' },
                borderRadius: '16px',
                cursor: 'pointer'
            })}
        >
            <Grid item xs={3} md={2}>
                <FullNameAvatar fullName={contact.name} />
            </Grid>
            <Grid item xs={7} md={8}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={400} color='text.secondary'>{contact.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{secondLine}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={2} display='flex' justifyContent='flex-end'>
                <IconButton>
                    <SelectArrowIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default SendMoneyChooseRecipient;