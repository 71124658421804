import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { CreateUserStorePayload, MerchantStore, MerchantStoreState, SaveStoreWorkingHoursPayload, StoreWorkingHours, UpdateUserStorePayload } from './types';

const initialState: MerchantStoreState = {
	list: [],
	failed: false,
	loading: false,
};

const statusSlice = createSlice({
	name: 'merchantStore',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<MerchantStore[]>) {
			state.list = action.payload;
		},
		setError(state, action: PayloadAction<boolean>) {
			state.failed = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	}
});

export const { setError, setList, setLoading } = statusSlice.actions;


export const getMerchantStores = () => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			dispatch(setError(false));
			const response = await request.get('/api-defi-network/store/list');
			const data = response?.data || [];
			dispatch(setList(data));
			dispatch(setLoading(false));
		}
		catch (e) {
			dispatch(setError(true));
		}
		dispatch(setLoading(false));
	};
};



export const createStore = async (payload: CreateUserStorePayload) => {
	const response = await request.post('/api-defi-network/store', payload);
	return response?.data;
};

export const updateStore = async (payload: UpdateUserStorePayload) => {
	const response = await request.patch('/api-defi-network/store', payload);
	return response?.data;
};

export const getStore = async (id: string): Promise<MerchantStore> => {
	const response = await request.get(`/api-defi-network/store/${id}`);
	return response?.data;
};

export const getWorkingHours = async (id: string): Promise<StoreWorkingHours[]> => {
	const response = await request.get(`/api-defi-network/store-shift/${id}`);
	return response?.data;
};


export const saveWorkingHours = async (payload: SaveStoreWorkingHoursPayload): Promise<StoreWorkingHours[]> => {
	const response = await request.put('/api-defi-network/store-shift', payload);
	return response?.data;
};


export default statusSlice.reducer;
