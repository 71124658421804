import { ReactElement } from "react";

export interface DeviceInfo {
    browser: string;
    platform: string;
    hash: string;
};

export enum ProfileType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
    EMPLOYEE = 'EMPLOYEE'
}


export type GuardProps = {
    children: ReactElement | null;
};



export enum Path {
    BASE_PATH = '/',
    DASHBOARD_PATH = '/dashboard',
    ONBOARDING_PATH = '/onboarding',
    LOGIN = '/sign-in'
}

export enum OnboardingPath {
    TWOFA = '/onboarding/two-factor',
    QUESTIONARY = '/onboarding/questionary',
    ADDRESS = '/onboarding/address',
    DOCUMENT = '/onboarding/document',
    ITA_DETAILS = '/onboarding/details',
    KYC = '/onboarding/kyc',
    VERIFYING = '/onboarding/verifying',
    FINAL_REJECTED_KYC = '/onboarding/kyc/rejected',
    FINAL_REJECTED_KYB = '/onboarding/kyb/rejected',
    WAITING_FOR_PAYMENT = '/onboarding/invoice',
    KYB_BASIC_INFORMATION = '/onboarding/kyb/basic-information',
    KYB_BUSINESS_INFORMATION = '/onboarding/kyb/business-information',
    KYB_REPRESENTERS = '/onboarding/kyb/representers',
    KYB_SHAREHOLDERS = '/onboarding/kyb/shareholders',
    KYB_ORDERS = '/onboarding/kyb/orders',
    KYB_HISTORY = '/onboarding/kyb/history',
    KYB_MERCHANT_DETAILS = '/onboarding/kyb/merchant-details',
    KYB_BANK_ACCOUNT_DETAILS = '/onboarding/kyb/bank-account-details',
    KYB_DOCUMENTS = '/onboarding/kyb/documents',
    KYB_SUBMIT = '/onboarding/kyb/submit',
    DOWNLOAD_APP = '/onboarding/download-app',
}

export enum LoginPath {
    SIGN_IN = '/sign-in',
    SIGN_UP = '/sign-up',
    FORGOT_PASSWORD = '/forgot-password',
}

export enum StoresPath {
    DETAILS = '/details',
}

export enum DashboardPath {
    HOME = '/dashboard/home',
    CARDS = '/dashboard/cards',
    MY_STORES = '/dashboard/my-stores',
    EMPLOYEES = '/dashboard/employees',
    TRANSFERS = '/dashboard/transfers',
    ORDERS = '/dashboard/orders',
    SETTINGS = '/dashboard/settings',
    HELP = '/dashboard/help', 
    FORBIDDEN ='/dashboard/forbidden'
}



export enum StorePath {
    STORES = 'stores',
    PRODUCTS = 'products',
    SETTINGS = 'settings',
    EMPLOYEES = 'employees',
    WORKING_HOURS = 'working-hours',
    PRODUCT_CREATE = 'product-create',
    ALL_PRODUCTS = 'all-products',
    ALL_CATEGORIES = 'all-categories',
    ALL_STORES = 'all-stores',
}

export enum EmployeesPath {
    CREATE_EMPLOYEE = 'new',
}

export enum CardsPath {
    CREATE_CARD = 'create',
}

export enum LegalPath {
    PRIVACY_POLICY = 'privacy-policy',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
}

export enum InvoicesPath {
    CREATE_INVOICE = 'new',
}

//list fixed path element translations
export const breadcrumbNameArray = [
    {
        pathElement: 'dashboard',
        displayName: '',
        show: false,
    },
    {
        pathElement: 'home',
        displayName: 'menu.home',
        show: true,
    },
    {
        pathElement: 'cards',
        displayName: 'menu.cards',
        show: true,
    },
    {
        pathElement: 'my-stores',
        displayName: 'menu.stores',
        show: true,
    },
    {
        pathElement: 'employees',
        displayName: 'menu.employees',
        show: true,
    },
    {
        pathElement: 'transfers',
        displayName: 'menu.transfers',
        show: true,
    }, 
    {
        pathElement: 'orders',
        displayName: 'menu.orders',
        show: true,
    },
    {
        pathElement: 'settings',
        displayName: 'menu.settings',
        show: true,
    },
    {
        pathElement: 'help',
        displayName: 'menu.help',
        show: true,
    },
    {
        pathElement: 'forbidden',
        displayName: 'fields.forbidden',
        show: false,
    },
    {
        pathElement: StorePath.PRODUCT_CREATE,
        displayName: 'fields.newProduct',
        show: true,
    },
    {
        pathElement: StorePath.WORKING_HOURS,
        displayName: 'fields.workinghours',
        show: true,
    },
    {
        pathElement: StorePath.SETTINGS,
        displayName: 'fields.settings',
        show: true,
    },
    {
        pathElement: StorePath.PRODUCTS,
        displayName: '',
        show: false,
    },
    {
        pathElement: StorePath.ALL_STORES,
        displayName: 'fields.allStores',
        show: false,
    },
    {
        pathElement: StorePath.ALL_PRODUCTS,
        displayName: 'fields.allProducts',
        show: false,
    },
    {
        pathElement: StorePath.ALL_CATEGORIES,
        displayName: 'fields.allCategories',
        show: false,
    },
    {
        pathElement: CardsPath.CREATE_CARD,
        displayName: 'fields.createCard',
        show: true,
    },
    {
        pathElement: InvoicesPath.CREATE_INVOICE,
        displayName: 'fields.createOrder',
        show: true,
    },
    {
        pathElement: LegalPath.PRIVACY_POLICY,
        displayName: 'titles.privacyPolicy',
        show: true,
    },
    {
        pathElement: LegalPath.TERMS_AND_CONDITIONS,
        displayName: 'titles.termsAndConditions',
        show: true,
    },
    
]
