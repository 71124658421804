import * as React from 'react';
import { SvgIcon } from '@mui/material';

const TransferBetweenAccountsIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.694 8.879l4.098-3.144a1 1 0 00-1.217-1.587l-5.736 4.4a1.667 1.667 0 000 2.645l5.736 4.4a1 1 0 101.217-1.586l-4.077-3.128h17.387a1 1 0 100-2H7.694zM24.49 22.98l-4.282 3.285a1 1 0 101.217 1.587l5.736-4.4c.87-.667.87-1.978 0-2.645l-5.736-4.4a1 1 0 10-1.217 1.587l3.894 2.986H6.898a1 1 0 100 2H24.49z"
                fill="inherit"
            />
        </svg>

    </SvgIcon>
);

export default TransferBetweenAccountsIcon;
