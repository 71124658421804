import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const TabsStyled = styled(Tabs)({
	'& .MuiTabs-indicator': {
		display: 'none',
		borderRadius: 0,
	},
	'&.MuiTabs-root': {
		minHeight: 'unset'
	},
	'&.MuiTabs-root .MuiTabs-flexContainer': {
		justifyContent: 'start'
	},
});

export default TabsStyled;
