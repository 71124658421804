import { Avatar, CircularProgress, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormTitle from "@components/form/FormTitle";
import { useEffect, useState } from "react";
import { getCryptoTopUpAssetList } from "@features/accounts/accountsSlice";
import { DepositAsset } from "@features/accounts/accountsTypes";
import SelectArrowIcon from '@mui/icons-material/ArrowForwardIos';
import AddMoneyCryptoTopUpAssetDetails from "./AddMoneyCryptoTopUpAssetDetails";

interface Props {
    onBack: () => void;
    onClose: () => void;
}

const AddMoneyCryptoTopUp = ({ onBack, onClose }: Props) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(0);
    const [assets, setAssets] = useState<DepositAsset[]>();
    const [selectedAsset, setSelectedAsset] = useState<DepositAsset | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const get = async () => {
            try {
                const response = await getCryptoTopUpAssetList();
                setAssets(response);
            } catch (e) {
                setAssets([]);
            } finally {
                setLoading(false);
            }
        }

        get();
    }, [])

    const handleAssetSelect = (asset: DepositAsset) => {
        setSelectedAsset(asset);
        setStep(1);
    }

    return (<>
        <DialogTitle>
            <FormTitle
                title={step === 0 ? t('titles.selectAsset') : t('titles.cryptoTopUp')}
                onClose={onClose}
                onBack={() => step === 0 ? onBack() : setStep(0)}
            />
        </DialogTitle>
        <DialogContent>
            {step === 0 && <Grid container gap={2}>
                {loading ? <CircularProgress /> : <>
                    {assets?.length === 0 ? <Typography variant="body1" fontWeight={600}>
                        {t('texts.cryptoTopUpAssetsNotFound')}
                    </Typography> : <>
                        {assets?.map((asset: DepositAsset) => <AssetLine asset={asset} onSelect={() => handleAssetSelect(asset)} />)}
                    </>}
                </>}
            </Grid>}
            {step === 1 && selectedAsset && <AddMoneyCryptoTopUpAssetDetails asset={selectedAsset} />}
        </DialogContent>
    </>
    );
}


interface AssetLineProps {
    asset: DepositAsset;
    onSelect: () => void;
}

const AssetLine = ({ asset, onSelect }: AssetLineProps) => {
    return (
        <Grid container justifyContent='space-between' onClick={onSelect} alignItems='center'
            sx={theme => ({
                background: theme.palette.secondaryBackground.light,
                padding: '10px 20px',
                borderRadius: '16px',
                cursor: 'pointer'
            })}
        >
            <Grid item xs={2}>
                <Avatar src={asset.currencyLogo} />
            </Grid>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={400} color='text.secondary'>{asset.currencyName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight={500}>{asset.currency}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={2} display='flex' justifyContent='flex-end'>
                <IconButton>
                    <SelectArrowIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default AddMoneyCryptoTopUp;