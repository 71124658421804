import * as React from 'react';
import { SvgIcon } from '@mui/material';

const OutgoingTransferIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                y={0.936035}
                width={40}
                height={40}
                rx={20}
                fill="#31ADF2"
                fillOpacity={0.1}
            />
            <path
                d="M26.263 20.404a.9.9 0 001.247-1.298l-6.193-5.956a1.9 1.9 0 00-2.634 0l-6.194 5.956a.9.9 0 001.248 1.297l5.363-5.157V28.35a.9.9 0 001.8 0V15.246l5.363 5.158z"
                fill="#31ADF2"
            />
        </svg>

    </SvgIcon>
);

export default OutgoingTransferIcon;
