import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { PaginateParams, SortDirectionEnum } from '../../types/pagination';
import { CreateMerchantTaxPayload, MerchantTax, MerchantTaxStore } from './merchantTaxTypes';

export const initialMerchantTaxPagination: PaginateParams = {
	search: '',
	skip: 0,
	take: 10,
	orderBy: 'createdDate',
	sort: SortDirectionEnum.DESC,
}

const initialState: MerchantTaxStore = {
	list: [],
	loading: false,
	count: 0,
	pagination: initialMerchantTaxPagination,
};

const slice = createSlice({
	name: 'merchantTax',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<MerchantTax[]>) {
			state.list = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setCount(state, action: PayloadAction<number>) {
			state.count = action.payload;
		},
		setPagination(state, action: PayloadAction<PaginateParams>) {
			state.pagination = action.payload;
		},
	}
});

export const { setList, setLoading, setCount, setPagination } = slice.actions;


export const getMerchantTaxesList = (params: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			dispatch(setPagination(params));
			const response = await request.get('/api-defi-network/merchant-tax', { params });

			const data = response?.data || null;
			dispatch(setList(data.list));
			dispatch(setCount(data.count));
		}
		catch (e) {
			dispatch(setList([]));
			dispatch(setCount(0));
		} finally {
			dispatch(setLoading(false));
		}
	};
};


export const createMerchantTax = async (payload: CreateMerchantTaxPayload) => {
	const response = await request.post('/api-defi-network/merchant-tax', payload);
	return response?.data;
}

export const getMerchantTaxOptions = async () => {
	const response = await request.get('/api-defi-network/merchant-tax');
	const optionsValueRate = response.data?.list.map((p: MerchantTax) => ({
		value: p.taxRate,
		label: `${p.taxRate * 100}% ${p.taxCode}`
	})) || [];

	const optionsValueCode = response.data?.list.map((p: MerchantTax) => ({
		value: p.taxCode,
		label: `${p.taxRate * 100}% ${p.taxCode}`
	})) || [];

	const optionsIdRate = response.data?.list.map((p: MerchantTax) => ({
		value: `${p.id}`,
		label: `${p.taxRate * 100}% ${p.taxCode}`
	})) || [];

	return { optionsIdRate, optionsValueRate, optionsValueCode };
}


export default slice.reducer;
