import { AppBar } from '@mui/material';
import ToolBarLayout from './ToolBarLayout';


const AppBarLayout = () => {
    return (

        <AppBar position="fixed"
            color="inherit"
            elevation={0}>
            <ToolBarLayout />
        </AppBar >
    )

};

export default AppBarLayout;