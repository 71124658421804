import { useMemo, ReactNode } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, Theme } from '@mui/material/styles';
import Palette from './palette';

import componentStyleOverrides from './compStyleOverride';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import Typography from './typography';

interface Props {
    children: ReactNode;
}

export default function ThemeCustomization({ children }: Props) {

    const theme: Theme = useMemo<Theme>(() => Palette(), []);
    const themeTypography: TypographyOptions = useMemo<TypographyOptions>(
        () => Typography(theme, `'Inter', sans-serif`),
        [theme]
    );


    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette: theme.palette,
            mixins: {
                toolbar: {
                    minHeight: '48px',
                    padding: '16px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px'
                    }
                },
            },
            typography: themeTypography,
        }),
        [theme, themeTypography]
    );

    const themes: Theme = createTheme(themeOptions);
    const themeWithOverrides = useMemo(() => componentStyleOverrides(themes), [themes]);


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeWithOverrides}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
