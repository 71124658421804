import { Account } from "@features/accounts/accountsTypes";
import { useMemo, useState } from "react";
import SendMoneySelfBankRecipientDetails from "./SendMoneySelfBankRecipientDetails";
import SendMoneyBankTransferConfirmation from "./SendMoneyBankTransferConfirmation";
import SendMoneyAmountDetails from "./SendMoneyAmountDetails";
import { findSelfPaymentTypes } from "@features/accounts/accountsSlice";
import { supportedPaymentMethods } from "./ChooseSendMoneyType";


interface Props {
    account: Account;
    onBack: () => void;
    onClose: () => void;
}

enum Step {
    RECIPIENT_DETAILS = "RECIPIENT_DETAILS",
    AMOUNT_DETAILS = "AMOUNT_DETAILS",
    TRANSFER_CONFIRMATION = "TRANSFER_CONFIRMATION",
}

const SendSelfBankTransfer = ({ account, onBack, onClose }: Props) => {
    const [step, setStep] = useState<Step>(Step.RECIPIENT_DETAILS);
    const [recipientPayload, setRecipientPayload] = useState<any>(null);
    const [amountPayload, setAmountPayload] = useState<any>(null);

    const isGbp = account.currency === 'GBP';

    const paymentProcessList = useMemo(() => findSelfPaymentTypes(account)
        .filter(p => supportedPaymentMethods.includes(p.processProperties?.paymentType)) || [], [account]);

    const handleNextFromDetails = ($payload: any) => {
        setRecipientPayload($payload);
        setStep(Step.AMOUNT_DETAILS);
    }

    const handleBackFromAccountScreen = () => {
        setStep(Step.RECIPIENT_DETAILS);
    }

    const handleNextFromAmountsetStep = ($payload: any) => {
        setAmountPayload($payload);
        setStep(Step.TRANSFER_CONFIRMATION);
    }

    return (
        <>
                {step === Step.RECIPIENT_DETAILS && <SendMoneySelfBankRecipientDetails
                    isGbpPayment={isGbp}
                    account={account}
                    onBack={onBack}
                    onClose={onClose}
                    onNext={handleNextFromDetails}
                    recipient={recipientPayload}
                />}
                {step === Step.AMOUNT_DETAILS && <SendMoneyAmountDetails
                    amountPayload={amountPayload}
                    account={account}
                    isGbpPayment={isGbp}
                    onBack={handleBackFromAccountScreen}
                    onClose={onClose}
                    onNext={(payload: any) => handleNextFromAmountsetStep(payload)}
                    paymentProcessList={paymentProcessList}
                />}
                {step === Step.TRANSFER_CONFIRMATION && <SendMoneyBankTransferConfirmation
                    onBack={() => setStep(Step.AMOUNT_DETAILS)}
                    onClose={onClose}
                    account={account}
                    amountPayload={amountPayload}
                    recipientPayload={recipientPayload}
                    contact={null}
                />}
        </>
    );
}



export default SendSelfBankTransfer;