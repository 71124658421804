import { Account } from "@features/accounts/accountsTypes";
import { Avatar, Card, Grid, IconButton, Menu, MenuItem, MenuProps, styled, Typography } from "@mui/material";
import { getCurrencySign } from "@utils/currency";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";

interface Props {
    accounts?: Account[];
    onSelect?: (account: Account) => void;
    selectedAccount: Account | null;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: '5px',
        minWidth: 200,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '&:active': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        ...theme.applyStyles('dark', {
            color: theme.palette.grey[300],
        }),
    },
}));


const AccountsSelector = ({ accounts, onSelect, selectedAccount }: Props) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = anchorEl !== null;


    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectMenuItem = (_account: Account) => {
        onSelect && onSelect(_account);
        handleCloseMenu();
    };

    const isSelectable = accounts && accounts.length > 1;

    return (
        <>
            <Card
                sx={theme => ({
                    background: theme.palette.secondaryBackground.light,
                    padding: '10px',
                    borderRadius: '16px',
                    cursor: isSelectable ? 'pointer' : 'default'
                })}
                onClick={(e) => isSelectable && handleOpenMenu(e)}
            >
                <Grid container alignItems='center'>
                    {selectedAccount ? <>
                        <Grid item xs={2}>
                            <Avatar src={selectedAccount.currencyLogo} />
                        </Grid>
                        <Grid item xs={8}>
                            <Grid item xs={12}>
                                <Typography variant="body1" fontWeight={400} color='text.secondary'>{selectedAccount.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" fontWeight={600}>
                                    <NumericFormat
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        valueIsNumericString
                                        thousandsGroupStyle='thousand'
                                        thousandSeparator
                                        prefix={`${getCurrencySign(selectedAccount.currency)} `}
                                        value={selectedAccount.availableBalanceString} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </> : <Grid item xs={10}>
                        <Typography variant="body1" fontWeight={400} color='text.secondary'>{t('texts.noAccountSelected')}</Typography>
                    </Grid>}
                    {isSelectable && <Grid item xs={2} container justifyContent='flex-end'>
                        <IconButton>
                            <ExpandMoreIcon />
                        </IconButton>
                    </Grid>}
                </Grid>

            </Card>
            <StyledMenu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
            >
                {accounts && accounts.map(_account => (
                    <MenuItem onClick={() => handleSelectMenuItem(_account)} key={_account.accountId}>
                        <Grid container alignItems='center' justifyContent='flex-end'>
                            <Grid item>
                                <Grid item xs={12} container justifyContent='flex-end'>
                                    <Typography variant="subtitle1" fontWeight={400}  color='text.secondary'>{_account.name}</Typography>
                                </Grid>
                                <Grid item xs={12} container justifyContent='flex-end'>
                                    <Typography variant="subtitle1" fontWeight={400}>
                                        <NumericFormat
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            valueIsNumericString
                                            thousandsGroupStyle='thousand'
                                            thousandSeparator
                                            prefix={`${getCurrencySign(_account.currency)} `}
                                            value={_account.availableBalanceString} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item ml={1}>
                                <Avatar src={_account.currencyLogo} sx={{ width: 24, height: 24 }} />
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}

            </StyledMenu>
        </>
    );

}

export default AccountsSelector;