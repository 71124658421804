import { Navigate, Outlet } from 'react-router-dom';
import { DashboardPath, StorePath } from '../types/global';
import { lazy } from 'react';
import Loadable from '@components/Loadable';
import { PathParamEnum } from '@features/menu/menuType';


const StoreMenu = Loadable(lazy(() => import('@pages/store/stores/StoreMenu')));
const ProductCreate = Loadable(lazy(() => import('@pages/store/products/ProductCreate')));
const ProductUpdate = Loadable(lazy(() => import('@pages/store/products/ProductUpdate')));
const Products = Loadable(lazy(() => import('@pages/store/products/Products')));
const StoreUpdate = Loadable(lazy(() => import('@pages/store/stores/StoreUpdate')));
const MyStores = Loadable(lazy(() => import('@pages/store/MyStores')));
const WorkingHours = Loadable(lazy(() => import('@pages/store/stores/WorkingHours')));

const StoreMenuRoutes = {
    path: DashboardPath.MY_STORES,
    element: <Outlet />,
    children: [
        {
            path: '',
            skipLazyLoad: true,
            element: <Navigate to={StorePath.ALL_STORES} replace={true} />,
        },
        {

            path: StorePath.ALL_STORES,
            element: <MyStores />,
            children: [
                {
                    path: `:${PathParamEnum.STORE_ID}`,
                    element: <StoreMenu />,
                    children: [
                        {
                            path: '',
                            skipLazyLoad: true,
                            element: <Navigate to={StorePath.PRODUCTS} replace={true} />,
                        },
                        {
                            path: `:${PathParamEnum.PRODUCT_ID}`,
                            element: <ProductUpdate />
                        },
                        {
                            path: StorePath.PRODUCT_CREATE,
                            element: <ProductCreate />
                        },
                        {
                            path: StorePath.PRODUCTS,
                            element: <Products />,
                        },
                        {
                            path: StorePath.SETTINGS,
                            element: <StoreUpdate />,
                        },
                        {
                            path: StorePath.WORKING_HOURS,
                            element: <WorkingHours />,
                        }

                    ]
                }
            ],
        }, 
        {
            path: StorePath.ALL_PRODUCTS,
            element: <MyStores />,  
            children: [
                {
                    path: StorePath.PRODUCT_CREATE,
                    element: <ProductCreate />
                },
            ]
        },
       
        {
            path: StorePath.ALL_CATEGORIES,
            element: <MyStores />,
        },
    ]


};

export default StoreMenuRoutes;
