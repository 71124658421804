import { Account } from "@features/accounts/accountsTypes";
import { Button, Card, Grid, Typography } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIcon from '@mui/icons-material/Add';
import { NumericFormat } from "react-number-format";
import { hasSubprocessWithType } from "@features/accounts/accountsSlice";
import { RootState } from "@store/reducer";
import { useSelector } from "@store/store";
import { useState } from "react";
import { getCurrencySign } from "@utils/currency";
import AddMoneyDialog from "./AddMoneyDialog";
import SendMoneyDialog from "./SendMoneyDialog";
import { useTranslation } from "react-i18next";
import useAllowedActions from "@hooks/useAllowedActions";

interface Props {
    account: Account;
}

const AccountCard = ({ account }: Props) => {
    const { t } = useTranslation();
    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
    const [openSendDialog, setOpenSendDialog] = useState<boolean>(false);

    const { user } = useSelector((state: RootState) => state.user);
    const { canManageFinances } = useAllowedActions();

    const isAvailableBalanceMoreThanZero = account.availableBalance > 0;

    const isSendMoneySubProcessAvailable = () => {
        if (account?.subProcesses) {
            if (hasSubprocessWithType('PAYMENT', account.subProcesses)) return true;
            else if (hasSubprocessWithType('PAYMENT_SELF', account.subProcesses)) return true;
            else if (hasSubprocessWithType('OWN_TRANSFER', account.subProcesses)) return true;
            return hasSubprocessWithType('TRANSFER', account.subProcesses);
        }
        else return false;
    };

    const isSendDisabled = !isAvailableBalanceMoreThanZero || !isSendMoneySubProcessAvailable() || user?.detained || !canManageFinances;


    return (<Card
        sx={theme => ({
            background: theme.palette.secondaryBackground.main,
            padding: '20px',
            borderRadius: '16px',
        })}>
        {openAddDialog && <AddMoneyDialog
            open={openAddDialog}
            onClose={() => setOpenAddDialog(false)}
            account={account}
        />}
        {openSendDialog && <SendMoneyDialog
            open={openSendDialog}
            onClose={() => setOpenSendDialog(false)}
            account={account}
        />}
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '1rem' }} color='text.secondary'>{account.name}</Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
                <Typography variant="h1">
                    <NumericFormat
                        displayType={'text'}
                        decimalScale={2}
                        fixedDecimalScale
                        valueIsNumericString
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        prefix={`${getCurrencySign(account.currency)} `}
                        value={account.availableBalanceString} />
                </Typography>
            </Grid>
            <Grid item xs={12} container gap={2}>
                <Button
                    variant="contained"
                    startIcon={<ArrowUpwardIcon />}
                    disabled={isSendDisabled}
                    sx={{ height: '40px' }}
                    onClick={() => setOpenSendDialog(true)}>
                    {t('buttons.send')}
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{ height: '40px' }}
                    disabled={!canManageFinances}
                    onClick={() => setOpenAddDialog(true)}>
                    {t('buttons.addMoney')}
                </Button>
            </Grid>
        </Grid>
    </Card>)
}

export default AccountCard;