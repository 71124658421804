import { Account } from "@features/accounts/accountsTypes";
import { DialogContent, DialogTitle, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useSelector } from "@store/store";
import { findProcessByType, hasSubprocessWithType } from "@features/accounts/accountsSlice";
import { useEffect, useMemo, useState } from "react";
import { NumericFormat, numericFormatter } from "react-number-format";
import { useTranslation } from "react-i18next";
import { getCurrencySign } from "@utils/currency";
import LoadingButton from "@components/LoadingButton";
import AccountsSelector from "@components/AccountsSelector";
import useFeeDebounce from "@hooks/useFeeDebounce";
import { roundAny, toNumber } from "@utils/round";
import bigDecimal from "js-big-decimal";
import { runOperation } from "@features/operations/operationsSlice";
import { showError, showSuccess } from "@components/Alerts";
import FormTitle from "@components/form/FormTitle";


interface Props {
    account: Account;
    direction: 'FROM' | 'TO';
    onBack: () => void;
    onClose: () => void;
}

const TransferBetweenOwnAccounts = ({ account, onBack, direction, onClose }: Props) => {
    const { t } = useTranslation();
    const { list } = useSelector((state) => state.accounts);
    const [amount, setAmount] = useState<string>();
    const [nextBlocked, setNextBlocked] = useState<boolean>(true);
    // const [amountForFee, setAmountForFee] = useState<number>(0);

    const { fee, callGetFee, feeLoading } = useFeeDebounce(0);

    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
    const totalAmount = bigDecimal.add(amount ?? 0, fee);

    const process = useMemo(() => {
        return findProcessByType(direction === 'TO' ? selectedAccount : account, 'OWN_TRANSFER');
    }, [account, direction, selectedAccount])

    const counterPartyAccounts = useMemo(() => {
        if (direction === 'TO') {
            return list.filter(acc => acc.currency === account.currency
                && acc.accountId !== account.accountId
                && hasSubprocessWithType('OWN_TRANSFER', acc.subProcesses)
                && (acc.availableBalance > 0)
                && findProcessByType(acc, 'OWN_TRANSFER').maintenance === false) || []
        } else if (direction === 'FROM') {
            return list.filter(acc => acc.currency === account.currency
                && acc.accountId !== account.accountId
            ) || []
        }
    }, [account, direction, list]);

    const formattedFee = useMemo(() => {
        return numericFormatter(fee.toString(), {
            displayType: 'text',
            decimalScale: 2,
            fixedDecimalScale: true,
            thousandsGroupStyle: 'thousand',
            thousandSeparator: true,
            valueIsNumericString: true,
            prefix: `${getCurrencySign(account.currency)}`
        });
    }, [account.currency, fee])

    useEffect(() => {
        if (!counterPartyAccounts || counterPartyAccounts.length === 0) return;
        setSelectedAccount(counterPartyAccounts[0])
    }, [counterPartyAccounts]);

    const handleAmountChange = (_amount: string) => {
        setNextBlocked(true);
        _amount = _amount.replaceAll(',', '');
        setAmount(_amount)
        setTimeout(() => {
            setNextBlocked(false);
        }, 500);

        const parsed = toNumber(_amount);
        const newAmount = roundAny(parsed, 2);

        if (direction === 'TO' && selectedAccount) {
            callGetFee(selectedAccount.accountId, process?.proc, newAmount);
        } else if (direction === 'FROM') {
            callGetFee(account.accountId, process?.proc, newAmount);
        }
    };

    const handleAccounSelect = (_account: Account) => {
        setSelectedAccount(_account)
        if (direction === 'TO' && amount) {
            setNextBlocked(true);
            const parsed = toNumber(amount);
            const newAmount = roundAny(parsed, 2);
            setTimeout(() => {
                setNextBlocked(false);
            }, 500);
            callGetFee(_account.accountId, process?.proc, newAmount);
        }
    };

    const handleSend = async () => {
        const payload = {
            amount,
            accountFromId: direction === 'TO' ? selectedAccount?.accountId : account?.accountId,
            accountToId: direction === 'TO' ? account?.accountId : selectedAccount?.accountId,
        }

        try {
            await runOperation(process.proc, payload);
            showSuccess({ text: t('alerts.paymentSubmitted') });
        } catch (error) {
            showError({ error });
        } finally {
            onClose();
        }
    }

    const insufficientFunds = useMemo(() => {
        if (direction === 'TO') {
            return bigDecimal.compareTo(selectedAccount?.availableBalance ?? 0, totalAmount) < 0;
        } else {
            return bigDecimal.compareTo(account?.availableBalance ?? 0, totalAmount) < 0;
        }
    }, [account?.availableBalance, direction, selectedAccount?.availableBalance, totalAmount])

    return (
        <>
            <DialogTitle>
                <FormTitle
                    title={t('titles.ownTransfers')}
                    onClose={onClose}
                    onBack={onBack}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container gap={2}>
                    {direction === 'TO' && <Grid item xs={12}>
                        <AccountsSelector selectedAccount={selectedAccount} onSelect={($acc) => handleAccounSelect($acc)} accounts={counterPartyAccounts} />
                    </Grid>}
                    <Grid item xs={12}>
                        <AccountsSelector selectedAccount={account} />
                    </Grid>
                    {direction === 'FROM' && <Grid item xs={12}>
                        <AccountsSelector selectedAccount={selectedAccount} onSelect={($acc) => handleAccounSelect($acc)} accounts={counterPartyAccounts} />
                    </Grid>}
                    <Grid item xs={12} mt={2}>
                        <NumericFormat
                            name="amount"
                            onChange={event => handleAmountChange(event.target.value)}
                            value={amount}
                            label={t('fields.amount')}
                            allowNegative={false}
                            decimalScale={2}
                            thousandsGroupStyle='thousand'
                            thousandSeparator={true}
                            helperText={insufficientFunds ? t('form.validator.insufficientFunds') : undefined}
                            error={insufficientFunds}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{getCurrencySign(account.currency)}</InputAdornment>,
                            }}
                            customInput={TextField}
                            inputProps={{ autoComplete: 'off' }}
                            displayType={'input'}
                            fullWidth
                        />
                    </Grid>
                    {fee > 0 && <Grid item xs={12}>
                        <Typography variant="subtitle1" color={'text.secondary'}>
                            {t('texts.feeNote', { fee: formattedFee })}
                        </Typography>
                    </Grid>}
                    <LoadingButton
                        key="addMoney"
                        disabled={feeLoading || insufficientFunds || !amount || bigDecimal.compareTo(amount, 0) <= 0 || nextBlocked}
                        fullWidth
                        onClick={handleSend}
                        variant="contained"
                        loading={feeLoading}
                    >
                        {t('buttons.submit')}
                    </LoadingButton>
                </Grid>
            </DialogContent></>
    );
}



export default TransferBetweenOwnAccounts;