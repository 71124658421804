import AuthLayout from '@layout/AuthLayout';
import { Navigate, Outlet } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from '@components/Loadable';
import GuestGuard from '@guards/GuestGuard';
import { LoginPath } from '../types/global';

const Login = Loadable(lazy(() => import('@pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('@pages/auth/ForgotPassword')));
const SignUp = Loadable(lazy(() => import('@pages/auth/SignUp')));

const LoginRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <AuthLayout>
                <Outlet />
            </AuthLayout>
        </GuestGuard>
    ),
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: LoginPath.SIGN_IN,
            element: <Login />
        },
        {
            path: LoginPath.SIGN_UP,
            element: <SignUp />
        },
        {
            path: LoginPath.FORGOT_PASSWORD,
            element: <ForgotPassword />
        },
        //Redirect to login
        {
            path: '*',
            element: <Navigate to='/' replace={true} />
        },
    ]
};

export default LoginRoutes;

