
import { isReadAllowed, isWriteAllowed } from '@features/allowedActions/allowedActionsSlice';
import { MerchantPermissions } from '@features/allowedActions/allowedActionsTypes';
import { useSelector } from '@store/store';

const useAllowedActions = () => {
    const user = useSelector((state) => state.user.user) || undefined;
    const allowedActions = useSelector((state) => state.allowedActions.list) || [];

    const canUpdateStore = isWriteAllowed(MerchantPermissions.STORES, user, allowedActions);
    const canUpdateProducts = isWriteAllowed(MerchantPermissions.PRODUCTS, user, allowedActions);
    const canReadAccounts = isReadAllowed(MerchantPermissions.FINANCES, user, allowedActions);
    const canManageFinances = isWriteAllowed(MerchantPermissions.FINANCES, user, allowedActions);
    const canManageEmployees = isWriteAllowed(MerchantPermissions.EMPLOYEES, user, allowedActions);

    return { canUpdateStore, canUpdateProducts, canReadAccounts, canManageFinances, canManageEmployees };
};

export default useAllowedActions;
