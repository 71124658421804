import * as React from 'react';
import { SvgIcon } from '@mui/material';

const HelpIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.274 8.733a2.1 2.1 0 00-2.336 1.373.9.9 0 01-1.698-.597 3.9 3.9 0 017.58 1.3c0 .899-.414 1.62-.896 2.149-.477.524-1.065.911-1.545 1.175-.373.205-.56.531-.56.803a.9.9 0 01-1.8 0c0-1.076.686-1.937 1.494-2.381.389-.214.788-.488 1.08-.809.288-.316.427-.625.427-.938v-.002a2.1 2.1 0 00-1.745-2.073z"
                fill="white"
            />
            <path
                d="M12 17.17a1 1 0 100 2h.01a1 1 0 100-2H12z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.1 12.987c0-6.02 4.88-10.9 10.9-10.9 6.02 0 10.9 4.88 10.9 10.9 0 6.02-4.88 10.9-10.9 10.9-6.02 0-10.9-4.88-10.9-10.9zm10.9-9.1a9.1 9.1 0 100 18.2 9.1 9.1 0 000-18.2z"
                fill="white"
            />
        </svg>
    </SvgIcon>
);

export default HelpIcon;
