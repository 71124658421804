import { showError, showSuccess } from "@components/Alerts";
import ConfirmationLine from "@components/ConfirmationLine";
import FormTitle from "@components/form/FormTitle";
import LoadingButton from "@components/LoadingButton";
import { Account } from "@features/accounts/accountsTypes";
import { BeneficiaryType, Contact } from "@features/beneficiary/beneficiaryTypes";
import { runOperation } from "@features/operations/operationsSlice";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import { getCurrencySign } from "@utils/currency";
import { alpha3ToAlpha2, getNames } from "i18n-iso-countries";
import bigDecimal from "js-big-decimal";
import { useState } from "react";
import { useTranslation } from "react-i18next";



interface Props {
    account: Account;
    amountPayload: any;
    contact: Contact | null;
    recipientPayload: any | null;
    onBack: () => void;
    onClose: () => void;
}


const SendMoneyBankTransferConfirmation = ({ account, amountPayload, contact, recipientPayload, onBack, onClose }: Props) => {

    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const countries = getNames('en');

    const isGbp = account.currency === 'GBP';
    const beneName = contact ? contact.name : recipientPayload.beneficiary;
    const reference = amountPayload.reference;
    const beneficiaryIdentityNo = contact ? contact.identification === ''
        ? null : contact.identification : recipientPayload.identification === ''
        ? null : recipientPayload.identification;
    const amount = amountPayload.amount;
    const fee = amountPayload.fee;
    const currencySign = getCurrencySign(account.currency);

    const sortCode = contact ? contact.sortCode : recipientPayload.sortCode;
    const accountNumber = contact ? contact.accountNumber : recipientPayload.accountNumber;


    const iban = contact ? contact.account : recipientPayload.iban;
    const bic = contact ? contact.bic : recipientPayload?.ibanData?.bic ?? '';
    const bankCountry = contact ? contact.bankCountry : recipientPayload.ibanData?.country ?? '';
    const bankCountryIso2 = bankCountry !== '' ? alpha3ToAlpha2(bankCountry) : undefined;
    const bankName = contact ? contact.bank : recipientPayload.ibanData?.bank ?? '';
    const bankAddress = contact ? contact.bankAddress : recipientPayload.ibanData?.address ?? '';

    const youPay = bigDecimal.add(amount, fee);

    const handleSubmit = async () => {
        const payload = {
            ccy: account.currency,
            amount: amount,
            details: reference,
            accountId: account.accountId,
            beneficiaryName: beneName,
            isToBusiness: contact ? contact.business : recipientPayload.beneficiaryType === BeneficiaryType.BUSINESS,
            saveToContacts: true,
            beneficiaryIdentityNo: beneficiaryIdentityNo,
            ...(!isGbp ?
                {
                    beneficiaryIban: iban,
                    beneficiaryBicSwift: bic,
                    beneficiaryBankCountry: bankCountry,
                    beneficiaryBankName: bankName,
                    beneficiaryBankAddress: bankAddress,
                } :
                {
                    beneficiarySortCode: contact ? contact.sortCode : recipientPayload.sortCode,
                    beneficiaryAccountNumber: contact ? contact.accountNumber : recipientPayload.accountNumber,
                }
            ),
        }

        try {
            setSubmitting(true);
            await runOperation(amountPayload.process.proc, payload);
            showSuccess({ text: t('alerts.paymentSubmitted') });
        } catch (error) {
            showError({ error });
        } finally {
            setSubmitting(false);
            onClose();
        }
    }


    return (
        <>
            <DialogTitle>
                <FormTitle
                    title={t('titles.paymentConfirmation')}
                    onClose={onClose}
                    onBack={onBack}
                />
            </DialogTitle>
            <DialogContent>
                <Grid container gap={1}>
                    <Grid item xs={12}>
                        <ConfirmationLine
                            label={t('fields.beneficiary')}
                            value={beneName}
                        />
                    </Grid>
                    {beneficiaryIdentityNo && <Grid item xs={12}>
                        <ConfirmationLine
                            label={t('fields.registrationNumber')}
                            value={beneficiaryIdentityNo}
                        />
                    </Grid>}
                    {isGbp ? <>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.accountNumber')}
                                value={accountNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.sortCode')}
                                value={sortCode}
                            />
                        </Grid>
                    </> : <>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.iban')}
                                value={iban}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.bic')}
                                value={bic}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.bankName')}
                                value={bankName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.bankCountry')}
                                value={bankCountryIso2 ? countries[bankCountryIso2] : bankCountry}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ConfirmationLine
                                label={t('fields.bankAddress')}
                                value={bankAddress}
                            />
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                        <ConfirmationLine
                            label={t('fields.reference')}
                            value={reference}
                        />
                    </Grid>
                    <Grid item xs={12} container direction='row' justifyContent='space-between'>
                        <Grid item xs>
                            <ConfirmationLine
                                label={t('fields.amount')}
                                value={amount}
                                amountValue
                                currencySing={currencySign}
                            />
                        </Grid>
                        <Grid item xs>
                            <ConfirmationLine
                                label={t('fields.fee')}
                                value={fee}
                                amountValue
                                currencySing={currencySign}
                            />
                        </Grid>
                        <Grid item xs>
                            <ConfirmationLine
                                label={t('fields.youPay')}
                                value={youPay}
                                amountValue
                                currencySing={currencySign}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <LoadingButton
                            disabled={submitting}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                            loading={submitting}
                        >
                            {t('buttons.submit')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    );
}



export default SendMoneyBankTransferConfirmation;