import * as React from 'react';
import { SvgIcon } from '@mui/material';

const TransferToOtherUserIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="32" height="32" viewBox="0 0 32 32" stroke="inherit" xmlns="http://www.w3.org/2000/svg">
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.453 12.627a5.68 5.68 0 1111.36 0 5.68 5.68 0 01-11.36 0zm5.68-3.68a3.68 3.68 0 100 7.36 3.68 3.68 0 000-7.36z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.887 16.001c0-7.867 6.378-14.245 14.245-14.245C24 1.756 30.378 8.134 30.378 16c0 7.868-6.378 14.246-14.246 14.246-7.867 0-14.245-6.378-14.245-14.246zM16.132 3.756C9.37 3.756 3.887 9.238 3.887 16c0 3.08 1.136 5.893 3.012 8.044 2.31-2.46 5.593-4 9.236-4 3.642 0 6.924 1.538 9.233 3.997a12.198 12.198 0 003.01-8.04c0-6.764-5.483-12.246-12.246-12.246zm7.804 21.683a10.63 10.63 0 00-7.8-3.394 10.63 10.63 0 00-7.804 3.396 12.196 12.196 0 007.8 2.806c2.966 0 5.685-1.054 7.804-2.808z"
        fill="inherit"
      />
        </svg>

    </SvgIcon>
);

export default TransferToOtherUserIcon;
