import { Outlet } from 'react-router-dom';
import AuthGuard from '@guards/AuthGuard';
import OnboardingLayout from '@layout/OnboardingLayout';
import { OnboardingPath } from '../types/global';
import { lazy } from 'react';
import Loadable from '@components/Loadable';

const TwoFaSetup = Loadable(lazy(() => import('@pages/onboarding/twofa/TwoFaSetup')));
const Questionary = Loadable(lazy(() => import('@pages/onboarding/individual/Questionary')));
const Documents = Loadable(lazy(() => import('@pages/onboarding/individual/Documents')));
const ResidencyAddress = Loadable(lazy(() => import('@pages/onboarding/individual/ResidencyAddress')));
const ItalianDetails = Loadable(lazy(() => import('@pages/onboarding/individual/ItalianDetails')));
const KYC = Loadable(lazy(() => import('@pages/onboarding/individual/KYC')));
const InVerification = Loadable(lazy(() => import('@pages/onboarding/statuses/InVerification')));
const FinalRejected = Loadable(lazy(() => import('@pages/onboarding/statuses/FinalRejected')));
const BasicInfo = Loadable(lazy(() => import('@pages/onboarding/business/BasicInfo')));
const BusinessInfo = Loadable(lazy(() => import('@pages/onboarding/business/BusinessInfo')));
const KybRepresenters = Loadable(lazy(() => import('@pages/onboarding/business/KybRepresenters')));
const KybShareholders = Loadable(lazy(() => import('@pages/onboarding/business/KybShareholders')));
const KybOrders = Loadable(lazy(() => import('@pages/onboarding/business/KybOrders')));
const KybHistory = Loadable(lazy(() => import('@pages/onboarding/business/KybHistory')));
const KybMerchantDetails = Loadable(lazy(() => import('@pages/onboarding/business/KybMerchantDetails')));
const KybBankDetails = Loadable(lazy(() => import('@pages/onboarding/business/KybBankDetails')));
const KybDocuments = Loadable(lazy(() => import('@pages/onboarding/business/KybDocuments')));
const KybSubmit = Loadable(lazy(() => import('@pages/onboarding/business/KybSubmit')));
const KybInvoice = Loadable(lazy(() => import('@pages/onboarding/business/KybInvoice')));
const DownloadApp = Loadable(lazy(() => import('@pages/onboarding/DownloadApp')));

const OnboardingRoutes = {
    path: '/',
    element: (
            <AuthGuard>
                <OnboardingLayout>
                    <Outlet />
                </OnboardingLayout>
            </AuthGuard>
    ),
    children: [
        {
            path: OnboardingPath.TWOFA,
            element: <TwoFaSetup />
        },
        {
            path: OnboardingPath.QUESTIONARY,
            element: <Questionary />
        },
        {
            path: OnboardingPath.ADDRESS,
            element: <ResidencyAddress />
        },
        {
            path: OnboardingPath.DOCUMENT,
            element: <Documents />
        },
        {
            path: OnboardingPath.ITA_DETAILS,
            element: <ItalianDetails />
        },
        {
            path: OnboardingPath.KYC,
            element: <KYC />
        },
        {
            path: OnboardingPath.FINAL_REJECTED_KYC,
            element: <FinalRejected />
        },
        {
            path: OnboardingPath.FINAL_REJECTED_KYB,
            element: <FinalRejected kyb/>
        },
        {
            path: OnboardingPath.WAITING_FOR_PAYMENT,
            element: <KybInvoice />
        },
        {
            path: OnboardingPath.VERIFYING,
            element: <InVerification />
        },
        {
            path: OnboardingPath.KYB_BASIC_INFORMATION,
            element: <BasicInfo />
        },
        {
            path: OnboardingPath.KYB_BUSINESS_INFORMATION,
            element: <BusinessInfo />
        },
        {
            path: OnboardingPath.KYB_REPRESENTERS,
            element: <KybRepresenters />
        },
        {
            path: OnboardingPath.KYB_SHAREHOLDERS,
            element: <KybShareholders />
        },
        {
            path: OnboardingPath.KYB_ORDERS,
            element: <KybOrders />
        },
        {
            path: OnboardingPath.KYB_HISTORY,
            element: <KybHistory />
        },
        {
            path: OnboardingPath.KYB_MERCHANT_DETAILS,
            element: <KybMerchantDetails />
        },
        {
            path: OnboardingPath.KYB_BANK_ACCOUNT_DETAILS,
            element: <KybBankDetails />
        },
        {
            path: OnboardingPath.KYB_DOCUMENTS,
            element: <KybDocuments />
        },
        {
            path: OnboardingPath.KYB_SUBMIT,
            element: <KybSubmit />
        },
        {
            path: OnboardingPath.DOWNLOAD_APP,
            element: <DownloadApp />
        },
    ]
};

export default OnboardingRoutes;
