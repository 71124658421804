import * as React from 'react';
import { SvgIcon } from '@mui/material';

const CryptoTopupIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="32" height="32" viewBox="0 0 32 32" stroke="inherit" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 1.897a3 3 0 013 0l9.962 5.752a3 3 0 011.5 2.598v11.502a3 3 0 01-1.5 2.599l-9.961 5.75a3 3 0 01-3 0l-9.962-5.75a3 3 0 01-1.5-2.599V10.247a3 3 0 011.5-2.598l9.962-5.752z"
                stroke="inherit"
                strokeWidth={2}
            />
            <path
                d="M16 16L3.945 9.04M16.002 16l12.055-6.96M16 16v13.92"
                stroke="inherit"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>

    </SvgIcon>
);

export default CryptoTopupIcon;
