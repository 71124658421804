import React from 'react';
import { AppBar, Toolbar, Container, Box, IconButton, useTheme } from '@mui/material';
import Logo from '@components/Logo';
import LanguageSwitcher from '@components/LanguageSwitcher';
import LogoutIcon from '@mui/icons-material/Logout';
import { dispatch } from '@store/store';
import { logout } from '@features/auth/userSlice';
import { Path } from '../types/global';
import { useNavigate } from 'react-router-dom';

const OnboardingLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const handleLogout = async () => {
        await dispatch(logout());
        await navigate(Path.LOGIN, { replace: true });
        dispatch({ type: 'RESET_APP' });
    }

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">

            <AppBar position="static" color="transparent" elevation={0} >
                <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LanguageSwitcher />
                    <IconButton
                        onClick={handleLogout} >
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Container
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    minHeight: 'calc(100vh - 64px - 78px)'
                }}
            >
                <Container
                    id='onboarding-main'
                    sx={{
                        maxWidth: { xs: 600, md: 800 },
                        width: { xs: '100%', lg: '80%' },
                        borderRadius: '26px',
                        backgroundColor: theme.palette.secondaryBackground.main, // 'rgba(255, 255, 255, 0.06)',
                        padding: { xs: 2, sm: 3, xl: 5 },
                        margin: 'auto',
                    }}
                >
                    {children}
                </Container>
            </Container>

            <Box component="footer" sx={{ py: 2, textAlign: 'center' }}>
                <Logo />
            </Box>
        </Box>
    );
};

export default OnboardingLayout;
