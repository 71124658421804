import { memo } from 'react';
import { Box, Theme, Drawer as MuiDrawer, Grid, IconButton } from '@mui/material';
import { appCollapsedDrawerWidth, appMobileDrawerWidth, drawerWidth, drawerWidthMobile, mainGapTop, mainPadding } from '@theme/layoutConstant';
import { styled, CSSObject } from '@mui/material/styles';
import { useDispatch, useSelector } from '@store/store';
import { openDrawer } from '@features/menu/menuSlice';
import { appBarHeight } from '@theme/layoutConstant';
import Logo from '@components/Logo';
import SidebarClose from '@assets/icons/SidebarClose';
import SidebarOpen from '@assets/icons/SidebarOpen';


const drawerTransition = (theme: Theme, property: string): CSSObject => ({
    transition: theme.transitions.create(property, {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
    }),
});

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
        width: drawerWidthMobile,
    },
    overflowX: 'hidden',
    border: "none",
    ...drawerTransition(theme, 'width'),
});

const closedMixin = (theme: Theme): CSSObject => ({
    overflowX: 'hidden',
    border: "none",
    width: `${appCollapsedDrawerWidth}px`,
    [theme.breakpoints.down('sm')]: {
        width: `${appMobileDrawerWidth}px`,
    },
    ...drawerTransition(theme, 'width'),
});


const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        [theme.breakpoints.down('sm')]: {
            width: `${drawerWidthMobile}px`,
        },
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const SidebarLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const handleDrawerCloseClose = () => {
        dispatch(openDrawer(!drawerOpen))
    };

    const handleDrawerClose = () => {
        dispatch(openDrawer(false))
    };


    return (
        <Box
            id='side-bar'
            sx={{
                display: 'flex',
                height: '100%',
                '& .MuiDrawer-paper': {
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                },
            }}
            aria-label="navigation"
        >
            <DrawerStyled
                variant="permanent"
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                color="inherit"
                sx={theme => ({
                    '& .MuiDrawer-paper': {
                        background: theme.palette.background.default
                    }
                })}
            >
                {drawerOpen &&
                    <Grid item>
                        <Box sx={{
                            pl: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            minHeight: `${appBarHeight}px`,
                            marginTop: `${mainPadding}px`,
                        }}>
                            <Logo />
                            <IconButton onClick={handleDrawerCloseClose} >
                                <SidebarClose sx={{ fontSize: 26 }} />
                            </IconButton>
                        </Box>
                    </Grid>
                }

                {!drawerOpen &&
                    <Grid item >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: `${appBarHeight}px`,
                            marginTop: `${mainPadding}px`,
                        }}>
                            <IconButton aria-label="open" onClick={handleDrawerCloseClose} >
                                <SidebarOpen sx={{ fontSize: 26 }} />
                            </IconButton>
                        </Box>
                    </Grid>
                }
                <Grid paddingTop={`${mainGapTop}px`}   >
                    {children}
                </Grid>
            </DrawerStyled>
        </Box >
    );
};

export default memo(SidebarLayout);
