import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import BoltIcon from '@mui/icons-material/Bolt';

const InstantChip = () => {
    const { t } = useTranslation();

    return <Chip size="small" label={t('chips.instant')} color='warning' icon={<BoltIcon />} variant="outlined" />;
}

export default InstantChip;