import IncomingTransferIcon from "@assets/icons/IncomingTransferIcon";
import OutgoingTransferIcon from "@assets/icons/OutgoingTransferIcon";
import { TransactionStatus, Transfer } from "@features/transactions/transactionsTypes"
import useCustomBreakpoints from "@hooks/useCustomBreakpoints";
import { Grid, Tooltip, Typography } from "@mui/material";
import { getCurrencySign } from "@utils/currency";
import { convertDateToString } from "@utils/date";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

interface Props {
    transfer: Transfer;
}


const TransferLine = ({ transfer }: Props) => {
    const { t } = useTranslation();
    const isOutgoing = transfer.amount < 0;

    const { isDesktop } = useCustomBreakpoints();


    const formattedCounterPartyAccountNumber = transfer.counterpartyAccountNumber ? transfer.counterpartyAccountNumber.length > 30 ? transfer.counterpartyAccountNumber.slice(0, 27).trim().concat('...') : transfer.counterpartyAccountNumber : '';
    const showTooltip = formattedCounterPartyAccountNumber?.length !== transfer.counterpartyAccountNumber?.length;

    const formattedDetails = transfer.details?.length > 40 ? transfer.counterpartyAccountNumber.slice(0, 37).trim().concat('...') : transfer.details;
    const showDetailsTooltip = transfer.details?.length !== formattedDetails?.length;
    const isPending = transfer.status === TransactionStatus.PENDING;
    const isFailed = transfer.status === TransactionStatus.FAILED;

    return (<Grid container alignItems='center' justifyContent='space-between' >
        <Grid item display='flex' alignItems='center' columnGap={1} xs={!isDesktop ? 8 : 3}>
            {isOutgoing ? <OutgoingTransferIcon sx={{ fontSize: '2.5rem' }} /> : <IncomingTransferIcon sx={{ fontSize: '2.5rem' }} />}
            {isDesktop ? <Grid>
                <Typography variant="body2" >{transfer.accountName ?? ''}</Typography>
                <Typography variant="subtitle1" fontWeight={500} color='text.secondary'>{convertDateToString(transfer.date, 'dd MMM, HH:mm')}</Typography>
            </Grid> : <Grid>
                <Typography variant="body2" >{transfer.counterpartyName ?? ''}</Typography>
                <Typography variant="body2" >{convertDateToString(transfer.date, 'dd MMM, HH:mm')}</Typography>
            </Grid>}
        </Grid>
        {isDesktop && <Grid item xs={4}>
            <Grid item xs={12}>
                <Typography variant="body2" >{transfer.counterpartyName ?? ''}</Typography>
            </Grid>
            {isDesktop && <Grid item xs={12}>
                {showTooltip ? <Tooltip title={transfer.counterpartyAccountNumber} >
                    <Typography variant="subtitle1" fontWeight={500} color='text.secondary'>{formattedCounterPartyAccountNumber}</Typography>
                </Tooltip> :
                    <Typography variant="subtitle1" fontWeight={500} color='text.secondary'>{formattedCounterPartyAccountNumber}</Typography>}
            </Grid>}
        </Grid>}
        {isDesktop && <Grid item xs={3}>
            {showDetailsTooltip ? <Tooltip title={transfer.details} >
                <Typography variant="body2" >{formattedDetails}</Typography>
            </Tooltip> :
                <Typography variant="body2" >{formattedDetails}</Typography>}
        </Grid>}
        <Grid item xs={isDesktop ? 2 : 4}>
            <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Typography variant="body2" color={isOutgoing ? 'error' : 'primary'} >
                    <NumericFormat
                        displayType={'text'}
                        decimalScale={transfer.decimals}
                        fixedDecimalScale
                        valueIsNumericString
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        prefix={`${getCurrencySign(transfer.currency)} `}
                        value={transfer.amountString} />
                </Typography>
            </Grid>
            {(isFailed || isPending) && <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Typography variant="subtitle2" color={isFailed ? 'error' : 'text.secondary'} >
                    {isFailed ? t('fields.declined') : t('fields.reserved')}
                </Typography>
            </Grid>}
        </Grid>
    </Grid>)
}

export default TransferLine;