import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@store/store';
import { Category, CategoryStore, CreateCategoryPayload, UpdateCategoryPayload } from './categoryTypes';
import { arrayProductToTree } from '@utils/tree';

const initialState: CategoryStore = {
	list: [],
	tree: undefined,
	failed: false,
	loading: undefined,
	activeCategory: undefined,
	expandedItems: [],
};

const statusSlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		setCategoryList(state, action: PayloadAction<Category[]>) {
			state.list = action.payload || [];
			//move products under the children to make tree view to work out of box
			try {
				const tmp = action.payload.map(p => ({ ...p, children: p?.products?.map((i: any) => ({ ...i, type: 'product', parentId: i.productCategoryId, productId: i.id, id: `${i.productCategoryId}-${i.id}` })) }))
				state.tree = tmp?.length > 0 ? arrayProductToTree(tmp) : undefined;
			}
			catch (e) {
				state.tree = undefined;
			}
		},
		setAllCategoriesList(state, action: PayloadAction<Category[]>) {
			state.list = action.payload || [];
		},
		setError(state, action: PayloadAction<boolean>) {
			state.failed = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setActiveCategory(state, action: PayloadAction<Category>) {
			state.activeCategory = action.payload;
		},
		setExpandedItems(state, action: PayloadAction<string[]>) {
			state.expandedItems = action.payload;
		},
	}
});

export const { setError, setCategoryList, setAllCategoriesList, setLoading, setActiveCategory, setExpandedItems } = statusSlice.actions;


export const getCategories = (storeId?: string) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setError(false));
			dispatch(setLoading(true));
			if (storeId) {
				const response = await request.get(`/api-defi-network/product-category/products/${storeId}`)
				const data = response?.data?.list || [];
				dispatch(setCategoryList(data));
			}
			else {
				const response = await request.get('/api-defi-network/product-category/all-categories');
				const data = response?.data || [];
				dispatch(setAllCategoriesList(data));
			}
			dispatch(setLoading(false));
		}
		catch (e) {
			dispatch(setError(true));
		}
		dispatch(setLoading(false));
	};
};




export const postCreateCategory = async (payload: CreateCategoryPayload) => {
	const response = await request.post('/api-defi-network/product-category', payload);
	return response?.data;
};


export const postUpdateCategory = async (payload: UpdateCategoryPayload) => {
	const response = await request.patch('/api-defi-network/product-category', payload);
	return response?.data;
};

export const getCategory = async (id: string): Promise<Category> => {
	const response = await request.get(`/api-defi-network/product-category/category/${id}`);
	return response?.data;
};

/* export const getAllCategories = async (): Promise<Category[]> => {
	try {
		const response = await request.get('/api-defi-network/product-category/all-categories');
		return response?.data;
	}
	catch (e) { return [] }
}; */
export const deleteCategory = async (categoryId: string): Promise<any> => {
	await request.post(`/api-defi-network/product-category/remove/${categoryId}`);
};


export default statusSlice.reducer;
