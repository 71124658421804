import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
    onSearch: (value: string | null) => void;
} & TextFieldProps;


const SearchInput = ({ onSearch, ...otherProps }: Props) => {

    const [val, setVal] = useState<string>('');
    const [searched, setSearched] = useState<boolean>(false);

    const handleSearch = () => {
        if (val && val.length > 0) {
            setSearched(true);
            onSearch(val);
        } else {
            setSearched(false);
            onSearch(null);
        }
    }

    const handleClear = () => {
        setVal('')
        setSearched(false);
        onSearch(null);
    }



    return <TextField
        value={val}
        onChange={(e) => setVal(e.target.value)}
        placeholder="Search"
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                handleSearch();
                e.preventDefault();
            }
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle search"
                        onClick={searched ? handleClear : handleSearch}
                        onMouseDown={searched ? handleClear : handleSearch}
                    >
                        {searched ? <ClearIcon /> : <SearchIcon />}
                    </IconButton>
                </InputAdornment>
            )
        }}
        {...otherProps}
    />;
}

export default SearchInput;
