import { Outlet } from 'react-router-dom';
import AuthGuard from '@guards/AuthGuard';
import DashboardLayout from '@layout/DashboardLayout';
import { CardsPath, DashboardPath, EmployeesPath, InvoicesPath, LegalPath } from '../types/global';
import Home from '@pages/home/Home';
import { lazy } from 'react';
import Loadable from '@components/Loadable';
import StoreMenuRoutes from './StoreMenuRoutes';
import { PathParamEnum } from '@features/menu/menuType';

const Employees = Loadable(lazy(() => import('@pages/employees/Employees')));
const EmployeeCreate = Loadable(lazy(() => import('@pages/employees/EmployeeCreate')));
const EmployeeDetails = Loadable(lazy(() => import('@pages/employees/EmployeeDetails')));
const Transactions = Loadable(lazy(() => import('@pages/transactions/Transactions')));
const Cards = Loadable(lazy(() => import('@pages/cards/Cards')));
const CreateCard = Loadable(lazy(() => import('@pages/cards/CreateCard')));
const Forbidden = Loadable(lazy(() => import('@pages/Forbidden')));
const Invoices = Loadable(lazy(() => import('@pages/invoices/Invoices')));
const InvoiceDetails = Loadable(lazy(() => import('@pages/invoices/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('@pages/invoices/InvoiceCreate')));
const Settings = Loadable(lazy(() => import('@pages/settings/Settings')));
const PrivacyPolicy = Loadable(lazy(() => import('@pages/policies/PrivacyPolicy')));
const TermsAndCondtions = Loadable(lazy(() => import('@pages/policies/TermsAndCondtions')));
const Support = Loadable(lazy(() => import('@pages/support/Support')));

const DashboardBusinessRoutes = {
    path: '/dashboard',
    element: (
        <AuthGuard>
            <DashboardLayout>
                <Outlet />
            </DashboardLayout>
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Home />
        },
        {
            path: DashboardPath.HOME,
            element: <Home />
        },
        {
            path: DashboardPath.CARDS,
            element: <Outlet />,
            children: [
                {
                    path: DashboardPath.CARDS,
                    element: <Cards />
                },
                {
                    path: CardsPath.CREATE_CARD,
                    element: <CreateCard />
                },
            ]
        },
        { ...StoreMenuRoutes },
        {
            path: DashboardPath.EMPLOYEES,
            element: <Outlet />,
            children: [
                {
                    path: DashboardPath.EMPLOYEES,
                    element: <Employees />,
                    children: [
                        {
                            path: EmployeesPath.CREATE_EMPLOYEE,
                            element: <EmployeeCreate />
                        },
                    ]
                },
                {
                    path: `:${PathParamEnum.EMPLOYEE_ID}`,
                    element: <EmployeeDetails />
                },
            ]
        },
        {
            path: DashboardPath.TRANSFERS,
            element: <Transactions />
        },
        {
            path: DashboardPath.ORDERS,
            element: <Outlet />,
            children: [
                {
                    path: DashboardPath.ORDERS,
                    element: <Invoices />,
                },
                {
                    path: InvoicesPath.CREATE_INVOICE,
                    element: <InvoiceCreate />
                },
                {
                    path: `:${PathParamEnum.INVOICE_ID}`,
                    element: <InvoiceDetails />
                },
            ]
        },
        {
            path: DashboardPath.SETTINGS,
            element: <Outlet />,
            children: [
                {
                    path: DashboardPath.SETTINGS,
                    element: <Settings />
                },
                {
                    path: LegalPath.PRIVACY_POLICY,
                    element: <PrivacyPolicy />
                },
                {
                    path: LegalPath.TERMS_AND_CONDITIONS,
                    element: <TermsAndCondtions />
                },
            ]
        },
        {
            path: DashboardPath.HELP,
            element: <Support />
        },
        {
            path: DashboardPath.FORBIDDEN,
            element: <Forbidden />
        },

    ]
};

export default DashboardBusinessRoutes;
