import { Account } from "@features/accounts/accountsTypes";
import { useMemo, useState } from "react";
import SendMoneyChooseRecipient from "./SendMoneyChooseRecipient";
import { Contact } from "@features/beneficiary/beneficiaryTypes";
import SendMoneyBankRecipientDetails from "./SendMoneyBankRecipientDetails";
import SendMoneyAmountDetails from "./SendMoneyAmountDetails";
import SendMoneyBankTransferConfirmation from "./SendMoneyBankTransferConfirmation";
import { findPaymentTypes } from "@features/accounts/accountsSlice";
import { supportedPaymentMethods } from "./ChooseSendMoneyType";


interface Props {
    account: Account;
    onBack: () => void;
    onClose: () => void;
}

enum Step {
    CHOOSE_RECIPIENT = "CHOOSE_RECIPIENT",
    RECIPIENT_DETAILS = "RECIPIENT_DETAILS",
    AMOUNT_DETAILS = "AMOUNT_DETAILS",
    TRANSFER_CONFIRMATION = "TRANSFER_CONFIRMATION",
}

const SendMoneyBankTransfer = ({ account, onBack, onClose }: Props) => {
    const [step, setStep] = useState<Step>(Step.CHOOSE_RECIPIENT);
    const [contact, setContact] = useState<Contact | null>(null);
    const [recipientPayload, setRecipientPayload] = useState<any>(null);
    const [amountPayload, setAmountPayload] = useState<any>(null);

    const handleOnRecipientChoose = ($contact: Contact | null) => {
        if ($contact) {
            setContact($contact);
            setStep(Step.AMOUNT_DETAILS);
        } else {
            setStep(Step.RECIPIENT_DETAILS);
        }
    }

    const paymentProcessList = useMemo(() => findPaymentTypes(account)
        .filter(p => supportedPaymentMethods.includes(p.processProperties?.paymentType)) || [], [account]);

    const isGbp = account.currency === 'GBP';

    const handleNextFromDetails = ($payload: any) => {
        setRecipientPayload($payload);
        setStep(Step.AMOUNT_DETAILS);
    }

    const handleBackFromAccountScreen = () => {
        if (contact) {
            setStep(Step.CHOOSE_RECIPIENT);
        } else {
            setStep(Step.RECIPIENT_DETAILS);
        }
    }

    const handleNextFromAmountsetStep = ($payload: any) => {
        setAmountPayload($payload);
        setStep(Step.TRANSFER_CONFIRMATION);
    }


    return (
        <>
            {step === Step.CHOOSE_RECIPIENT && <SendMoneyChooseRecipient onBack={onBack} onChoose={(contact: Contact | null) => handleOnRecipientChoose(contact)} onClose={onClose} recipientType={isGbp ? 'GBP' : 'EUR'} />}
            {step === Step.RECIPIENT_DETAILS && <SendMoneyBankRecipientDetails
                recipient={recipientPayload}
                isGbpPayment={isGbp}
                account={account}
                onBack={() => setStep(Step.CHOOSE_RECIPIENT)}
                onClose={onClose}
                onNext={handleNextFromDetails}
            />}
            {step === Step.AMOUNT_DETAILS && <SendMoneyAmountDetails
                amountPayload={amountPayload}
                account={account}
                isGbpPayment={isGbp}
                onBack={handleBackFromAccountScreen}
                onClose={onClose}
                onNext={(payload: any) => handleNextFromAmountsetStep(payload)}
                paymentProcessList={paymentProcessList}
            />}
            {step === Step.TRANSFER_CONFIRMATION && <SendMoneyBankTransferConfirmation
                onBack={() => setStep(Step.AMOUNT_DETAILS)}
                onClose={onClose}
                account={account}
                amountPayload={amountPayload}
                recipientPayload={recipientPayload}
                contact={contact}
            />}
        </>
    );
}



export default SendMoneyBankTransfer;