import { Grid, IconButton, Typography } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { NumericFormat } from "react-number-format";

interface Props {
    label: string;
    value: string;
    copyValue?: boolean;
    amountValue?: boolean;
    currencySing?: string;
}

const ConfirmationLine = ({ label, value, copyValue, amountValue, currencySing }: Props) => {
    return (<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
        <Grid item xs={10}>
            <Typography variant='body1' sx={{ fontWeight: amountValue ? 600 : 500 }} color={amountValue ? 'primary' : 'text'}>
                {amountValue ? <NumericFormat
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale
                    valueIsNumericString
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    prefix={currencySing ?? ''}
                    value={value} /> : value}
            </Typography>
            <Typography variant="subtitle1" color='text.secondary'>{label}</Typography>
        </Grid>
        {copyValue && <CopyToClipboard text={value}>
            <IconButton>
                <ContentCopyIcon />
            </IconButton>
        </CopyToClipboard >}
    </Grid>);
};

export default ConfirmationLine;