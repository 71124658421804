import { styled } from '@mui/material/styles';
import { Tab, TabProps } from '@mui/material';

const TabStyled = styled((props: TabProps) => <Tab disableRipple {...props} />)(
	() => ({
		backgroundColor: 'transparent',
		textTransform: 'none',
		borderRadius: '20px',
		padding: '10px 20px',
		lineHeight: '18px',
		fontSize: '14px',
		fontWeight: 500,
		'&.MuiButtonBase-root.Mui-selected': {
			backgroundColor: 'rgba(255, 255, 255, 0.06)',
			minHeight: '29px',
			minWidth: 'unset'
		},
		'&.MuiButtonBase-root': {
			minHeight: '29px',
			minWidth: 'unset'
		},
	}),
);

export default TabStyled;
