import { Account } from "@features/accounts/accountsTypes";
import { DepositType } from "../AddMoneyDialog";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import TransferTypeCard from "./TransferTypeCard";
import BankTransferIcon from "@assets/icons/transferTypes/BankTransferIcon";
import CryptoTopupIcon from "@assets/icons/transferTypes/CryptoTopupIcon";
import TransferBetweenAccountsIcon from "@assets/icons/transferTypes/TransferBetweenAccountsIcon";
import { useSelector } from "@store/store";
import { findProcessByType, hasSubprocessWithType } from "@features/accounts/accountsSlice";
import { useTranslation } from "react-i18next";
import FormTitle from "@components/form/FormTitle";
import OneTwoDaysChip from "@components/chips/OneTwoDaysChip";
import InstantChip from "@components/chips/InstantChip";


interface Props {
    onChoose: (type: DepositType) => void;
    onClose: () => void;
    account: Account;
}

const ChooseDepositType = ({ onChoose, account, onClose }: Props) => {
    const { t } = useTranslation();
    const { list } = useSelector((state) => state.accounts);

    const otherAccounts = list.filter((acc: Account) => acc.accountId !== account.accountId || acc.currency !== account.currency) || [];
    const topupByCryptoAllowed = hasSubprocessWithType('EXCHANGE_DEPOSIT', account.subProcesses);

    const ownTransferAllowed = otherAccounts.some((otherAccount: Account) => hasSubprocessWithType('OWN_TRANSFER', otherAccount.subProcesses)
        && otherAccount.availableBalance > 0
        && findProcessByType(otherAccount, 'OWN_TRANSFER').maintenance === false && otherAccount.currency === account.currency);

    return (<>
        <DialogTitle>
            <FormTitle
                title={t('titles.addMoney')}
                onClose={onClose}
            />
        </DialogTitle>
        <DialogContent>
            <Grid container gap={1}>
                <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(DepositType.BANK_TRANSFER)}
                        title={t('titles.bankTransfer')}
                        description={t('descriptions.bankTransfer')}
                        icon={<BankTransferIcon />}
                        chips={<OneTwoDaysChip />}
                    />
                </Grid>
                {topupByCryptoAllowed && <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(DepositType.TOP_UP_BY_CRYPTO)}
                        title={t('titles.cryptoTopUp')}
                        description={t('descriptions.cryptoTopUp')}
                        icon={<CryptoTopupIcon />}
                        chips={<InstantChip />}
                    />
                </Grid>}
                {ownTransferAllowed && <Grid item xs={12}>
                    <TransferTypeCard
                        onClick={() => onChoose(DepositType.TRANSFER_BETWEEN_ACCOUNTS)}
                        fillIconFully
                        title={t('titles.ownTransfers')}
                        description={t('descriptions.ownTransfers')}
                        icon={<TransferBetweenAccountsIcon />}
                        chips={<InstantChip />}
                    />
                </Grid>}

            </Grid>
        </DialogContent>
    </>
    );
}

export default ChooseDepositType;