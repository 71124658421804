import * as React from 'react';
import { SvgIcon } from '@mui/material';

const EmployeeIcon = (props: any) => (
    <SvgIcon {...props} >
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fillRule="evenodd" clipRule="evenodd" d="M8.25977 10.4571C8.25977 8.02165 10.2341 6.04736 12.6695 6.04736C15.1049 6.04736 17.0792 8.02165 17.0792 10.4571C17.0792 12.8925 15.1049 14.8668 12.6695 14.8668C10.2341 14.8668 8.25977 12.8925 8.25977 10.4571ZM12.6695 7.84736C11.2282 7.84736 10.0598 9.01577 10.0598 10.4571C10.0598 11.8984 11.2282 13.0668 12.6695 13.0668C14.1108 13.0668 15.2792 11.8984 15.2792 10.4571C15.2792 9.01577 14.1108 7.84736 12.6695 7.84736Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1.83545 12.9869C1.83545 7.00342 6.68603 2.15283 12.6695 2.15283C18.653 2.15283 23.5036 7.00342 23.5036 12.9869C23.5036 18.9704 18.653 23.821 12.6695 23.821C6.68603 23.821 1.83545 18.9704 1.83545 12.9869ZM12.6695 3.95283C7.68015 3.95283 3.63545 7.99753 3.63545 12.9869C3.63545 15.1986 4.43022 17.2247 5.74974 18.7951C7.50172 16.9918 9.9546 15.8696 12.6693 15.8696C15.3842 15.8696 17.8371 16.9919 19.5891 18.7953C20.9088 17.2249 21.7036 15.1987 21.7036 12.9869C21.7036 7.99753 17.6589 3.95283 12.6695 3.95283ZM18.3004 20.0519C16.8736 18.5816 14.8781 17.6696 12.6693 17.6696C10.4607 17.6696 8.46522 18.5815 7.0384 20.0517C8.58269 21.2842 10.5401 22.021 12.6695 22.021C14.7989 22.021 16.7562 21.2843 18.3004 20.0519Z" fill="white" />
            </g>
        </svg>

    </SvgIcon>
);

export default EmployeeIcon;
